/* 

*/
[md-ink-ripple] {
  position: relative;
}
.i-24 {
  font-size: 24px;
  vertical-align: -23%;
}
.md-btn {
  color: currentColor;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  display: inline-block;
  background: transparent;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-style: inherit;
  font-variant: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-btn.md-flat:not([disabled]):hover,
.md-btn.md-flat:not([disabled]):focus {
  background-color: rgba(158, 158, 158, 0.2);
}
.md-btn-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.md-btn[disabled],
.md-btn.md-raised[disabled],
.md-btn.md-fab[disabled] {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
  cursor: not-allowed;
}
.md-fab {
  line-height: 44px;
}
.md-fab-offset {
  margin-top: -28px;
  margin-bottom: -28px;
}
.md-btn:focus {
  outline: none;
}
.md-btn:hover {
  text-decoration: none;
}
.md-btn.md-cornered {
  border-radius: 0;
}
.md-btn.md-icon {
  padding: 0;
  background: none;
}
.md-btn.md-raised {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.md-btn.md-fab {
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.2s linear;
  transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow;
}
.md-btn.md-raised:not([disabled]),
.md-btn.md-fab {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-btn.md-raised:not([disabled]):focus,
.md-btn.md-raised:not([disabled]):hover,
.md-btn.md-fab:not([disabled]):focus,
.md-btn.md-fab:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.md-btn.md-fab.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}
.md-btn.md-fab.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}
.md-btn.md-fab.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}
.md-btn.md-fab.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}
.md-btn.md-fab md-icon {
  margin-top: 0;
}
.md-btn.md-fab.md-mini {
  width: 40px;
  height: 40px;
}
.md-btn:not([disabled]).md-raised:focus,
.md-btn:not([disabled]).md-raised:hover,
.md-btn:not([disabled]).md-fab:focus,
.md-btn:not([disabled]).md-fab:hover {
  -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0);
}
.md-toast-open-top .md-btn.md-fab-top-left,
.md-toast-open-top .md-btn.md-fab-top-right {
  -webkit-transform: translate3d(0, 42px, 0);
  transform: translate3d(0, 42px, 0);
}
.md-toast-open-top .md-btn.md-fab-top-left:not([disabled]):focus,
.md-toast-open-top .md-btn.md-fab-top-left:not([disabled]):hover,
.md-toast-open-top .md-btn.md-fab-top-right:not([disabled]):focus,
.md-toast-open-top .md-btn.md-fab-top-right:not([disabled]):hover {
  -webkit-transform: translate3d(0, 41px, 0);
  transform: translate3d(0, 41px, 0);
}
.md-toast-open-bottom .md-btn.md-fab-bottom-left,
.md-toast-open-bottom .md-btn.md-fab-bottom-right {
  -webkit-transform: translate3d(0, -42px, 0);
  transform: translate3d(0, -42px, 0);
}
.md-toast-open-bottom .md-btn.md-fab-bottom-left:not([disabled]):focus,
.md-toast-open-bottom .md-btn.md-fab-bottom-left:not([disabled]):hover,
.md-toast-open-bottom .md-btn.md-fab-bottom-right:not([disabled]):focus,
.md-toast-open-bottom .md-btn.md-fab-bottom-right:not([disabled]):hover {
  -webkit-transform: translate3d(0, -43px, 0);
  transform: translate3d(0, -43px, 0);
}
.edit-btn i,
.attr-edit-btn i {
  font-family: "Material-Design-Icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  font-variant: normal;
  font-size: 20px;
  vertical-align: -19%;
  -moz-osx-font-smoothing: grayscale;
}
.edit-btn i:before,
.attr-edit-btn i:before {
  content: "\e796";
}
.edit-btn.active,
.attr-edit-btn.active {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.87);
}
.edit-btn.active i:before,
.attr-edit-btn.active i:before {
  content: "\e899";
}
.card {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: relative;
  margin-bottom: 24px;
  z-index: 10;
}
.card-heading {
  position: relative;
  padding: 20px 24px;
}
.card-heading h2 {
  margin: 0;
  font-size: 18px;
}
.card-heading small {
  display: block;
  margin-top: 8px;
  opacity: 0.5;
}
.card-body {
  padding: 20px 24px;
}
.card-divider {
  border-bottom: 1px solid rgba(110, 115, 120, 0.15);
  margin: 0 24px;
}
.card-tools {
  position: absolute;
  right: 12px;
  top: 12px;
}
.red {
  background-color: #f44336;
}
.red-50 {
  background-color: #ffebee;
}
.red-100 {
  background-color: #ffcdd2;
}
.red-200 {
  background-color: #ef9a9a;
}
.red-300 {
  background-color: #e57373;
}
.red-400 {
  background-color: #ef5350;
}
.red-500 {
  background-color: #f44336;
}
.red-600 {
  background-color: #e53935;
}
.red-700 {
  background-color: #d32f2f;
}
.red-800 {
  background-color: #c62828;
}
.red-900 {
  background-color: #b71c1c;
}
.red-A100 {
  background-color: #ff8a80;
}
.red-A200 {
  background-color: #ff5252;
}
.red-A400 {
  background-color: #ff1744;
}
.red-A700 {
  background-color: #d50000;
}
.pink {
  background-color: #e91e63;
}
.pink-50 {
  background-color: #fce4ec;
}
.pink-100 {
  background-color: #f8bbd0;
}
.pink-200 {
  background-color: #f48fb1;
}
.pink-300 {
  background-color: #f06292;
}
.pink-400 {
  background-color: #ec407a;
}
.pink-500 {
  background-color: #e91e63;
}
.pink-600 {
  background-color: #d81b60;
}
.pink-700 {
  background-color: #c2185b;
}
.pink-800 {
  background-color: #ad1457;
}
.pink-900 {
  background-color: #880e4f;
}
.pink-A100 {
  background-color: #ff80ab;
}
.pink-A200 {
  background-color: #ff4081;
}
.pink-A400 {
  background-color: #f50057;
}
.pink-A700 {
  background-color: #c51162;
}
.purple {
  background-color: #9c27b0;
}
.purple-50 {
  background-color: #f3e5f5;
}
.purple-100 {
  background-color: #e1bee7;
}
.purple-200 {
  background-color: #ce93d8;
}
.purple-300 {
  background-color: #ba68c8;
}
.purple-400 {
  background-color: #ab47bc;
}
.purple-500 {
  background-color: #9c27b0;
}
.purple-600 {
  background-color: #8e24aa;
}
.purple-700 {
  background-color: #7b1fa2;
}
.purple-800 {
  background-color: #6a1b9a;
}
.purple-900 {
  background-color: #4a148c;
}
.purple-A100 {
  background-color: #ea80fc;
}
.purple-A200 {
  background-color: #e040fb;
}
.purple-A400 {
  background-color: #d500f9;
}
.purple-A700 {
  background-color: #aa00ff;
}
.deep-purple {
  background-color: #673ab7;
}
.deep-purple-50 {
  background-color: #ede7f6;
}
.deep-purple-100 {
  background-color: #d1c4e9;
}
.deep-purple-200 {
  background-color: #b39ddb;
}
.deep-purple-300 {
  background-color: #9575cd;
}
.deep-purple-400 {
  background-color: #7e57c2;
}
.deep-purple-500 {
  background-color: #673ab7;
}
.deep-purple-600 {
  background-color: #5e35b1;
}
.deep-purple-700 {
  background-color: #512da8;
}
.deep-purple-800 {
  background-color: #4527a0;
}
.deep-purple-900 {
  background-color: #311b92;
}
.deep-purple-A100 {
  background-color: #b388ff;
}
.deep-purple-A200 {
  background-color: #7c4dff;
}
.deep-purple-A400 {
  background-color: #651fff;
}
.deep-purple-A700 {
  background-color: #6200ea;
}
.indigo {
  background-color: #3f51b5;
}
.indigo-50 {
  background-color: #e8eaf6;
}
.indigo-100 {
  background-color: #c5cae9;
}
.indigo-200 {
  background-color: #9fa8da;
}
.indigo-300 {
  background-color: #7986cb;
}
.indigo-400 {
  background-color: #5c6bc0;
}
.indigo-500 {
  background-color: #3f51b5;
}
.indigo-600 {
  background-color: #3949ab;
}
.indigo-700 {
  background-color: #303f9f;
}
.indigo-800 {
  background-color: #283593;
}
.indigo-900 {
  background-color: #1a237e;
}
.indigo-A100 {
  background-color: #8c9eff;
}
.indigo-A200 {
  background-color: #536dfe;
}
.indigo-A400 {
  background-color: #3d5afe;
}
.indigo-A700 {
  background-color: #304ffe;
}
.blue {
  background-color: #2196f3;
}
.blue-50 {
  background-color: #e3f2fd;
}
.blue-100 {
  background-color: #bbdefb;
}
.blue-200 {
  background-color: #90caf9;
}
.blue-300 {
  background-color: #64b5f6;
}
.blue-400 {
  background-color: #42a5f5;
}
.blue-500 {
  background-color: #2196f3;
}
.blue-600 {
  background-color: #1e88e5;
}
.blue-700 {
  background-color: #1976d2;
}
.blue-800 {
  background-color: #1565c0;
}
.blue-900 {
  background-color: #0d47a1;
}
.blue-A100 {
  background-color: #82b1ff;
}
.blue-A200 {
  background-color: #448aff;
}
.blue-A400 {
  background-color: #2979ff;
}
.blue-A700 {
  background-color: #2962ff;
}
.light-blue {
  background-color: #03a9f4;
}
.light-blue-50 {
  background-color: #e1f5fe;
}
.light-blue-100 {
  background-color: #b3e5fc;
}
.light-blue-200 {
  background-color: #81d4fa;
}
.light-blue-300 {
  background-color: #4fc3f7;
}
.light-blue-400 {
  background-color: #29b6f6;
}
.light-blue-500 {
  background-color: #03a9f4;
}
.light-blue-600 {
  background-color: #039be5;
}
.light-blue-700 {
  background-color: #0288d1;
}
.light-blue-800 {
  background-color: #0277bd;
}
.light-blue-900 {
  background-color: #01579b;
}
.light-blue-A100 {
  background-color: #80d8ff;
}
.light-blue-A200 {
  background-color: #40c4ff;
}
.light-blue-A400 {
  background-color: #00b0ff;
}
.light-blue-A700 {
  background-color: #0091ea;
}
.cyan {
  background-color: #00bcd4;
}
.cyan-50 {
  background-color: #e0f7fa;
}
.cyan-100 {
  background-color: #b2ebf2;
}
.cyan-200 {
  background-color: #80deea;
}
.cyan-300 {
  background-color: #4dd0e1;
}
.cyan-400 {
  background-color: #26c6da;
}
.cyan-500 {
  background-color: #00bcd4;
}
.cyan-600 {
  background-color: #00acc1;
}
.cyan-700 {
  background-color: #0097a7;
}
.cyan-800 {
  background-color: #00838f;
}
.cyan-900 {
  background-color: #006064;
}
.cyan-A100 {
  background-color: #84ffff;
}
.cyan-A200 {
  background-color: #18ffff;
}
.cyan-A400 {
  background-color: #00e5ff;
}
.cyan-A700 {
  background-color: #00b8d4;
}
.teal {
  background-color: #009688;
}
.teal-50 {
  background-color: #e0f2f1;
}
.teal-100 {
  background-color: #b2dfdb;
}
.teal-200 {
  background-color: #80cbc4;
}
.teal-300 {
  background-color: #4db6ac;
}
.teal-400 {
  background-color: #26a69a;
}
.teal-500 {
  background-color: #009688;
}
.teal-600 {
  background-color: #00897b;
}
.teal-700 {
  background-color: #00796b;
}
.teal-800 {
  background-color: #00695c;
}
.teal-900 {
  background-color: #004d40;
}
.teal-A100 {
  background-color: #a7ffeb;
}
.teal-A200 {
  background-color: #64ffda;
}
.teal-A400 {
  background-color: #1de9b6;
}
.teal-A700 {
  background-color: #00bfa5;
}
.green {
  background-color: #4caf50;
}
.green-50 {
  background-color: #e8f5e9;
}
.green-100 {
  background-color: #c8e6c9;
}
.green-200 {
  background-color: #a5d6a7;
}
.green-300 {
  background-color: #81c784;
}
.green-400 {
  background-color: #66bb6a;
}
.green-500 {
  background-color: #4caf50;
}
.green-600 {
  background-color: #43a047;
}
.green-700 {
  background-color: #388e3c;
}
.green-800 {
  background-color: #2e7d32;
}
.green-900 {
  background-color: #1b5e20;
}
.green-A100 {
  background-color: #b9f6ca;
}
.green-A200 {
  background-color: #69f0ae;
}
.green-A400 {
  background-color: #00e676;
}
.green-A700 {
  background-color: #00c853;
}
.light-green {
  background-color: #8bc34a;
}
.light-green-50 {
  background-color: #f1f8e9;
}
.light-green-100 {
  background-color: #dcedc8;
}
.light-green-200 {
  background-color: #c5e1a5;
}
.light-green-300 {
  background-color: #aed581;
}
.light-green-400 {
  background-color: #9ccc65;
}
.light-green-500 {
  background-color: #8bc34a;
}
.light-green-600 {
  background-color: #7cb342;
}
.light-green-700 {
  background-color: #689f38;
}
.light-green-800 {
  background-color: #558b2f;
}
.light-green-900 {
  background-color: #33691e;
}
.light-green-A100 {
  background-color: #ccff90;
}
.light-green-A200 {
  background-color: #b2ff59;
}
.light-green-A400 {
  background-color: #76ff03;
}
.light-green-A700 {
  background-color: #64dd17;
}
.lime {
  background-color: #cddc39;
}
.lime-50 {
  background-color: #f9fbe7;
}
.lime-100 {
  background-color: #f0f4c3;
}
.lime-200 {
  background-color: #e6ee9c;
}
.lime-300 {
  background-color: #dce775;
}
.lime-400 {
  background-color: #d4e157;
}
.lime-500 {
  background-color: #cddc39;
}
.lime-600 {
  background-color: #c0ca33;
}
.lime-700 {
  background-color: #afb42b;
}
.lime-800 {
  background-color: #9e9d24;
}
.lime-900 {
  background-color: #827717;
}
.lime-A100 {
  background-color: #f4ff81;
}
.lime-A200 {
  background-color: #eeff41;
}
.lime-A400 {
  background-color: #c6ff00;
}
.lime-A700 {
  background-color: #aeea00;
}
.yellow {
  background-color: #ffeb3b;
}
.yellow-50 {
  background-color: #fffde7;
}
.yellow-100 {
  background-color: #fff9c4;
}
.yellow-200 {
  background-color: #fff59d;
}
.yellow-300 {
  background-color: #fff176;
}
.yellow-400 {
  background-color: #ffee58;
}
.yellow-500 {
  background-color: #ffeb3b;
}
.yellow-600 {
  background-color: #fdd835;
}
.yellow-700 {
  background-color: #fbc02d;
}
.yellow-800 {
  background-color: #f9a825;
}
.yellow-900 {
  background-color: #f57f17;
}
.yellow-A100 {
  background-color: #ffff8d;
}
.yellow-A200 {
  background-color: #ffff00;
}
.yellow-A400 {
  background-color: #ffea00;
}
.yellow-A700 {
  background-color: #ffd600;
}
.amber {
  background-color: #ffc107;
}
.amber-50 {
  background-color: #fff8e1;
}
.amber-100 {
  background-color: #ffecb3;
}
.amber-200 {
  background-color: #ffe082;
}
.amber-300 {
  background-color: #ffd54f;
}
.amber-400 {
  background-color: #ffca28;
}
.amber-500 {
  background-color: #ffc107;
}
.amber-600 {
  background-color: #ffb300;
}
.amber-700 {
  background-color: #ffa000;
}
.amber-800 {
  background-color: #ff8f00;
}
.amber-900 {
  background-color: #ff6f00;
}
.amber-A100 {
  background-color: #ffe57f;
}
.amber-A200 {
  background-color: #ffd740;
}
.amber-A400 {
  background-color: #ffc400;
}
.amber-A700 {
  background-color: #ffab00;
}
.orange {
  background-color: #ff9800;
}
.orange-50 {
  background-color: #fff3e0;
}
.orange-100 {
  background-color: #ffe0b2;
}
.orange-200 {
  background-color: #ffcc80;
}
.orange-300 {
  background-color: #ffb74d;
}
.orange-400 {
  background-color: #ffa726;
}
.orange-500 {
  background-color: #ff9800;
}
.orange-600 {
  background-color: #fb8c00;
}
.orange-700 {
  background-color: #f57c00;
}
.orange-800 {
  background-color: #ef6c00;
}
.orange-900 {
  background-color: #e65100;
}
.orange-A100 {
  background-color: #ffd180;
}
.orange-A200 {
  background-color: #ffab40;
}
.orange-A400 {
  background-color: #ff9100;
}
.orange-A700 {
  background-color: #ff6d00;
}
.deep-orange {
  background-color: #ff5722;
}
.deep-orange-50 {
  background-color: #fbe9e7;
}
.deep-orange-100 {
  background-color: #ffccbc;
}
.deep-orange-200 {
  background-color: #ffab91;
}
.deep-orange-300 {
  background-color: #ff8a65;
}
.deep-orange-400 {
  background-color: #ff7043;
}
.deep-orange-500 {
  background-color: #ff5722;
}
.deep-orange-600 {
  background-color: #f4511e;
}
.deep-orange-700 {
  background-color: #e64a19;
}
.deep-orange-800 {
  background-color: #d84315;
}
.deep-orange-900 {
  background-color: #bf360c;
}
.deep-orange-A100 {
  background-color: #ff9e80;
}
.deep-orange-A200 {
  background-color: #ff6e40;
}
.deep-orange-A400 {
  background-color: #ff3d00;
}
.deep-orange-A700 {
  background-color: #dd2c00;
}
.brown {
  background-color: #795548;
}
.brown-50 {
  background-color: #efebe9;
}
.brown-100 {
  background-color: #d7ccc8;
}
.brown-200 {
  background-color: #bcaaa4;
}
.brown-300 {
  background-color: #a1887f;
}
.brown-400 {
  background-color: #8d6e63;
}
.brown-500 {
  background-color: #795548;
}
.brown-600 {
  background-color: #6d4c41;
}
.brown-700 {
  background-color: #5d4037;
}
.brown-800 {
  background-color: #4e342e;
}
.brown-900 {
  background-color: #3e2723;
}
.blue-grey {
  background-color: #607d8b;
}
.blue-grey-50 {
  background-color: #eceff1;
}
.blue-grey-100 {
  background-color: #cfd8dc;
}
.blue-grey-200 {
  background-color: #b0bec5;
}
.blue-grey-300 {
  background-color: #90a4ae;
}
.blue-grey-400 {
  background-color: #78909c;
}
.blue-grey-500 {
  background-color: #607d8b;
}
.blue-grey-600 {
  background-color: #546e7a;
}
.blue-grey-700 {
  background-color: #455a64;
}
.blue-grey-800 {
  background-color: #37474f;
}
.blue-grey-900 {
  background-color: #263238;
}
.grey {
  background-color: #9e9e9e;
}
.grey-50 {
  background-color: #fafafa;
}
.grey-100 {
  background-color: #f5f5f5;
}
.grey-200 {
  background-color: #eeeeee;
}
.grey-300 {
  background-color: #e0e0e0;
}
.grey-400 {
  background-color: #bdbdbd;
}
.grey-500 {
  background-color: #9e9e9e;
}
.grey-600 {
  background-color: #757575;
}
.grey-700 {
  background-color: #616161;
}
.grey-800 {
  background-color: #424242;
}
.grey-900 {
  background-color: #212121;
}
.red,
.red-500,
.red-600,
.red-700,
.red-A200,
.red-A400,
.red-A700,
.pink,
.pink-500,
.pink-600,
.pink-A200,
.pink-A400,
.pink-A700,
.purple-300,
.purple-400,
.purple-A200,
.purple-A400,
.purple-A700,
.deep-purple-300,
.deep-purple-400,
.deep-purple-A200,
.indigo-300,
.indigo-400,
.indigo-A200,
.indigo-A400,
.blue,
.blue-500,
.blue-600,
.blue-700,
.blue-A200,
.blue-A400,
.blue-A700,
.light-blue,
.light-blue-500,
.light-blue-600,
.light-blue-700,
.light-blue-800,
.light-blue-A700,
.cyan,
.cyan-500,
.cyan-600,
.cyan-700,
.cyan-800,
.teal,
.teal-500,
.teal-600,
.teal-700,
.green,
.green-500,
.green-600,
.green-700,
.light-green-800,
.light-green-900,
.lime-900,
.orange-800,
.orange-900,
.deep-orange,
.deep-orange-500,
.deep-orange-600,
.deep-orange-700,
.deep-orange-800,
.deep-orange-900,
.deep-orange-A400,
.deep-orange-A700,
.brown-300,
.brown-400,
.blue-grey,
.blue-grey-400,
.blue-grey-500 {
  color: #ffffff;
}
.red-800,
.red-900,
.pink-700,
.pink-800,
.pink-900,
.purple,
.purple-500,
.purple-600,
.purple-700,
.purple-800,
.purple-900,
.deep-purple,
.deep-purple-500,
.deep-purple-600,
.deep-purple-700,
.deep-purple-800,
.deep-purple-900,
.deep-purple-A400,
.deep-purple-A700,
.indigo,
.indigo-500,
.indigo-600,
.indigo-700,
.indigo-800,
.indigo-900,
.indigo-A700,
.blue-800,
.blue-900,
.light-blue-900,
.cyan-900,
.teal-800,
.teal-900,
.green-800,
.green-900,
.brown,
.brown-500,
.brown-600,
.brown-700,
.brown-800,
.brown-900,
.blue-grey-600,
.blue-grey-700,
.blue-grey-800,
.blue-grey-900,
.grey-600,
.grey-700,
.grey-800,
.grey-900 {
  color: rgba(255, 255, 255, 0.87);
}
.orange-A700 {
  color: #000000;
}
table.td-vm > tbody > tr > td {
  vertical-align: middle;
}
table > tbody > tr td.two-lines > a > span,
table > tbody > tr td.two-lines > span {
  padding-top: 4px !important;
  padding-bottom: 2px !important;
}
table > tbody > tr td.two-lines > a > span span.primary,
table > tbody > tr td.two-lines > span span.primary {
  line-height: 16px;
  padding: 4px 0 1px !important;
  display: block;
}
table > tbody > tr td.two-lines > a > span span.primary ~ span.primary,
table > tbody > tr td.two-lines > span span.primary ~ span.primary {
  padding: 1px 0 4px !important;
  display: block;
}
table > tbody > tr td.two-lines > a > span span.primary > span,
table > tbody > tr td.two-lines > span span.primary > span,
table > tbody > tr td.two-lines > a > span span.primary > span,
table > tbody > tr td.two-lines > span span.primary > span {
  font-weight: 400;
  display: inline;
  line-height: 16px;
}
table > tbody > tr td.two-lines > a > span span.secondary,
table > tbody > tr td.two-lines > span span.secondary {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  padding: 1px 0 4px !important;
  display: block;
}
.md-list {
  border-radius: 2px;
}
.md-list-item {
  display: block;
  position: relative;
}
.md-list-item:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
}
.md-list-item:last-child.md-list-item:after {
  border: none;
}
.md-list-item.inset.md-list-item:after {
  left: 72px;
}
.md-list-item-left {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  float: left;
  text-align: center;
  line-height: 40px;
}
.md-list-item-content {
  overflow: hidden;
}
.md-list-item-content h3 {
  margin: 0 0 3px 0;
  font-weight: normal;
}
.md-navbar {
  min-height: 60px;
  position: relative;
  z-index: 50;
}
.md-navbar .navbar-header {
  width: auto;
}
.md-navbar .navbar-header > button {
  padding: 15px 18px;
}
.md-navbar .navbar-collapse {
  margin-left: 0;
}
.md-navbar .navbar-brand {
  line-height: 60px;
}
.md-navbar .navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}
.md-navbar .navbar-btn {
  margin-top: 13px;
  margin-bottom: 13px;
}
.md-navbar .navbar-form-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.md-aside {
  width: 240px;
  max-width: 240px;
  position: relative;
  z-index: 40;
}
.md-content {
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 767px) {
  .off-screen.md-content {
    top: 60px;
    z-index: 45;
    max-width: 75%;
  }
  .off-screen.md-content + * {
    padding-top: 60px;
    z-index: 30;
  }
}
.md-form-group {
  padding: 18px 0 24px 0;
  position: relative;
}
.md-input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit;
}
.md-input:focus,
.md-input.focus {
  border-color: rgba(160, 160, 160, 0.2);
  border-bottom-width: 1px;
  padding-bottom: 1px;
}
.md-input:focus ~ label,
.md-input.focus ~ label {
  color: #7266ba;
  opacity: 1;
  top: 0 !important;
  font-size: 0.85em !important;
}
.md-input:focus ~ label:after,
.md-input.focus ~ label:after {
  visibility: visible;
  width: 100%;
  left: 0;
}
.float-label .md-input ~ label {
  top: 20px;
  font-size: 1em;
}
.float-label .md-input.ng-dirty ~ label,
.float-label .md-input.has-value ~ label {
  top: 0;
  font-size: 0.85em;
}
.md-input ~ label {
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.85em;
  position: absolute;
  z-index: 10;
  opacity: 0.5;
  display: inline-block;
  top: 0px;
  left: 0;
  margin-bottom: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}
.md-input ~ label:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  height: 2px;
  width: 10px;
  visibility: hidden;
  background-color: #2196f3;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.md-input.disabled,
.md-input[disabled] {
  opacity: 0.5;
}
textarea.md-input {
  height: auto;
}
.md-input-white:focus,
.md-input-white.focus {
  border-color: #fff;
}
.md-input-white:focus ~ label,
.md-input-white.focus ~ label {
  color: #fff;
}
.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: 0.85em;
}
.md-input-msg.right {
  right: 0;
}
.md-check,
.form-group label.md-check {
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: block;
  margin: 8px;
  white-space: nowrap;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 18px;
  line-height: 26px;
  min-width: 18px;
  min-height: 18px;
}
.md-check input,
.form-group label.md-check input {
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 1;
  opacity: 0;
  margin-left: -20px;
}
.md-check input .waves-ripple,
.form-group label.md-check input .waves-ripple {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
.md-check input:checked + i:before,
.form-group label.md-check input:checked + i:before {
  border-width: 0;
  background-color: inherit;
}
.md-check input:checked + span .active,
.form-group label.md-check input:checked + span .active {
  display: inherit;
}
.md-check input[type="radio"] + i,
.form-group label.md-check input[type="radio"] + i,
.md-check input[type="radio"] + i:before,
.form-group label.md-check input[type="radio"] + i:before {
  border-radius: 50%;
}
.md-check input[type="checkbox"]:checked + i:after,
.form-group label.md-check input[type="checkbox"]:checked + i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: ' ';
}
.md-check input[type="radio"]:checked + i:after,
.form-group label.md-check input[type="radio"]:checked + i:after {
  position: absolute;
  left: 6px;
  top: 6px;
  display: table;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  content: ' ';
}
.md-check input[disabled] + i:before,
.form-group label.md-check input[disabled] + i:before,
fieldset[disabled] .md-check input + i:before,
fieldset[disabled] .form-group label.md-check input + i:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.md-check input[disabled]:checked + i:before,
.form-group label.md-check input[disabled]:checked + i:before {
  background-color: rgba(0, 0, 0, 0.26);
}
.md-check > i,
.form-group label.md-check > i {
  width: 0;
  height: 18px;
  line-height: 1;
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: relative;
}
.md-check > i:before,
.form-group label.md-check > i:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-check > i.no-icon:after,
.form-group label.md-check > i.no-icon:after {
  display: none !important;
}
.md-check {
  box-sizing: border-box;
  display: block;
  margin: 8px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 18px;
  position: relative;
  line-height: 26px;
  min-width: 18px;
  min-height: 18px;
}
.md-check *,
.md-check *:before,
.md-check *:after {
  box-sizing: border-box;
}
.md-check .md-container {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.md-check .md-container:before {
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
.md-check .md-container:after {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.md-check .md-container .ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
.md-check .md-icon {
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
}
.md-check input {
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 1;
  opacity: 0;
  margin: 0;
}
.md-check input:checked + .md-icon {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.87);
  border: none;
}
.md-check input:checked + .md-icon:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: '';
}
.md-check .md-label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.md-check .md-label span {
  margin-left: 10px;
}
md-checkbox.md-focused:not([disabled]) .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}
md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
  background-color: rgba(0, 0, 0, 0.12);
}
md-checkbox .md-container {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
md-checkbox .md-container:before {
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}
md-checkbox .md-container:after {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
md-checkbox .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}
md-checkbox .md-icon {
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
}
md-checkbox.md-checked .md-icon {
  border: none;
}
md-checkbox[disabled] {
  cursor: no-drop;
}
md-checkbox.md-checked .md-icon:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: '';
}
md-checkbox .md-label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
md-checkbox .md-label span {
  margin-left: 10px;
}
.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px;
}
.md-switch input {
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -36px;
}
.md-switch input:checked + i:before {
  background: inherit;
  opacity: 0.5;
}
.md-switch input:checked + i:after {
  background: inherit;
  left: 16px;
}
.md-switch input[disabled] + i:before,
fieldset[disabled] .md-switch input + i:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.md-switch input[disabled] + i:after,
fieldset[disabled] .md-switch input + i:after {
  background-color: #bdbdbd;
}
.md-switch i {
  width: 0px;
  height: 18px;
  line-height: 1;
  margin-left: -36px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 44px;
  position: relative;
}
.md-switch i:before {
  content: "";
  left: 1px;
  width: 34px;
  top: 3px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
  background-color: #9e9e9e;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-switch i:after {
  content: "";
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-whiteframe-z0 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.md-whiteframe-z1 {
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-whiteframe-z2 {
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
}
.md-whiteframe-z3 {
  box-shadow: 0px 17px 50px rgba(0, 0, 0, 0.19);
}
.md-whiteframe-z4 {
  box-shadow: 0px 16px 28px 0 rgba(0, 0, 0, 0.22);
}
.md-whiteframe-z5 {
  box-shadow: 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
}
#flash-messages {
  top: 64px;
  left: 64px;
  position: fixed;
  z-index: 1300;
  right: 0;
}
#flash-messages .alert {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#flash-messages .alert-success {
  background-color: #81c784;
  border-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.87);
}
#flash-messages .alert-success hr {
  border-top-color: rgba(0, 0, 0, 0);
}
#flash-messages .alert-success .alert-link {
  color: rgba(230, 230, 230, 0.87);
}
#flash-messages .alert-info {
  background-color: #64b5f6;
  border-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.87);
}
#flash-messages .alert-info hr {
  border-top-color: rgba(0, 0, 0, 0);
}
#flash-messages .alert-info .alert-link {
  color: rgba(230, 230, 230, 0.87);
}
#flash-messages .alert-warning {
  background-color: #fff176;
  border-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.87);
}
#flash-messages .alert-warning hr {
  border-top-color: rgba(0, 0, 0, 0);
}
#flash-messages .alert-warning .alert-link {
  color: rgba(230, 230, 230, 0.87);
}
#flash-messages .alert-danger {
  background-color: #e57373;
  border-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.87);
}
#flash-messages .alert-danger hr {
  border-top-color: rgba(0, 0, 0, 0);
}
#flash-messages .alert-danger .alert-link {
  color: rgba(230, 230, 230, 0.87);
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?-9heso');
  src: url('../fonts/icomoon/icomoon.eot?#iefix-9heso') format('embedded-opentype'), url('../fonts/icomoon/icomoon.woff?-9heso') format('woff'), url('../fonts/icomoon/icomoon.ttf?-9heso') format('truetype'), url('../fonts/icomoon/icomoon.svg?-9heso#icomoon') format('svg');
}
.simform {
  position: relative;
  margin: 0 auto;
  padding: 2em 0;
  max-width: 860px;
  width: 100%;
  text-align: left;
  font-size: 2.5em;
}
.simform .submit {
  display: none;
}
/* Question list style */
.simform ol {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}
.simform ol:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.35em;
}
.questions > li {
  z-index: 100;
  position: relative;
  visibility: hidden;
  height: 0;
  -webkit-transition: visibility 0s 0.4s, height 0s 0.4s;
  transition: visibility 0s 0.4s, height 0s 0.4s;
}
.questions li.current,
.no-js .questions li {
  visibility: visible;
  height: auto;
  -webkit-transition: none;
  transition: none;
}
/* Labels */
.questions li > span {
  display: block;
  overflow: hidden;
}
.questions li > span label {
  display: block;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.questions li.current > span label,
.no-js .questions li > span label {
  -webkit-transition: none;
  transition: none;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.show-next .questions li.current > span label {
  -webkit-animation: moveUpFromDown 0.4s both;
  animation: moveUpFromDown 0.4s both;
}
@-webkit-keyframes moveUpFromDown {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
  }
}
@keyframes moveUpFromDown {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Input field */
.questions input {
  display: block;
  margin: 0.3em 0 0 0;
  padding: 0.2em 1em 0.5em 0.7em;
  width: calc(98%);
  border: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1em;
  line-height: 1;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  height: 2.1em;
}
.questions .current input,
.no-js .questions input {
  opacity: 1;
}
.questions input:focus,
.simform button:focus {
  outline: none;
}
/* Next question button */
.next {
  position: absolute;
  right: 0;
  bottom: 2.15em;
  /* padding-bottom of form plus progress bar height */
  display: block;
  padding: 0;
  width: 2em;
  height: 2em;
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.next:hover {
  color: rgba(0, 0, 0, 0.5);
}
.next::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "\e600";
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: 'icomoon';
  line-height: 2;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.next.show {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  pointer-events: auto;
}
/* Progress bar */
.simform .progress {
  width: 0%;
  height: 0.15em;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.simform .progress::before {
  position: absolute;
  top: auto;
  width: 100%;
  height: inherit;
  background: rgba(0, 0, 0, 0.05);
  content: '';
}
/* Number indicator */
.simform .number {
  position: absolute;
  right: 0;
  overflow: hidden;
  margin: 0.4em 0;
  width: 3em;
  font-weight: 700;
  font-size: 0.4em;
}
.simform .number:after {
  position: absolute;
  left: 50%;
  content: '/';
  opacity: 0.4;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.simform .number span {
  float: right;
  width: 40%;
  text-align: center;
}
.simform .number .number-current {
  float: left;
}
.simform .number-next {
  position: absolute;
  left: 0;
}
.simform.show-next .number-current {
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.simform.show-next .number-next {
  -webkit-animation: moveUpFromDown 0.4s both;
  animation: moveUpFromDown 0.4s both;
}
/* Error and final message */
.simform .error-message,
.simform .final-message {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.simform .error-message {
  padding: 0.4em 3.5em 0 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  font-style: italic;
  font-size: 0.4em;
}
.final-message {
  top: 50%;
  left: 0;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.error-message.show,
.final-message.show {
  visibility: visible;
  opacity: 1;
}
.final-message.show {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
/* Final hiding of form / showing message */
.simform-inner.hide {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
/* No JS Fallback */
.no-js .simform {
  font-size: 1.75em;
}
.no-js .questions li {
  padding: 0 0 2em;
}
.no-js .simform .submit {
  display: block;
  float: right;
  padding: 10px 20px;
  border: none;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.4);
}
.no-js .simform .controls {
  display: none;
}
/* Remove IE clear cross */
input[type=text]::-ms-clear {
  display: none;
}
/* Adjust form for smaller screens */
@media screen and (max-width: 44.75em) {
  .simform {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 33.5625em) {
  .simform {
    font-size: 1.2em;
  }
}
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.cs-select select {
  display: none;
}
.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em;
}
.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-select > span::after {
  content: '\25BE';
  right: 1em;
}
.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em;
}
.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
div.cs-active {
  z-index: 200;
}
/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}
.cs-select.cs-active .cs-options {
  visibility: visible;
}
.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cs-select ul span {
  padding: 1em;
}
.cs-select ul li.cs-focus span {
  background-color: #ddd;
}
/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}
.cs-select li.cs-optgroup > span {
  cursor: default;
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?-rdnm34');
  src: url('../fonts/icomoon/icomoon.eot?#iefix-rdnm34') format('embedded-opentype'), url('../fonts/icomoon/icomoon.woff?-rdnm34') format('woff'), url('../fonts/icomoon/icomoon.ttf?-rdnm34') format('truetype'), url('../fonts/icomoon/icomoon.svg?-rdnm34#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
div.cs-skin-boxes {
  background: transparent;
  font-size: 1.25em;
  font-weight: 700;
  max-width: 300px;
  z-index: 2000;
  color: #524e4f;
}
@media screen and (max-width: 30em) {
  div.cs-skin-boxes {
    font-size: 1em;
  }
}
.cs-skin-boxes > span {
  background: #fff;
  border: 4px solid #524e4f;
}
.cs-skin-boxes > span::after,
.cs-skin-boxes.cs-active > span::after {
  font-family: 'icomoon';
  top: auto;
  -webkit-transform: none;
  transform: none;
  content: '\e014';
}
.cs-skin-boxes .cs-options {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  background: #fffed8;
  font-size: 75%;
  opacity: 0;
  -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.cs-skin-boxes.cs-active .cs-options {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.cs-skin-boxes .cs-options ul {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.cs-skin-boxes .cs-options li {
  width: 20%;
  height: 25%;
  float: left;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  opacity: 0;
  border: 10px solid transparent;
  background-clip: padding-box;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  box-shadow: inset 0 -6em 0 -2em #ffffff;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
@media screen and (max-width: 30em) {
  .cs-skin-boxes .cs-options li {
    box-shadow: none;
    border-width: 3px;
    border-radius: 8px;
  }
}
.cs-skin-boxes.cs-active .cs-options li {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.01s;
  transition-delay: 0.01s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.02s;
  transition-delay: 0.02s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.03s;
  transition-delay: 0.03s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.04s;
  transition-delay: 0.04s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(6) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(7) {
  -webkit-transition-delay: 0.06s;
  transition-delay: 0.06s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(8) {
  -webkit-transition-delay: 0.07s;
  transition-delay: 0.07s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(9) {
  -webkit-transition-delay: 0.08s;
  transition-delay: 0.08s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(10) {
  -webkit-transition-delay: 0.09s;
  transition-delay: 0.09s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(11) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(12) {
  -webkit-transition-delay: 0.11s;
  transition-delay: 0.11s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(13) {
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(14) {
  -webkit-transition-delay: 0.13s;
  transition-delay: 0.13s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(15) {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(16) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(17) {
  -webkit-transition-delay: 0.16s;
  transition-delay: 0.16s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(18) {
  -webkit-transition-delay: 0.17s;
  transition-delay: 0.17s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(19) {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.cs-skin-boxes.cs-active .cs-options li:nth-child(20) {
  -webkit-transition-delay: 0.19s;
  transition-delay: 0.19s;
}
.cs-skin-boxes .cs-options li.cs-selected::after {
  font-family: 'icomoon';
  content: '\e00d';
  color: #fff;
  position: absolute;
  font-size: 2em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) translate3d(0, -1em, 0);
  transform: translate3d(-50%, -50%, 0) translate3d(0, -1em, 0);
}
.cs-skin-boxes .cs-options li.cs-selected span::after {
  content: '';
}
@media screen and (max-width: 30em) {
  .cs-skin-boxes .cs-options li.cs-selected::after {
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
.cs-skin-boxes .cs-options li.color-588c75 {
  background-color: #588c75;
}
.cs-skin-boxes .cs-options li.color-b0c47f {
  background-color: #b0c47f;
}
.cs-skin-boxes .cs-options li.color-f3e395 {
  background-color: #f3e395;
}
.cs-skin-boxes .cs-options li.color-f3ae73 {
  background-color: #f3ae73;
}
.cs-skin-boxes .cs-options li.color-da645a {
  background-color: #da645a;
}
.cs-skin-boxes .cs-options li.color-79a38f {
  background-color: #79a38f;
}
.cs-skin-boxes .cs-options li.color-c1d099 {
  background-color: #c1d099;
}
.cs-skin-boxes .cs-options li.color-f5eaaa {
  background-color: #f5eaaa;
}
.cs-skin-boxes .cs-options li.color-f5be8f {
  background-color: #f5be8f;
}
.cs-skin-boxes .cs-options li.color-e1837b {
  background-color: #e1837b;
}
.cs-skin-boxes .cs-options li.color-9bbaab {
  background-color: #9bbaab;
}
.cs-skin-boxes .cs-options li.color-d1dcb2 {
  background-color: #d1dcb2;
}
.cs-skin-boxes .cs-options li.color-f9eec0 {
  background-color: #f9eec0;
}
.cs-skin-boxes .cs-options li.color-f7cda9 {
  background-color: #f7cda9;
}
.cs-skin-boxes .cs-options li.color-e8a19b {
  background-color: #e8a19b;
}
.cs-skin-boxes .cs-options li.color-bdd1c8 {
  background-color: #bdd1c8;
}
.cs-skin-boxes .cs-options li.color-e1e7cd {
  background-color: #e1e7cd;
}
.cs-skin-boxes .cs-options li.color-faf4d4 {
  background-color: #faf4d4;
}
.cs-skin-boxes .cs-options li.color-fbdfc9 {
  background-color: #fbdfc9;
}
.cs-skin-boxes .cs-options li.color-f1c1bd {
  background-color: #f1c1bd;
}
.cs-skin-boxes .cs-options span {
  position: absolute;
  width: 100%;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px 15px;
  margin: 0;
}
@media screen and (max-width: 30em) {
  .cs-skin-boxes .cs-options span {
    display: none;
  }
}
.cs-skin-boxes .cs-options li span:hover,
.cs-skin-boxes li.cs-focus span,
.cs-skin-boxes li.cs-selected span {
  color: #333;
}
.select2-drop.fs-select {
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
  top: 0 !important;
  bottom: 0 !important;
  opacity: 0;
  background: #ffc107 !important;
}
.select2-drop.fs-select .select2-results {
  height: 100%;
  max-height: initial;
}
.select2-drop.fs-select .select2-results li {
  width: 20%;
  height: 25%;
  float: left;
  position: relative;
  cursor: pointer;
  border: 10px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  box-shadow: inset 0 -6em 0 -2em #ffffff;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.select2-drop.fs-select.select2-drop-active {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.8s, opacity 0.8s;
  transition: transform 0.8s, opacity 0.8s;
}
.select2-drop.fs-select.select2-drop-active .select2-results li {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.fs-select .select2-results .select2-result-label {
  position: absolute;
  width: 100%;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px 15px;
  margin: 0;
  background-color: #fff;
}
.bid .order-container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}
.bid .order-scroller {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.bid ul,
.bid li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.bid li {
  list-style-type: none;
}
.bid .order {
  background: #FFF;
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.4em;
  text-align: left;
  max-width: 500px;
  -webkit-transform: translate3d(0, 0, 0);
}
.bid .order .orderline {
  width: 100%;
  margin: 0px;
  padding: 3px 15px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 250ms ease-in-out;
  -moz-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
}
.bid .order .orderline.selected {
  background: rgba(140, 143, 183, 0.2);
  -webkit-transition: background 250ms ease-in-out;
  -moz-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
  cursor: default;
}
.bid .order .orderline .product-name {
  padding: 0;
  display: inline-block;
  font-weight: bold;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bid .order .orderline .price {
  padding: 0;
  font-weight: bold;
  float: right;
}
.bid .order .orderline .info-list {
  color: #888;
  margin-left: 10px;
}
.bid .order .orderline .info-list em {
  color: #777;
  font-weight: bold;
  font-style: normal;
}
