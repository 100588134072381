/* 

*/
@import url(http://fonts.googleapis.com/css?family=RobotoDraft:500,900italic,900,400italic,100,700italic,300,700,500italic,100italic,300italic,400);
/*
* colors.less
*
*/
/* To Load Hinted Fonts for Windows */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url(http://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYAzyDMXhdD8sAj6OAJTFsBI.woff2) format('woff2'), url(http://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff'), url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-Bold'), url(http://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcmaVI6zN22yiurzcBKxPjFE.woff2) format('woff2'), url(http://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcnbFhgvWbfSbdVg11QabG8w.woff) format('woff'), url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}
/*=================================================
  HELPERS
===================================================
  A. List Helpers
=================================================== */
/* list item spacing helpers */
.list-spacing-3 li + li {
  padding-top: 3px;
}
.list-spacing-5 li + li {
  padding-top: 5px;
}
.list-spacing-8 li + li {
  padding-top: 8px;
}
.list-spacing-10 li + li {
  padding-top: 10px;
}
.list-spacing-15 li + li {
  padding-top: 15px;
}
/*divide li items with border and extra spacing*/
.list-divide-items {
  margin: 0;
  padding-left: 10px;
  list-style: none;
}
.list-divide-items li + li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #EEE;
}
/*===================================================
  B. Height Helpers
===================================================== */
/* Height helpers, primarly used with panel widgets */
.fixed-height .panel-body {
  overflow: auto;
}
.hover-height {
  overflow: hidden;
  transition: all 0.2s ease;
}
.mh-20 {
  max-height: 20px !important;
}
.mh-25 {
  max-height: 25px !important;
}
.mh-30 {
  max-height: 30px !important;
}
.mh-35 {
  max-height: 35px !important;
}
.mh-40 {
  max-height: 40px !important;
}
.mh-50 {
  max-height: 50px !important;
}
.mh-100 {
  max-height: 100px !important;
}
.mh-200 {
  max-height: 200px !important;
}
.mh-300 {
  max-height: 300px !important;
}
.mh-400 {
  max-height: 400px !important;
}
.mh-500 {
  max-height: 500px !important;
}
.mh-600 {
  max-height: 600px !important;
}
.mh-700 {
  max-height: 700px !important;
}
.mh-800 {
  max-height: 800px !important;
}
.mh-900 {
  max-height: 900px !important;
}
.mh-1000 {
  max-height: 1000px !important;
}
.h-20 {
  height: 20px !important;
}
.h-25 {
  height: 25px !important;
}
.h-30 {
  height: 30px !important;
}
.h-35 {
  height: 35px !important;
}
.h-40 {
  height: 40px !important;
}
.h-50 {
  height: 50px !important;
}
.h-80 {
  height: 80px !important;
}
.h-100 {
  height: 100px !important;
}
.h-120 {
  height: 120px !important;
}
.h-150 {
  height: 150px !important;
}
.h-170 {
  height: 170px !important;
}
.h-200 {
  height: 200px !important;
}
.h-300 {
  height: 300px !important;
}
.h-400 {
  height: 400px !important;
}
.h-500 {
  height: 500px !important;
}
.h-600 {
  height: 600px !important;
}
.h-700 {
  height: 700px !important;
}
.h-800 {
  height: 800px !important;
}
.h-900 {
  height: 900px !important;
}
.h-1000 {
  height: 1000px !important;
}
/*===================================================
  A. General/Misc Helpers
===================================================== */
/* Transform Center Helper(experimental) */
.t-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* BG Helpers */
.bg-none {
  background-color: transparent !important;
}
/* box-shadow Helpers */
.no-boxshadow {
  box-shadow: none !important;
}
/* height helpers */
.h-a {
  height: auto !important;
}
/* overflow Helpers */
.of-a {
  overflow: auto !important;
}
.of-h {
  overflow: hidden !important;
}
.of-v {
  overflow: visible !important;
}
.of-x-a {
  overflow-x: auto !important;
}
.of-x-h {
  overflow-x: hidden !important;
}
.of-x-v {
  overflow-x: visible !important;
}
.of-y-a {
  overflow-y: auto !important;
}
.of-y-h {
  overflow-y: hidden !important;
}
.of-y-v {
  overflow-y: visible !important;
}
/* Center Grid Column Helper */
.center-column {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
/* Vertical Align Helpers */
.va-t {
  vertical-align: top !important;
}
.va-m {
  vertical-align: middle !important;
}
.va-b {
  vertical-align: bottom !important;
}
.va-s {
  vertical-align: super !important;
}
/* Relative Position Helper */
.posr {
  position: relative !important;
}
/* Inline Block Helper */
.ib,
.inline-object {
  display: inline-block !important;
}
/* pointer cursor */
.cursor {
  cursor: pointer !important;
}
/* Useful for emphasizing a disabled input */
.option-disabled {
  opacity: 0.6 !important;
}
/* unstyled input */
.input-unstyled,
.input-unstyled:hover,
.input-unstyled:focus {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
}
/*===================================================
  B. Table Layout Helpers - specifically for widgets
===================================================== */
/* table-layout for connecting panels */
.panel-group.table-layout > .panel + .panel {
  border-left: 0;
}
.panel-group.table-layout + .panel-group.table-layout > .panel {
  border-top: 0;
}
/* table-layout when attached to row */
.row.table-layout {
  margin-left: 0;
  margin-right: 0;
  border-collapse: collapse;
}
.table-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}
/* table-layout helper content */
.table-layout > div,
.table-layout > aside,
.table-layout > section {
  vertical-align: middle;
  display: table-cell;
  float: none;
}
@media (max-width: 1300px) {
  .table-layout.table-clear-md,
  .table-layout.table-clear-md > div,
  .table-layout.table-clear-md > aside,
  .table-layout.table-clear-md > section {
    display: block;
    float: none;
  }
}
@media (max-width: 992px) {
  .table-layout.table-clear-sm,
  .table-layout.table-clear-sm > div,
  .table-layout.table-clear-sm > aside,
  .table-layout.table-clear-sm > section {
    display: block;
    float: none;
  }
}
@media (max-width: 767px) {
  .table-layout.table-clear-xs,
  .table-layout.table-clear-xs > div,
  .table-layout.table-clear-xs > aside,
  .table-layout.table-clear-xs > section {
    display: block;
    float: none;
  }
}
/*===============================================
  C. Text Helpers
================================================= */
.text-uppercase {
  text-transform: uppercase !important;
}
.fw200 {
  font-weight: 200 !important;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fs3 {
  font-size: 4px !important;
}
.fs4 {
  font-size: 4px !important;
}
.fs5 {
  font-size: 5px !important;
}
.fs6 {
  font-size: 6px !important;
}
.fs7 {
  font-size: 7px !important;
}
.fs8 {
  font-size: 8px !important;
}
.fs9 {
  font-size: 9px !important;
}
.fs10 {
  font-size: 10px !important;
}
.fs11 {
  font-size: 11px !important;
}
.fs12 {
  font-size: 12px !important;
}
.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px !important;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
  font-size: 16px !important;
}
.fs16 {
  font-size: 17px !important;
}
.fs18 {
  font-size: 18px !important;
}
.fs20 {
  font-size: 20px !important;
}
.fs22 {
  font-size: 22px !important;
}
.fs24 {
  font-size: 24px !important;
}
.fs26 {
  font-size: 26px !important;
}
.fs28 {
  font-size: 28px !important;
}
.fs30 {
  font-size: 30px !important;
}
.fs35 {
  font-size: 35px !important;
}
.fs40 {
  font-size: 40px !important;
}
.fs45 {
  font-size: 45px !important;
}
.fs50 {
  font-size: 50px !important;
}
.fs60 {
  font-size: 60px !important;
}
.fs70 {
  font-size: 70px !important;
}
.fs80 {
  font-size: 80px !important;
}
.fs90 {
  font-size: 90px !important;
}
.fs100 {
  font-size: 100px !important;
}
/*===============================================
  D. Padding Helpers
================================================= */
.pn {
  padding: 0 !important;
}
.p1 {
  padding: 1px !important;
}
.p2 {
  padding: 2px !important;
}
.p3 {
  padding: 3px !important;
}
.p4 {
  padding: 4px !important;
}
.p5 {
  padding: 5px !important;
}
.p6 {
  padding: 6px !important;
}
.p7 {
  padding: 7px !important;
}
.p8 {
  padding: 8px !important;
}
.p10 {
  padding: 10px !important;
}
.p12 {
  padding: 12px !important;
}
.p15 {
  padding: 15px !important;
}
.p20 {
  padding: 20px !important;
}
.p25 {
  padding: 25px !important;
}
.p30 {
  padding: 30px !important;
}
.p35 {
  padding: 35px !important;
}
.p40 {
  padding: 40px !important;
}
.p50 {
  padding: 50px !important;
}
.ptn {
  padding-top: 0 !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt25 {
  padding-top: 25px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pt35 {
  padding-top: 35px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.prn {
  padding-right: 0 !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr25 {
  padding-right: 25px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr35 {
  padding-right: 35px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pbn {
  padding-bottom: 0 !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb25 {
  padding-bottom: 25px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb35 {
  padding-bottom: 35px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pln {
  padding-left: 0 !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl25 {
  padding-left: 25px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl35 {
  padding-left: 35px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl50 {
  padding-left: 50px !important;
}
/* Axis Padding (both top/bottom or left/right) */
.pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.pv8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.ph8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ph15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ph25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
/* responsive padding helpers */
@media (min-width: 1140px) {
  .ptn-lg {
    padding-top: 0 !important;
  }
  .prn-lg {
    padding-right: 0 !important;
  }
  .pbn-lg {
    padding-bottom: 0 !important;
  }
  .pln-lg {
    padding-left: 0 !important;
  }
  .pt5-lg {
    padding-top: 5px !important;
  }
  .pr5-lg {
    padding-right: 5px !important;
  }
  .pb5-lg {
    padding-bottom: 5px !important;
  }
  .pl5-lg {
    padding-left: 5px !important;
  }
}
@media (min-width: 992px) {
  .ptn-md {
    padding-top: 0 !important;
  }
  .prn-md {
    padding-right: 0 !important;
  }
  .pbn-md {
    padding-bottom: 0 !important;
  }
  .pln-md {
    padding-left: 0 !important;
  }
  .pt5-md {
    padding-top: 5px !important;
  }
  .pr5-md {
    padding-right: 5px !important;
  }
  .pb5-md {
    padding-bottom: 5px !important;
  }
  .pl5-md {
    padding-left: 5px !important;
  }
}
/*===============================================
  E. Margin Helpers
================================================= */
/* margin center helper */
.mauto {
  margin-left: auto;
  margin-right: auto;
}
.mn {
  margin: 0 !important;
}
.m1 {
  margin: 1px !important;
}
.m2 {
  margin: 2px !important;
}
.m3 {
  margin: 3px !important;
}
.m4 {
  margin: 4px !important;
}
.m5 {
  margin: 5px !important;
}
.m8 {
  margin: 8px !important;
}
.m10 {
  margin: 10px !important;
}
.m15 {
  margin: 15px !important;
}
.m20 {
  margin: 20px !important;
}
.m25 {
  margin: 25px !important;
}
.m30 {
  margin: 30px !important;
}
.m35 {
  margin: 35px !important;
}
.m40 {
  margin: 40px !important;
}
.m50 {
  margin: 50px !important;
}
.mtn {
  margin-top: 0 !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mrn {
  margin-right: 0 !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr25 {
  margin-right: 25px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr35 {
  margin-right: 35px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mbn {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb35 {
  margin-bottom: 35px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb70 {
  margin-bottom: 70px !important;
}
.mln {
  margin-left: 0 !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml25 {
  margin-left: 25px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml35 {
  margin-left: 35px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml50 {
  margin-left: 50px !important;
}
/* Axis Margins (both top/bottom or left/right) */
.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mv15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mv25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.mv70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.mh5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mh15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mh25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.mh70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}
/* Negative Margin Helpers */
.mtn5 {
  margin-top: -5px !important;
}
.mtn10 {
  margin-top: -10px !important;
}
.mtn15 {
  margin-top: -15px !important;
}
.mtn20 {
  margin-top: -20px !important;
}
.mtn30 {
  margin-top: -30px !important;
}
.mrn5 {
  margin-right: -5px !important;
}
.mrn10 {
  margin-right: -10px !important;
}
.mrn15 {
  margin-right: -15px !important;
}
.mrn20 {
  margin-right: -20px !important;
}
.mrn30 {
  margin-right: -30px !important;
}
.mbn5 {
  margin-bottom: -5px !important;
}
.mbn10 {
  margin-bottom: -10px !important;
}
.mbn15 {
  margin-bottom: -15px !important;
}
.mbn20 {
  margin-bottom: -20px !important;
}
.mbn30 {
  margin-bottom: -30px !important;
}
.mln5 {
  margin-left: -5px !important;
}
.mln10 {
  margin-left: -10px !important;
}
.mln15 {
  margin-left: -15px !important;
}
.mln20 {
  margin-left: -20px !important;
}
.mln30 {
  margin-left: -30px !important;
}
/* Vertical Negative Margin "mv" + "n" + "x" */
.mvn5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}
.mvn10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
.mvn15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}
.mvn20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}
.mvn30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}
/* Horizontal Negative Margin "mh" + "n" + "x" */
.mhn5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
.mhn10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.mhn15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.mhn20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.mhn30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}
/*===============================================
  F. LineHeight Helpers
================================================= */
.lh0 {
  line-height: 0 !important;
}
.lh5 {
  line-height: 5px !important;
}
.lh10 {
  line-height: 10px !important;
}
.lh15 {
  line-height: 15px !important;
}
.lh20 {
  line-height: 20px !important;
}
.lh25 {
  line-height: 25px !important;
}
.lh30 {
  line-height: 30px !important;
}
.lh35 {
  line-height: 35px !important;
}
.lh40 {
  line-height: 40px !important;
}
/*===============================================
  G. Border Helpers
================================================= */
/* Border Disables */
.br-l-n {
  border-left: none !important;
}
.br-r-n {
  border-right: none !important;
}
.br-t-n {
  border-top: none !important;
}
.br-b-n {
  border-bottom: none !important;
}
.br-n {
  border: none !important;
}
.br-v-n {
  border-top: none !important;
  border-bottom: none !important;
}
.br-h-n {
  border-left: none !important;
  border-right: none !important;
}
/* Default Borders */
.br-a {
  border: 1px solid #eeeeee !important;
}
.br-l {
  border-left: 1px solid #eeeeee !important;
}
.br-r {
  border-right: 1px solid #eeeeee !important;
}
.br-t {
  border-top: 1px solid #eeeeee !important;
}
.br-b {
  border-bottom: 1px solid #eeeeee !important;
}
/* Border Style */
.br-dashed {
  border-style: dotted !important;
}
/* Border Radius */
.br0 {
  border-radius: 0px !important;
}
.br1 {
  border-radius: 1px !important;
}
.br2 {
  border-radius: 2px !important;
}
.br3 {
  border-radius: 3px !important;
}
.br4 {
  border-radius: 4px !important;
}
.br6 {
  border-radius: 6px !important;
}
.br8 {
  border-radius: 8px !important;
}
.br12 {
  border-radius: 12px !important;
}
.br24 {
  border-radius: 24px !important;
}
.br64 {
  border-radius: 64px !important;
}
/* Border Width */
.bw1 {
  border-width: 1px !important;
}
.bw2 {
  border-width: 2px !important;
}
.bw3 {
  border-width: 3px !important;
}
.bw4 {
  border-width: 4px !important;
}
.bw5 {
  border-width: 5px !important;
}
.bw8 {
  border-width: 8px !important;
}
.bw10 {
  border-width: 10px !important;
}
.bw15 {
  border-width: 15px !important;
}
/*Border Colors Generate contextual modifier classes for colorizing the alert.
*/
.br-white {
  border-color: #ffffff !important;
}
.br-lighter {
  border-color: #eeeeee !important;
}
.br-light {
  border-color: #e7e7e7 !important;
}
.br-grey {
  border-color: #d9d9d9 !important;
}
.br-greyer {
  border-color: #cccccc !important;
}
.br-dark {
  border-color: #3a3f51 !important;
}
.br-dark-light {
  border-color: #4f566f !important;
}
.br-primary {
  border-color: #00a08e !important;
}
.br-primary-light {
  border-color: #00d3bb !important;
}
.br-info {
  border-color: #23b7e5 !important;
}
.br-info-light {
  border-color: #51c6ea !important;
}
.br-success {
  border-color: #27c24c !important;
}
.br-success-light {
  border-color: #43d967 !important;
}
.br-warning {
  border-color: #fad733 !important;
}
.br-warning-light {
  border-color: #fbe165 !important;
}
.br-danger {
  border-color: #f05050 !important;
}
.br-danger-light {
  border-color: #f47f7f !important;
}
.br-alert {
  border-color: #967adc !important;
}
.br-alert-light {
  border-color: #b6a2e7 !important;
}
.br-system {
  border-color: #37bc9b !important;
}
.br-system-light {
  border-color: #58ceb1 !important;
}
.br-tp-top {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.br-tp-right {
  border-right-color: rgba(0, 0, 0, 0.1);
}
.br-tp-left {
  border-left-color: rgba(0, 0, 0, 0.1);
}
/*===============================================
  H. Max Width Helpers
================================================= */
.fluid-width {
  width: 100% !important;
}
.mw10 {
  max-width: 10px !important;
}
.mw20 {
  max-width: 20px !important;
}
.mw30 {
  max-width: 30px !important;
}
.mw35 {
  max-width: 35px !important;
}
.mw40 {
  max-width: 40px !important;
}
.mw45 {
  max-width: 45px !important;
}
.mw50 {
  max-width: 50px !important;
}
.mw60 {
  max-width: 60px !important;
}
.mw80 {
  max-width: 80px !important;
}
.mw100 {
  max-width: 100px !important;
}
.mw140 {
  max-width: 140px !important;
}
.mw160 {
  max-width: 160px !important;
}
.mw180 {
  max-width: 180px !important;
}
.mw200 {
  max-width: 200px !important;
}
.mw240 {
  max-width: 240px !important;
}
.mw280 {
  max-width: 280px !important;
}
.mw320 {
  max-width: 320px !important;
}
.mw400 {
  max-width: 400px !important;
}
.mw450 {
  max-width: 450px !important;
}
.mw500 {
  max-width: 500px !important;
}
.mw600 {
  max-width: 600px !important;
}
.mw700 {
  max-width: 700px !important;
}
.mw800 {
  max-width: 800px !important;
}
.mw900 {
  max-width: 900px !important;
}
.mw1000 {
  max-width: 1000px !important;
}
.mw1100 {
  max-width: 1100px !important;
}
.mw1200 {
  max-width: 1200px !important;
}
/*Regular Width Helpers. Primarily used
on tables, feel free to add more*/
.w20 {
  width: 20px !important;
}
.w30 {
  width: 30px !important;
}
.w40 {
  width: 40px !important;
}
.w50 {
  width: 50px !important;
}
.w75 {
  width: 75px !important;
}
.w100 {
  width: 100px !important;
}
.w125 {
  width: 125px !important;
}
.w150 {
  width: 150px !important;
}
.w175 {
  width: 175px !important;
}
.w200 {
  width: 200px !important;
}
.w225 {
  width: 225px !important;
}
.w250 {
  width: 250px !important;
}
.w300 {
  width: 300px !important;
}
.w320 {
  width: 320px !important;
}
.w350 {
  width: 350px !important;
}
.w400 {
  width: 400px !important;
}
.w450 {
  width: 450px !important;
}
.mnw20 {
  min-width: 20px !important;
}
.mnw30 {
  min-width: 30px !important;
}
.mnw40 {
  min-width: 40px !important;
}
.mnw50 {
  min-width: 50px !important;
}
.mnw75 {
  min-width: 75px !important;
}
.mnw100 {
  min-width: 100px !important;
}
.mnw125 {
  min-width: 125px !important;
}
.mnw150 {
  min-width: 150px !important;
}
.mnw175 {
  min-width: 175px !important;
}
.mnw200 {
  min-width: 200px !important;
}
.mnw250 {
  min-width: 250px !important;
}
.mnw300 {
  min-width: 300px !important;
}
.mnw500 {
  min-width: 500px !important;
}
.mnw700 {
  min-width: 700px !important;
}
.mnw1000 {
  min-width: 1000px !important;
}
.dockmodal,
.dockmodal *,
.dockmodal *:before,
.dockmodal *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.modal-placeholder {
  display: none;
  visibility: hidden;
  height: 0;
  width: 0;
}
.dockmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background: #000;
  opacity: 0.7;
  display: none;
}
.dockmodal {
  position: fixed;
  right: 20px;
  bottom: 0;
  top: auto;
  z-index: 1000;
  height: 0;
  background: transparent;
  border-bottom: 0;
  box-shadow: 0 1px 0px #000;
  overflow: hidden;
  border: 0;
}
.dockmodal-header {
  height: 36px;
  padding: 5px 5px 5px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #30363e;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.dockmodal-body {
  background: #FFF;
  position: absolute;
  top: 36px;
  bottom: 40px;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 10px 15px;
}
.dockmodal-footer {
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #CCC;
  overflow: hidden;
  text-align: right;
}
.dockmodal-footer-buttonset {
  height: 30px;
  line-height: 30px;
}
.dockmodal.no-footer .dockmodal-body {
  bottom: 0;
}
.dockmodal-header .title-text {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  vertical-align: top;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dockmodal-header .header-action {
  padding: 5px;
  float: right;
  margin-right: 1px;
  line-height: 16px;
}
.dockmodal-header .header-action:hover {
  background: #555;
}
.dockmodal-header .header-action i {
  background: url(../../../../img/dockmodal/sprite.png) no-repeat top left;
  vertical-align: top;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 16px;
  height: 16px;
}
.dockmodal-header .header-action .icon-dockmodal-close {
  background-position: 0 0;
}
.dockmodal-header .header-action .icon-dockmodal-popin {
  background-position: 0 -40px;
}
.dockmodal-header .header-action .icon-dockmodal-popout {
  background-position: 0 -60px;
}
.dockmodal.popped-out .header-action .icon-dockmodal-popout {
  background-position: 0 -40px;
}
.dockmodal-header .header-action .icon-dockmodal-minimize {
  background-position: 0 -75px;
}
.dockmodal-header .header-action .icon-dockmodal-restore {
  background-position: 0 -80px;
}
.dockmodal.minimized .header-action .icon-dockmodal-minimize {
  background-position: 0 -25px;
}
.dockmodal.popped-out {
  width: auto;
  height: auto;
  border: 0;
  z-index: 1501;
}
.dockmodal.minimized {
  height: 36px;
  top: auto;
  left: auto;
  right: 20px;
  bottom: 0;
  z-index: 1000;
}
.dockmodal.minimized .dockmodal-header {
  /*position: relative;*/
}
.dockmodal.minimized .dockmodal-body {
  /*display: none;*/
}
.dockmodal.minimized .dockmodal-footer {
  /*display: none;*/
}
.dockmodal .dockmodal-footer-buttonset > a {
  min-width: 60px;
}
.dockmodal .dockmodal-footer-buttonset > a + a {
  margin-left: 5px;
}
.modal-content {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0px;
}
.modal-content .modal-header {
  border-bottom: none;
  height: 36px;
  padding: 5px 5px 5px 10px;
  background: #333;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modal-content .modal-header .close {
  margin: 0;
  padding: 5px;
  float: right;
  margin-right: 1px;
  line-height: 16px;
  opacity: 1;
}
.modal-content .modal-header .close > span {
  background: url("../../../img/dockmodal/sprite.png") no-repeat top left;
  vertical-align: top;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.modal-content .modal-header .close:hover {
  background: #555;
}
.modal-content .modal-header .modal-title {
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  vertical-align: top;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-content .modal-body {
  background: #FFF;
  overflow: auto;
  padding: 10px 15px;
}
.modal-content .modal-footer {
  border-top: none;
  background-color: #F5F5F5;
  padding: 5px 10px;
  height: 38px;
}
.modal-content .modal-footer .btn {
  padding: 0 10px;
  font-size: 11px;
  line-height: 26px;
  border-radius: 2px;
}
.x-panel {
  -webkit-box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25);
  background-color: #FFF;
  border: 1px solid #e5e5e5;
}
.x-panel-b {
  border: 1px solid #e5e5e5;
}
.panel .panel-heading .panel-tabs {
  margin: 0;
}
@keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vex-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-webkit-keyframes vex-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-moz-keyframes vex-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes vex-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-o-keyframes vex-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
.vex,
.vex *,
.vex *:before,
.vex *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.vex {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1111;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vex-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.vex-overlay {
  background: #000;
  filter: alpha(opacity=40);
  /* IE 5–7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  /* IE 8 */
}
.vex-overlay {
  animation: vex-fadein 0.5s;
  -webkit-animation: vex-fadein 0.5s;
  -moz-animation: vex-fadein 0.5s;
  -ms-animation: vex-fadein 0.5s;
  -o-animation: vex-fadein 0.5s;
  -webkit-backface-visibility: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vex.vex-closing .vex-overlay {
  animation: vex-fadeout 0.5s;
  -webkit-animation: vex-fadeout 0.5s;
  -moz-animation: vex-fadeout 0.5s;
  -ms-animation: vex-fadeout 0.5s;
  -o-animation: vex-fadeout 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex-content {
  animation: vex-fadein 0.5s;
  -webkit-animation: vex-fadein 0.5s;
  -moz-animation: vex-fadein 0.5s;
  -ms-animation: vex-fadein 0.5s;
  -o-animation: vex-fadein 0.5s;
  -webkit-backface-visibility: hidden;
  background: #fff;
}
.vex.vex-closing .vex-content {
  animation: vex-fadeout 0.5s;
  -webkit-animation: vex-fadeout 0.5s;
  -moz-animation: vex-fadeout 0.5s;
  -ms-animation: vex-fadeout 0.5s;
  -o-animation: vex-fadeout 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex-close:before {
  font-family: Arial, sans-serif;
  content: "\00D7";
}
.vex-dialog-form {
  margin: 0;
}
.vex-dialog-button {
  text-rendering: optimizeLegibility;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.vex-loading-spinner {
  animation: vex-rotation 0.7s linear infinite;
  -webkit-animation: vex-rotation 0.7s linear infinite;
  -moz-animation: vex-rotation 0.7s linear infinite;
  -ms-animation: vex-rotation 0.7s linear infinite;
  -o-animation: vex-rotation 0.7s linear infinite;
  -webkit-backface-visibility: hidden;
  -moz-box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1112;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2em;
  width: 2em;
  background: #fff;
}
body.vex-open {
  overflow: hidden;
}
@keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@-webkit-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@-moz-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@-ms-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@-o-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}
@-webkit-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}
@-moz-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}
@-ms-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}
@-o-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}
@keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}
@-webkit-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}
@-moz-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}
@-ms-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}
@-o-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}
.vex {
  padding-top: 160px;
  padding-bottom: 160px;
}
.vex.vex-closing .vex-content {
  animation: vex-flyout 0.5s;
  -webkit-animation: vex-flyout 0.5s;
  -moz-animation: vex-flyout 0.5s;
  -ms-animation: vex-flyout 0.5s;
  -o-animation: vex-flyout 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex .vex-content {
  animation: vex-flyin 0.5s;
  -webkit-animation: vex-flyin 0.5s;
  -moz-animation: vex-flyin 0.5s;
  -ms-animation: vex-flyin 0.5s;
  -o-animation: vex-flyin 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex .vex-content {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  font-family: "Helvetica Neue", sans-serif;
  border-top: 20px solid #bbb;
  background: #f0f0f0;
  color: #444;
  padding: 1em;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 450px;
  font-size: 1.1em;
  line-height: 1.5em;
}
.vex .vex-content h1,
.vex .vex-content h2,
.vex .vex-content h3,
.vex .vex-content h4,
.vex .vex-content h5,
.vex .vex-content h6,
.vex .vex-content p,
.vex .vex-content ul,
.vex .vex-content li {
  color: inherit;
}
.vex .vex-close {
  -moz-border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.vex .vex-close:before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  content: "\00D7";
  font-size: 26px;
  font-weight: normal;
  line-height: 31px;
  height: 30px;
  width: 30px;
  text-align: center;
  top: 3px;
  right: 3px;
  color: #bbb;
  background: transparent;
}
.vex .vex-close:hover:before,
.vex .vex-close:active:before {
  color: #777;
  background: #e0e0e0;
}
.vex .vex-dialog-form .vex-dialog-message {
  margin-bottom: 0.5em;
}
.vex .vex-dialog-form .vex-dialog-input {
  margin-bottom: 1em;
}
.vex .vex-dialog-form .vex-dialog-input textarea,
.vex .vex-dialog-form .vex-dialog-input input[type="date"],
.vex .vex-dialog-form .vex-dialog-input input[type="datetime"],
.vex .vex-dialog-form .vex-dialog-input input[type="datetime-local"],
.vex .vex-dialog-form .vex-dialog-input input[type="email"],
.vex .vex-dialog-form .vex-dialog-input input[type="month"],
.vex .vex-dialog-form .vex-dialog-input input[type="number"],
.vex .vex-dialog-form .vex-dialog-input input[type="password"],
.vex .vex-dialog-form .vex-dialog-input input[type="search"],
.vex .vex-dialog-form .vex-dialog-input input[type="tel"],
.vex .vex-dialog-form .vex-dialog-input input[type="text"],
.vex .vex-dialog-form .vex-dialog-input input[type="time"],
.vex .vex-dialog-form .vex-dialog-input input[type="url"],
.vex .vex-dialog-form .vex-dialog-input input[type="week"] {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  width: 100%;
  padding: 0.25em 0.67em;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  min-height: 2.5em;
  margin: 0 0 0.25em;
}
.vex .vex-dialog-form .vex-dialog-input textarea:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="date"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="email"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="month"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="number"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="password"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="search"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="tel"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="text"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="time"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="url"]:focus,
.vex .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -moz-box-shadow: inset 0 0 0 1px #3288e6;
  -webkit-box-shadow: inset 0 0 0 1px #3288e6;
  box-shadow: inset 0 0 0 1px #3288e6;
  outline: none;
}
.vex .vex-dialog-form .vex-dialog-buttons {
  *zoom: 1;
}
.vex .vex-dialog-form .vex-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}
.vex .vex-dialog-button {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 0;
  float: right;
  margin: 0 0 0 0.5em;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.75em 2em;
}
.vex .vex-dialog-button.vex-last {
  margin-left: 0;
}
.vex .vex-dialog-button:focus {
  animation: vex-pulse 1.1s infinite;
  -webkit-animation: vex-pulse 1.1s infinite;
  -moz-animation: vex-pulse 1.1s infinite;
  -ms-animation: vex-pulse 1.1s infinite;
  -o-animation: vex-pulse 1.1s infinite;
  -webkit-backface-visibility: hidden;
  outline: none;
}
@media (max-width: 568px) {
  .vex .vex-dialog-button:focus {
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    -o-animation: none;
    -webkit-backface-visibility: hidden;
  }
}
.vex .vex-dialog-button.vex-dialog-button-primary {
  background: #3288e6;
  color: #fff;
}
.vex .vex-dialog-button.vex-dialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}
.vex-loading-spinner {
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  width: 0;
  height: 0;
  border: 1.2em solid #bbb;
  border-top-color: #f0f0f0;
  border-bottom-color: #f0f0f0;
}
.ui-datepicker {
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  color: #666666;
  display: none;
  font-size: 13px;
  margin-top: 3px;
  position: relative;
  text-align: center;
  width: 18em;
  z-index: 10;
}
.ui-datepicker a {
  color: #404040;
  text-align: center;
}
.ui-datepicker .ui-state-disabled span {
  color: #dbdbdb;
}
.ui-datepicker .ui-state-disabled span:hover {
  background: 0;
}
.ui-datepicker .ui-datepicker-header {
  background: #1B1E25;
  border-bottom: 1px solid #cfcfcf;
  font-size: 13px;
  line-height: 27px;
  padding: 5px;
  position: relative;
}
.ui-datepicker .ui-datepicker-prev {
  color: #D3D3D3;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 27px;
  left: 2px;
  position: absolute;
  text-decoration: none;
  top: 5px;
  width: 27px;
}
.ui-datepicker .ui-datepicker-next {
  color: #D3D3D3;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 27px;
  position: absolute;
  right: 2px;
  text-decoration: none;
  top: 5px;
  width: 27px;
}
.ui-datepicker .ui-datepicker-title {
  color: #E9E9E9;
  font-family: 'Montserrat';
  font-weight: normal;
  margin: 0 2.3em;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month {
  width: 49%;
}
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  border-collapse: collapse;
  font-size: .9em;
  margin: 0 0 .4em;
  width: 100%;
}
.ui-datepicker th {
  border: 0;
  font-weight: bold;
  padding: .5em .3em;
  text-align: center;
}
.ui-datepicker td {
  border: 0;
  padding: 2px 5px;
}
.ui-datepicker td span {
  display: block;
  padding: .25em;
  text-align: center;
  text-decoration: none;
}
.ui-datepicker td span:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.ui-datepicker td a {
  display: block;
  padding: .25em;
  text-align: center;
  text-decoration: none;
}
.ui-datepicker td a:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.ui-datepicker .ui-state-highlight {
  color: #fff;
  font-weight: 700;
}
.ui-datepicker-today a {
  background: #B3C5C7 !important;
  color: #616060;
  font-weight: 700;
}
.ui-datepicker-today a:hover {
  background: #00a08e !important;
  color: #fff;
  font-weight: 700;
}
.ui-datepicker-current-day .ui-state-active {
  background: #00a08e !important;
  color: #fff;
  font-weight: 700;
}
.cal-widget .ui-datepicker {
  margin-top: 0;
  width: 100%;
}
.cal-widget .ui-datepicker:before {
  display: none;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0 auto .4em;
  width: 95%;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-monthpicker .ui-datepicker-header {
  margin-bottom: 3px;
}
.ui-timepicker-div dl .ui_tpicker_hour,
.ui-timepicker-div dl .ui_tpicker_minute,
.ui-timepicker-div dl .ui_tpicker_second,
.ui-timepicker-div dl .ui_tpicker_millisec {
  -moz-border-radius: 4px;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 6px;
  webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.ui-datepicker-buttonpane {
  background: #ECECEC;
  border-top: 1px solid #ECECEC;
  padding: 10px;
}
.ui-datepicker-buttonpane button {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  background-color: #2B2E33;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin-right: 10px;
  padding: 7px 24px 8px 24px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.ui-datepicker-buttonpane button:hover {
  background-color: #1F2225;
  border-color: #1F2225;
  color: #fff;
}
.ui-datepicker-buttonpane button.ui-datepicker-current {
  background-color: #687386;
}
.ui-datepicker-buttonpane button.ui-datepicker-current:hover {
  background-color: #5d6778;
}
.ui-datepicker-inline {
  width: 100%;
}
.datepicker table tr td span {
  text-align: center;
}
/*------------------------------------------------------------------
[15. Form Elements]
*/
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea.form-control {
  padding: 4px 0 4px 0;
}
select[multiple],
select[size] {
  height: auto !important;
}
input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form legend {
  margin: 15px 0px 10px 0px;
}
.form-control {
  background-image: none;
  border-color: rgba(12, 12, 12, 0.12);
  -webkit-appearance: none;
  outline: 0;
  line-height: normal;
  font-size: inherit;
  font-weight: normal;
  vertical-align: middle;
  min-height: 34px;
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}
.form-control:focus {
  border-color: rgba(0, 0, 0, 0.1);
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.26);
}
/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 12px;
  line-height: 1.5;
  min-height: 30px;
  height: 30px;
  padding: 5px 9px;
}
.input-lg,
.form-horizontal .form-group-lg .form-control {
  height: 45px;
  padding: 0 0;
  font-size: 1.6em;
  line-height: 1.3333333;
  border-radius: 2px;
}
.input-xlg {
  height: 51px;
  font-size: 18px;
  line-height: 22px;
}
/* Checkboxes and Radio buttons
--------------------------------------------------
*/
.radio,
.checkbox {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0px;
}
.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
  font-size: 13px;
}
.radio label:before,
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  position: absolute;
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
}
.radio label {
  margin-bottom: 6px;
}
.radio label:before {
  bottom: 2.5px;
  border-radius: 99px;
  -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
}
.radio input[type=radio]:checked + label:before {
  border-width: 5px;
}
.radio input[type=radio] {
  display: none;
}
.radio input[type=radio][disabled] + label {
  opacity: 0.65;
}
.radio.radio-success input[type=radio]:checked + label:before {
  border-color: #1dbb99;
}
.radio.radio-primary input[type=radio]:checked + label:before {
  border-color: #3b3f4f;
}
.radio.radio-info input[type=radio]:checked + label:before {
  border-color: #3bafda;
}
.radio.radio-warning input[type=radio]:checked + label:before {
  border-color: #f7cf5f;
}
.radio.radio-danger input[type=radio]:checked + label:before {
  border-color: #f35958;
}
.radio.radio-complete input[type=radio]:checked + label:before {
  border-color: #4a89dc;
}
.checkbox input[type=radio][disabled] + label:after {
  background-color: #e1e1e1;
}
.checkbox label {
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap;
}
.checkbox label:before {
  top: 1.4px;
  border-radius: 3px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.checkbox label::after {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.2px;
  top: 0px;
  font-size: 11px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.checkbox label:after {
  border-radius: 3px;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox.checkbox-circle label:after {
  border-radius: 99px;
}
.checkbox.checkbox-circle label:before {
  border-radius: 99px;
}
.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8px;
}
.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff;
}
.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox][disabled] + label:before {
  background-color: #eceff3;
}
.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important;
}
.checkbox.right label:before {
  right: -35px;
  left: auto;
}
.checkbox.right input[type=checkbox]:checked + label {
  position: relative;
}
.checkbox.right input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  position: absolute;
  right: -27px;
  left: auto;
}
.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #1dbb99;
}
.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #3b3f4f;
}
.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #4a89dc;
}
.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #f7cf5f;
}
.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f35958;
}
.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #3bafda;
}
.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #ffffff;
}
.input-group.transparent .input-group-addon {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.07);
}
/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 768px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*='col-']:not(:first-child),
  form .row [class*='col-']:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
  }
  form .row [class*='col-']:first-child {
    padding-left: 0;
  }
  form .row [class*='col-']:last-child {
    padding-right: 0;
  }
}
/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
  border-bottom: 1px solid #e1e1e1;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
}
.form-horizontal .form-group:last-child {
  border-bottom: none;
}
.form-horizontal .form-group:hover .control-label {
  opacity: .6;
}
.form-horizontal .form-group.focused .control-label {
  opacity: 1;
}
.form-horizontal .form-group .control-label {
  text-align: right;
  opacity: .8;
  -webkit-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
}
/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
  border-radius: 0;
  margin-bottom: 0;
}
.form-group-attached > div {
  margin: 0;
}
.form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
  border-top-left-radius: 2px;
}
.form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
  border-top-right-radius: 2px;
}
.form-group-attached > div:first-child.form-group-default {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
  border-bottom-left-radius: 2px;
}
.form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
  border-bottom-right-radius: 2px;
}
.form-group-attached > div:last-child.form-group-default {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.form-group-attached > div.row > [class*='col-'] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.form-group-attached > div.row > [class*='col-'] > .form-group-default {
  display: table;
  width: 100%;
}
.form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
  border-right-color: transparent;
}
.form-group-attached > div:not(:last-child) .form-group-default,
.form-group-attached > div:not(:last-child).form-group-default {
  border-bottom-color: transparent;
}
/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: rgba(0, 0, 0, 0.07) !important;
  }
}
@media only screen and (min-width: 768px) {
  .form-group-attached > div.row {
    display: table;
    width: 100%;
  }
  .form-group-attached > div.row > [class*='col-'] {
    display: table-cell;
    height: 100%;
    float: none;
    vertical-align: top;
  }
  .form-group-attached > div.row > [class*='col-'] .form-group {
    height: 100%;
    width: 100%;
  }
}
/* Form Groups
--------------------------------------------------
*/
.floating-label .twitter-typeahead ~ label,
.form-group.floating-label .twitter-typeahead ~ label {
  top: 0px;
  font-size: 12px !important;
}
.floating-label .form-control ~ label,
.form-group.floating-label .form-control ~ label {
  top: 19px;
  font-size: 16px;
}
.floating-label .form-control:focus ~ label,
.form-group.floating-label .form-control:focus ~ label,
.floating-label .form-control.static ~ label,
.form-group.floating-label .form-control.static ~ label,
.floating-label .form-control.dirty ~ label,
.form-group.floating-label .form-control.dirty ~ label {
  top: 0px;
  font-size: 12px !important;
  line-height: 1em;
}
.floating-label.append-icon .form-control ~ i,
.form-group.floating-label.append-icon .form-control ~ i {
  display: none;
}
.floating-label.append-icon .form-control:focus ~ i,
.form-group.floating-label.append-icon .form-control:focus ~ i,
.floating-label.append-icon .form-control.dirty ~ i,
.form-group.floating-label.append-icon .form-control.dirty ~ i {
  display: block;
}
.form-group {
  position: relative;
  margin-bottom: 19px;
  padding-top: 16px;
}
.form-group label:not(.error):not(.md-check) {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.85em;
  position: absolute;
  z-index: 10;
  opacity: 0.5;
  display: inline-block;
  top: 0px;
  left: 0;
  margin-bottom: 0;
  bottom: 0;
  width: 100%;
  color: inherit;
}
.form-group label .help {
  margin-left: 8px;
}
.form-group .help {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.form-group.label-lg {
  padding-top: 24px;
}
.form-group.label-lg .form-control,
.form-group.label-lg .twitter-typeahead {
  border-radius: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.form-group.label-lg .form-control ~ label,
.form-group.label-lg .twitter-typeahead ~ label {
  font-size: 18px;
  line-height: 24px;
}
.form-group .input-group {
  margin-top: -16px;
}
.form-group .input-group .input-group-content {
  padding-top: 16px;
  position: relative;
  display: table-cell;
  vertical-align: bottom;
}
.form-group .input-group .input-group-addon {
  vertical-align: bottom;
  padding-top: 16px;
  padding-left: 10px;
  border-color: transparent;
  background-color: transparent;
  color: inherit;
  opacity: 0.7;
}
.form-group .input-group .input-group-addon:first-child {
  min-width: 42px;
  text-align: left;
  padding-left: 0;
  padding-right: 10px;
}
.form-group .form-control ~ label,
.form-group .twitter-typeahead ~ label {
  font-size: 0.85em;
  position: absolute;
  z-index: 10;
  opacity: 0.5;
  display: inline-block;
  top: 0px;
  left: 0;
  margin-bottom: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  color: inherit;
}
.form-group .form-control ~ label:after,
.form-group .twitter-typeahead ~ label:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  height: 2px;
  width: 10px;
  visibility: hidden;
  background-color: #2196f3;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.form-group .form-control:not(.static) ~ label,
.form-group .twitter-typeahead:not(.static) ~ label {
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.form-group .form-control:focus ~ label,
.form-group .twitter-typeahead:focus ~ label {
  color: #2196f3;
  opacity: 1;
}
.form-group .form-control:focus ~ label:after,
.form-group .twitter-typeahead:focus ~ label:after {
  visibility: visible;
  width: 100%;
  left: 0;
}
.form-group.append-icon .form-control {
  padding-left: 35px;
}
.form-group.append-icon i {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  width: 25px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  pointer-events: none;
  top: auto;
  bottom: 0;
}
.form-group-default {
  background-color: #fff;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.form-group-default.required:after {
  color: #f35958;
  content: "*";
  font-family: arial;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 9px;
}
.form-group-default.disabled {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.26);
}
.form-group-default.disabled input {
  opacity: .6;
}
.form-group-default.disabled.focused {
  background: #f3f3f3;
}
.form-group-default.disabled.focused label {
  opacity: 1;
}
.form-group-default.focused {
  border-color: rgba(0, 0, 0, 0.1) !important;
  background-color: #e7e7e7;
}
.form-group-default.focused label {
  opacity: .4;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-group-default.has-error {
  background-color: rgba(243, 89, 88, 0.1);
}
.form-group-default.has-success .form-control-feedback,
.form-group-default.has-error .form-control-feedback {
  display: none !important;
}
.form-group-default.has-success .form-control,
.form-group-default.has-success .form-control:focus,
.form-group-default.has-error .form-control,
.form-group-default.has-error .form-control:focus {
  border: none;
  box-shadow: none;
}
.form-group-default.input-group {
  padding: 0;
}
.form-group-default.input-group > label {
  margin-top: 6px;
  padding-left: 12px;
}
.form-group-default.input-group > .form-control {
  margin-top: -2px;
  margin-bottom: 3px;
  padding-left: 12px;
}
.form-group-default.input-group .input-group-addon {
  height: calc(50px);
  min-width: calc(50px);
  border-radius: 0;
  border: none;
}
.form-group-default.input-group.focused .input-group-addon {
  border-color: rgba(0, 0, 0, 0.1);
}
.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}
.form-group-default .form-control.error {
  color: #2c2c2c;
}
.form-group-default .form-control:focus {
  background: none;
}
.form-group-default label {
  margin: 0;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.form-group-default label.label-lg {
  font-size: 13px;
  left: 13px;
  top: 9px;
}
.form-group-default label.label-sm {
  font-size: 11px;
  left: 11px;
  top: 6px;
}
.form-group-default label.highlight {
  opacity: 1;
}
.form-group-default label.fade {
  opacity: .5;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-group-default > .input-lg {
  height: 29px;
  min-height: 29px;
  padding-left: 1px;
}
.form-group-default > .input-sm {
  min-height: 18px;
  height: 18px;
}
.form-group-default.form-group-default-select2 {
  padding: 0;
}
.form-group-default.form-group-default-select2 > label {
  position: absolute;
  z-index: 10;
  padding: 7px 12px 0 12px;
}
.form-group-default.form-group-default-select2 > label.label-lg {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-select2 > label.label-sm {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice {
  padding-top: 20px;
  height: 52px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-arrow b:before {
  top: 20px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-chosen {
  padding-left: 3px;
  padding-top: 1px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choices {
  padding-top: 20px;
  height: 52px;
  border: 0px;
}
.form-group-default.form-group-default-select2 > .input-lg {
  height: auto;
  padding: 0;
}
.form-group-default.form-group-default-select2 > .input-lg .select2-choice {
  padding-top: 20px;
  height: 56px;
}
.form-group-default.form-group-default-select2 > .input-sm {
  height: auto;
  padding: 0;
}
.form-group-default.form-group-default-select2 > .input-sm .select2-choice {
  padding-top: 20px;
  height: 46px;
}
.form-group-default.form-group-default-selectFx {
  padding: 0;
}
.form-group-default.form-group-default-selectFx > label {
  position: absolute;
  z-index: 10;
  padding: 7px 12px 0 12px;
}
.form-group-default.form-group-default-selectFx > label.label-lg {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-selectFx > label.label-sm {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
  padding-top: 28px;
  height: 52px;
  padding-left: 12px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
  height: auto;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
  top: 39px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
  height: 60px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
  height: 50px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
  vertical-align: top;
}
/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #17977c;
}
.has-success .form-control {
  border-color: #17977c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #116b58;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  background: #e7e7e7;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(87, 87, 87, 0.47);
}
.has-success .form-control-feedback {
  color: #17977c;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #c8a84d;
}
.has-warning .form-control {
  border-color: #c8a84d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #ad8d35;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  background: #e7e7e7;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(87, 87, 87, 0.47);
}
.has-warning .form-control-feedback {
  color: #c8a84d;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f35958;
}
.has-error .form-control {
  border-color: #f35958;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #f02a28;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  background: #e7e7e7;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(87, 87, 87, 0.47);
}
.has-error .form-control-feedback {
  color: #f35958;
}
.error {
  font-size: 12px;
  color: #f35958;
  display: block;
}
/* Addon inputs
--------------------------------------------------
*/
.input-group-addon {
  background: transparent;
  border: 1px solid rgba(12, 12, 12, 0.12);
  color: rgba(87, 87, 87, 0.47);
  font-size: 13px;
  line-height: 1;
  text-align: center;
  padding: 4.5px 14px;
  display: table-cell;
  border-radius: 2px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s, background 0.2s linear 0s;
}
.input-group-addon.primary {
  background-color: #3b3f4f;
  border: 1px solid #3b3f4f;
  color: #ffffff;
}
.input-group-addon.primary .arrow {
  color: #3b3f4f;
}
.input-group-addon.success {
  background-color: #0090d9;
  color: #ffffff;
}
.input-group-addon.success .arrow {
  color: #0090d9;
}
.input-group-addon.info {
  background-color: #1f3853;
  color: #ffffff;
}
.input-group-addon.info .arrow {
  color: #1f3853;
}
.input-group-addon.warning {
  background-color: #fbb05e;
  color: #ffffff;
}
.input-group-addon.warning .arrow {
  color: #fbb05e;
}
.input-group-addon.danger {
  background-color: #f35958;
  color: #ffffff;
}
.input-group-addon.danger .arrow {
  color: #f35958;
}
.input-group-addon .arrow {
  position: relative;
  right: -6px;
  color: #D1DADE;
  z-index: 100;
}
.input-group-addon .arrow:before {
  font-family: 'FontAwesome';
  content: "\f0da";
  font-size: 23px;
  position: absolute;
  left: 17px;
  top: -2px;
}
.input-group-addon:last-child .arrow:before {
  font-family: 'FontAwesome';
  content: "\f0d9";
  font-size: 23px;
  position: absolute;
  left: -23px;
  top: -2px;
}
.input-group-addon:last-child input {
  border-left: 0px;
}
/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 16px 25px;
  border-radius: 2px;
  font-size: 12px;
}
.datepicker:after {
  border-bottom-color: #f0f0f0;
}
.datepicker thead tr .datepicker-switch {
  color: #6f7b8a;
  font-size: 13px;
}
.datepicker thead tr .next,
.datepicker thead tr .prev {
  color: #3b3f4f;
  content: '';
  font-size: 0px;
}
.datepicker thead tr .next:before,
.datepicker thead tr .prev:before {
  color: #3b3f4f;
  font-family: 'FontAwesome';
  font-size: 10px;
}
.datepicker thead tr .prev:before {
  content: "\f053";
}
.datepicker thead tr .next:before {
  content: "\f054";
}
.datepicker thead tr .dow {
  font-family: 'Montserrat';
  color: #3b3f4f;
  text-transform: uppercase;
  font-size: 11px;
}
.datepicker thead tr th {
  width: 31px;
  height: 29px;
}
.datepicker tbody tr .odd {
  color: #d0d3d8;
}
.datepicker table tr td {
  width: 31px;
  height: 29px;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #e1e1e1;
}
.datepicker table tr td.day:hover {
  background: #e7e7e7;
}
.datepicker table tr td.active {
  background-color: #3b3f4f !important;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: none;
  text-shadow: none;
  font-weight: 600;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #e7e7e7;
  background-image: none;
  color: #ffffff;
}
.datepicker table tr td span {
  border-radius: 4px;
  width: 42px;
  height: 42px;
  line-height: 42px;
}
.datepicker table tr td span.active {
  background-color: #3b3f4f !important;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none;
  border: none;
  text-shadow: none;
}
.datepicker.dropdown-menu {
  border-color: #e1e1e1;
  color: #575757;
}
.datepicker.datepicker-dropdown.datepicker-orient-bottom:before {
  border-color: #e1e1e1;
}
.datepicker-inline {
  width: auto;
}
.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0;
}
/* Timepicker
https://github.com/m3wolf/bootstrap3-timepicker
*/
.bootstrap-timepicker-widget table td a i {
  font-size: 12px;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget .bootstrap-timepicker-widget input {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  background: #fff;
}
/* Daterangepicker
http://www.dangrossman.info/
*/
.daterangepicker {
  background: #fff;
}
.daterangepicker .calendar .calendar-date {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.daterangepicker .calendar .prev,
.daterangepicker .calendar .next,
.daterangepicker .calendar th {
  color: #3b3f4f;
  text-transform: uppercase;
  font-size: 11px;
}
.daterangepicker .calendar .month {
  color: #6f7b8a;
  font-size: 13px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3b3f4f;
  border-color: #3b3f4f;
}
/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-choice,
.select2-container-multi .select2-choices {
  border-color: transparent;
}
.select2-container .select2-choice {
  background-image: none;
  background-color: transparent !important;
  border-radius: 0px;
  border: 0px;
  padding: 0px;
  transition: border 0.2s linear 0s;
  height: 37px;
}
.select2-container .select2-choice .select2-arrow {
  background: transparent;
  border-left: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.select2-container .select2-choice .select2-arrow b:before {
  font-family: 'FontAwesome';
  content: "\f0d7";
  position: relative;
  top: 2px;
  right: 5px;
  font-size: 12px;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: transparent;
}
.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1));
}
.select2-drop.select2-drop-above {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #e7e7e7;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.select2-search {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}
.select2-search input {
  background: #ffffff !important;
  vertical-align: baseline;
  line-height: 28px;
  border-radius: 2px;
  border: none;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.select2-results {
  margin: 3px 10px 10px 3px;
}
.select2-results li {
  color: #575757;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  color: #2c2c2c;
}
.select2-results li.select2-result-with-children > .select2-result-label:first-child {
  padding-top: 8px;
}
.select2-results .select2-highlighted {
  background: #e7e7e7;
  border-radius: 3px;
}
.select2-results .select2-highlighted .select2-result-label {
  color: #202020;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: none;
  color: #202020;
  font-size: 12px;
  padding-left: 8px;
  padding-top: 0px;
  position: relative;
  top: -5px;
}
.select2-drop-active {
  border: 0px;
  border-bottom: none;
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 990;
}
.select2-container-multi .select2-choices {
  background-image: none;
  border: 0px;
  border-radius: 2px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 8px;
  margin: 8px -5px 7px 10px;
  padding: 4px 8px 4px 21px;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: none;
}
.select2-container-multi.select2-container-active .select2-search-choice {
  background-color: #ffffff !important;
}
.select2-container-multi .select2-search-choice-close {
  left: 6px;
}
.select2-search-choice-close {
  background: none;
  top: 4px;
  right: 0;
}
.select2-search-choice-close:hover {
  text-decoration: none;
}
.select2-search-choice-close:before {
  font-family: 'FontAwesome';
  content: "\f00d";
  font-size: 12px;
  color: #575757;
}
.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px;
}
.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0;
}
.select2-drop-mask {
  z-index: 700;
}
.modal-open .select2-drop-active {
  z-index: 1051;
}
.modal-open .select2-drop-mask {
  z-index: 1050;
}
.modal-open .cs-skin-slide.cs-active {
  z-index: 1050;
}
.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle;
}
.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600;
}
/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/
.form-group-default .bootstrap-tagsinput {
  border: 0px;
  padding-left: 0;
}
.bootstrap-tagsinput {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding-bottom: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}
.bootstrap-tagsinput.active-element {
  background-color: #e1e1e1;
}
.bootstrap-tagsinput input {
  border: none;
  margin-bottom: 0px;
  min-height: 25px;
}
.bootstrap-tagsinput .tag {
  vertical-align: middle;
  padding: 6px 9px;
  padding-right: 6px;
  border-radius: 3px;
  line-height: 30px;
}
.bootstrap-tagsinput .tag[data-role="remove"] {
  margin-left: 4px;
}
.bootstrap-tagsinput .tag[data-role="remove"]:hover:active,
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: none;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  font-family: 'pages-icon';
  content: "\e60a";
  padding: 0;
}
/* Bootstrap3 wysihtml5
https://github.com/Waxolunist/bootstrap3-wysihtml5-bower
*/
.wysiwyg5-wrapper {
  position: relative;
}
.wysiwyg5-wrapper .wysihtml5-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 50px;*/
  background: #e7e7e7;
  border-top: 1px solid #e1e1e1;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border-color: transparent;
  border-right-color: #e1e1e1;
  color: #A5A5A5;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 5px;
  border-width: 1px;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn:hover .editor-icon {
  opacity: .8;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.active .editor-icon {
  opacity: 1;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle {
  padding-left: 10px;
  padding-right: 20px;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle .current-font {
  opacity: .5;
  font-size: 14px;
}
.wysiwyg5-wrapper .wysihtml5-toolbar > li {
  margin: 0;
  padding: 0;
}
.wysiwyg5-wrapper .wysiwyg {
  width: 100%;
  min-height: 200px;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 50px !important;
  border: 0;
}
.wysiwyg5-wrapper .wysiwyg:focus {
  background-color: #f0f0f0;
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wysiwyg5-wrapper .expand-wysiwyg {
  bottom: 0;
  color: #A5A5A5;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  position: absolute;
  right: 0;
}
.wysiwyg5-wrapper .wysihtml5-sandbox {
  border: none !important;
  padding: 16px 16px 50px !important;
  width: 100% !important;
}
.wysiwyg5-wrapper .wysihtml5-sandbox.expanded {
  height: 100% !important;
}
.toggle-wysiwyg {
  position: absolute;
  right: 15px;
  top: 0;
}
.toggle-wysiwyg li {
  display: inline-block;
  font-weight: 600;
}
.editor-icon {
  background-image: url("../img/editor_tray.png");
  display: inline-block;
  height: 40px;
  margin-top: 5px;
  opacity: 0.4;
  vertical-align: top;
  width: 40px;
}
.editor-icon-headline {
  background-position: 0 0;
  width: 31px;
}
.editor-icon-bold {
  background-position: -40px 0;
}
.editor-icon-italic {
  background-position: -80px 0;
}
.editor-icon-underline {
  background-position: -120px 0;
}
.editor-icon-link {
  background-position: -160px 0;
}
.editor-icon-quote {
  background-position: -200px 0;
}
.editor-icon-ul {
  background-position: -240px 0;
}
.editor-icon-ol {
  background-position: -280px 0;
}
.editor-icon-outdent {
  background-position: -320px 0;
}
.editor-icon-indent {
  background-position: -360px 0;
}
.editor-icon-image {
  background-position: -400px 0;
}
.editor-icon-html {
  background-position: -440px 0;
}
/* Summernote
https://github.com/HackerWins/summernote
*/
.summernote-wrapper .note-editor {
  border-color: #e1e1e1;
}
.summernote-wrapper .note-editor .note-toolbar {
  padding: 0;
  background-color: #e7e7e7;
  border-bottom: none;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group {
  margin: 0 -1px 0 0;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn {
  font-size: 12px;
  font-weight: 600;
  height: 50px;
  min-width: 47px;
  line-height: 50px;
  padding: 0 5px;
  border-radius: 0;
  background-color: #e7e7e7;
  border-color: transparent;
  border-right-color: #e1e1e1;
  border-bottom-color: #e1e1e1;
  color: #575757;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn.active,
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:active {
  background-color: #e1e1e1;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn.dropdown-toggle {
  min-width: 61px;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:last-child),
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:only-child) {
  margin-right: 1px;
}
.summernote-wrapper .note-editor .note-statusbar {
  background-color: transparent;
}
.summernote-wrapper .note-editor .note-statusbar .note-resizebar {
  border-top-color: transparent;
}
.summernote-wrapper .note-editor .note-statusbar .note-resizebar .note-icon-bar {
  border-top: 1px solid #e1e1e1;
}
.summernote-wrapper .note-popover .popover .popover-content .dropdown-menu li a i,
.summernote-wrapper .note-toolbar .dropdown-menu li a i {
  color: #3b3f4f;
}
input,
input:focus {
  -webkit-transition: none !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #e7e7e7 inset !important;
}
input.error:-webkit-autofill,
input.error:-webkit-autofill:focus,
.has-error input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F9E9E9 inset !important;
}
/* Pages SelectFx */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 700;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.cs-select select {
  display: none;
}
.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em;
}
.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-select > span::after {
  content: '\25BE';
  right: 1em;
}
.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em;
}
.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}
.cs-select.cs-active .cs-options {
  visibility: visible;
}
.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cs-select ul span {
  padding: 1em;
}
.cs-select ul li.cs-focus span {
  background-color: #ddd;
}
/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}
.cs-select li.cs-optgroup > span {
  cursor: default;
}
div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px;
}
@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px;
  }
}
div.cs-skin-slide::before {
  content: '';
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1);
}
.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}
@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px;
  }
}
.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}
.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em;
  }
}
.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  padding: 0.8em 1em 0.8em 2.5em;
}
.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent;
}
.cs-skin-slide .cs-selected span::after {
  content: '';
}
/* Pages Select  overriding */
.form-group-default .cs-skin-slide > span {
  padding: 0 30px 0 0;
  height: 22px;
  line-height: 21px;
}
.form-group-default .cs-wrapper {
  width: 100%;
}
.cs-wrapper {
  display: inline-block;
}
.form-control.cs-select:not(.cs-active) {
  width: 100% !important;
}
.cs-select {
  background-color: transparent;
}
.cs-select span {
  text-overflow: initial;
}
.cs-select .cs-placeholder {
  width: 100%;
}
div.cs-skin-slide {
  width: auto;
  font-family: Arial, sans-serif;
  color: #5e5e5e;
}
div.cs-skin-slide:before {
  background-color: transparent;
}
div.cs-skin-slide.cs-transparent {
  background: none;
}
div.cs-skin-slide.cs-transparent .cs-backdrop {
  border-color: transparent;
  background: none;
}
div.cs-skin-slide.cs-transparent.cs-active .cs-backdrop {
  background: #f0f0f0;
}
div.cs-skin-slide > span {
  height: 35px;
  padding: 6px 33px 6px 17px;
  line-height: 23px;
}
div.cs-skin-slide.cs-active {
  z-index: 890;
}
div.cs-skin-slide.cs-active:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
div.cs-skin-slide.cs-active .cs-backdrop {
  border: transparent;
  background: #f0f0f0;
  box-shadow: -1px 0 1px #cccccc, 1px 0 1px #cccccc;
}
div.cs-skin-slide > span:after,
div.cs-skin-slide.cs-active > span:after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #5e5e5e;
}
div.cs-skin-slide .cs-options {
  height: auto;
  padding: 9px 0;
  width: auto;
  padding: 10px;
  max-height: 350px;
  transform: translate3d(1, 1, 1);
  overflow: hidden;
}
div.cs-skin-slide .cs-options ul {
  width: 100%;
  display: table;
}
div.cs-skin-slide .cs-options ul li {
  display: table-row;
}
div.cs-skin-slide .cs-options ul li span {
  display: table-cell;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  padding: 5px 0;
  text-transform: none;
  max-height: 350px;
  overflow-y: auto;
}
div.cs-skin-slide .cs-options ul li span:hover,
div.cs-skin-slide .cs-options ul li.cs-focus span,
div.cs-skin-slide .cs-options ul li.cs-selected span {
  color: #2c2c2c;
}
.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s;
}
/* end overrides */
/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px;
}
.x-form .form-group .control-label {
  padding-top: 5px;
}
.x-form .form-group > div {
  position: relative;
  display: table;
  border-collapse: separate;
}
.x-form .form-group > div > span[data-editor] {
  display: table-cell;
  width: 100%;
  float: left;
}
.x-form .form-group > div > span[data-error] {
  display: none;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0 10px;
  text-align: center;
}
.x-form .form-group > div .help-block {
  display: none;
}
.x-form .form-group.has-error > div .help-block[data-error] {
  display: table-cell;
}
.help-block,
.help-block[data-error] {
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0;
  color: inherit;
  opacity: .7;
  letter-spacing: 0.1px;
  font-size: 12px;
  display: block;
}
form.form-input-bd .title {
  background-color: #eaebec;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.4em;
  padding: 5px;
  margin-bottom: 10px;
}
.sf-nav {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.sf-viewport {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}
.sf-viewport legend {
  font-size: 20px;
  padding: 15px 0;
  font-weight: 700;
  background: none;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}
.sf-viewport .sf-btn.sf-btn-finish {
  margin: 0 0 0 10px;
}
.sf-nav-bottom {
  margin-top: 6px;
}
.sf-nav-wrap {
  overflow: hidden;
  width: 100%;
  position: relative;
  left: 0;
}
.sf-nav-top,
.sf-nav-bottom {
  height: 40px;
}
.sf-nav-top .sf-nav,
.sf-nav-bottom .sf-nav {
  width: 100%;
  position: absolute;
}
.sf-nav-left {
  float: left;
  width: 20%;
  height: auto;
  overflow: hidden;
}
.sf-nav-right {
  float: right;
  width: 20%;
  height: auto;
}
.sf-nav-wrap.sf-nav-left li,
.sf-nav-wrap.sf-nav-right li {
  float: none;
  margin-bottom: 10px;
}
.sf-nav li {
  float: left;
  position: relative;
  margin-right: 20px;
}
.sf-nav li.sf-nav-link {
  cursor: pointer;
}
.sf-nav-right li,
sf-nav-left li {
  padding-right: 10px;
}
.sf-nav-on-left {
  width: 80%;
  margin-left: 20%;
}
.sf-nav-on-right {
  width: 80%;
  margin-right: 20%;
}
.sf-nav-right li {
  margin-right: 0;
  margin-left: 10px;
}
.sf-step > fieldset {
  overflow: auto;
  overflow-x: hidden;
}
.sf-step-no-active {
  height: 1px;
}
.sf-step-no-active > fieldset {
  display: none;
}
li.sf-li-nonumber {
  text-align: center;
}
.sf-li-nonumber .sf-nav-number {
  display: none;
}
.sf-wizard .sf-btn,
form .nocsript-sf-btn {
  padding: 0 35px;
}
.sf-wizard > form {
  box-sizing: border-box;
}
.sf-controls input {
  margin: 0;
}
.rtl .sf-nav li {
  float: right;
  margin-left: 0;
}
.rtl .sf-arrow .sf-nav li.sf-nav-step-1,
.rtl .sf-arrow .sf-nav li.sf-nav-step-2,
.rtl .sf-arrow .sf-nav li.sf-nav-step-3 {
  margin-right: -2px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-top-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
}
.rtl .sf-arrow .sf-nav li.sf-nav-step-0:not(.sf-active):after {
  left: -25px;
  right: auto;
  border-right: 26px solid #E0E0E0;
  border-left: none;
}
.rtl .sf-arrow .sf-nav li.sf-nav-step-1:after {
  left: -24px;
  right: auto;
  border-right: 26px solid #E0E0E0;
  border-left: none;
}
.sf-sea .sf-nav-bottom {
  margin-top: 10px;
  height: 60px;
}
.sf-sea .sf-nav-top {
  height: 83px;
}
.sf-sea .sf-nav-top li,
.sf-sea .sf-nav-bottom li {
  -webkit-transition: margin 200ms;
  -moz-transition: margin 200ms;
  -ms-transition: margin 200ms;
  -o-transition: margin 200ms;
  transition: margin 200ms;
}
.sf-sea .sf-nav li {
  font-size: 16px;
  color: #F5F5F5;
  background: rgba(43, 46, 51, 0.2);
  height: 60px;
  line-height: 60px;
  padding: 0 45px;
  border-radius: 3px;
}
.sf-sea .sf-nav-top .sf-nav li:last-child,
.sf-sea .sf-nav-bottom .sf-nav li:last-child {
  margin-right: 0;
}
.sf-sea .sf-nav li.sf-active,
.sf-sea .sf-nav li.sf-nav-link:hover {
  background: #319db5;
}
.sf-sea .sf-nav-top li.sf-active {
  margin-top: 6px;
}
.sf-sea .sf-nav li.sf-nav-prev-step {
  background: #203140;
}
.sf-sea .sf-nav-number {
  position: absolute;
  left: 12px;
  top: 0;
  font-size: 96px;
  font-weight: 700;
  overflow: hidden;
}
.sf-sea .sf-nav-number-inner {
  display: block;
}
.sf-sea .sf-li-number .sf-nav-subtext {
  padding-left: 35px;
}
.sf-sea .sf-nav-top .sf-nav-step.sf-active:after {
  content: "";
  width: 5px;
  height: 5px;
  background: transparent;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -12px;
  border: 12px solid #000;
  border-color: transparent #319DB5 #319DB5 transparent;
  z-index: -1;
}
.sf-sea .sf-wizard .sf-btn,
.sf-sea form .nocsript-sf-btn {
  height: 45px;
  line-height: 45px;
  padding: 0 25px;
  border-radius: 3px;
  border: none;
  color: #FFF;
  text-decoration: none;
  background-color: #2b2e33;
}
.sf-sea .sf-wizard .sf-btn:hover,
.sf-sea .nocsript-sf-btn:hover {
  background-color: #319db5;
}
.sf-sea .sf-wizard .sf-btn.sf-btn-next {
  background: url(../img/t1-arr-right.png) no-repeat right 15px #2b2e33;
  padding-right: 50px;
}
.sf-sea .sf-wizard .sf-btn.sf-btn-prev {
  background: url(../img/t1-arr-left.png) no-repeat left 15px #3d4750;
  padding-left: 50px;
}
.sf-sea .sf-wizard .sf-btn.sf-btn-next:hover {
  background-position: right -35px;
}
.sf-sea .sf-wizard .sf-btn.sf-btn-prev:hover {
  background-position: left -35px;
}
.sf-sea .sf-wizard > form {
  padding: 20px;
  border-radius: 3px;
}
.sf-sea .sf-step legend {
  padding-top: 5px;
  display: none;
}
.rtl .sf-sea .sf-wizard .sf-btn.sf-btn-next {
  background: url(../img/t1-arr-left.png) no-repeat left 15px #2b2e33;
  padding-left: 50px;
  padding-right: 25px;
}
.rtl .sf-sea .sf-wizard .sf-btn.sf-btn-prev {
  background: url(../img/t1-arr-right.png) no-repeat right 15px #3d4750;
  padding-right: 50px;
  padding-left: 25px;
}
.rtl .sf-sea .sf-nav-number {
  right: 12px;
  left: auto;
}
.rtl .sf-sea .sf-li-number .sf-nav-subtext {
  padding-right: 35px;
  padding-left: 0;
}
.select2-container--default {
  /*______ SINGLE _____*/
  /*_____ MULTIPLE _____*/
  /*_____ END _____*/
}
.select2-container--default .select2-selection--single {
  background-image: none;
  border-radius: 2px;
  border: 0px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.12);
  padding: 0px;
  transition: border 0.2s linear 0s;
  height: 37px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #202020;
  line-height: 37px;
  padding-left: 0px;
  font-size: 16px;
  font-weight: normal;
  vertical-align: middle;
  padding-right: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: normal;
  width: 27px;
  font-size: 16px;
  text-align: center;
  font-size: 1.4rem;
  color: #202020;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba(12, 12, 12, 0.26);
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: 0;
  height: 100%;
  top: 0;
  right: 0;
  text-align: center;
  width: 27px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #202020;
}
.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  font-family: 'Material-Design-Icons';
  content: "\e894";
  top: 0;
  right: 0;
  font-size: 1.8rem;
  line-height: 37px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  margin: 0;
  top: 0;
  right: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--open .select2-dropdown {
  border: 1px solid rgba(12, 12, 12, 0.12);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.select2-container--open .select2-dropdown .select2-results {
  margin: 10px;
}
.select2-container--open .select2-dropdown .select2-results li {
  color: #202020;
  line-height: 37px;
  font-size: 16px;
  padding: 0px 6px;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-group-default .select2-container .select2-choice,
.select2-container-multi .select2-choices {
  border-color: transparent;
}
.select2-container .select2-choice {
  background-image: none;
  background-color: transparent!important;
  border-radius: 0px;
  border: 0px !important;
  padding: 0px;
  transition: border 0.2s linear 0s;
  height: 37px;
}
.select2-container .select2-choice .select2-chosen {
  line-height: 37px;
}
.select2-container .select2-choice .select2-arrow {
  background: transparent;
  border-left: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.select2-container .select2-choice .select2-arrow b:before {
  font-family: 'Material-Design-Icons';
  content: "\e894";
  top: 0;
  right: 10px;
  font-size: 1.8rem;
  line-height: 37px;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: transparent;
}
.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1));
}
.select2-drop {
  background-color: #fff!important;
}
.select2-drop.select2-drop-above {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #e7e7e7;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.select2-search {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}
.select2-search input {
  background: #ffffff !important;
  vertical-align: baseline;
  line-height: 28px;
  border-radius: 2px;
  border: none;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.select2-results {
  margin: 3px 10px 10px 3px;
}
.select2-results li {
  color: #575757;
  font-size: 16px;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  color: #2c2c2c;
}
.select2-results li.select2-result-with-children > .select2-result-label:first-child {
  padding-top: 8px;
}
.select2-results .select2-highlighted {
  background: #e7e7e7;
  border-radius: 3px;
}
.select2-results .select2-highlighted .select2-result-label {
  color: #202020;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: none;
  color: #202020;
  font-size: 12px;
  padding-left: 8px;
  padding-top: 0px;
  position: relative;
  top: -5px;
}
.select2-drop-active {
  border: 0px;
  border-bottom: none;
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 990;
}
.select2-container-multi .select2-choices {
  background-image: none;
  border: 0px;
  border-radius: 2px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 8px;
  margin: 8px -5px 7px 10px;
  padding: 4px 8px 4px 21px;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: none;
}
.select2-container-multi.select2-container-active .select2-search-choice {
  background-color: #ffffff !important;
}
.select2-container-multi .select2-search-choice-close {
  left: 6px;
}
.select2-container .select2-choice abbr.select2-search-choice-close {
  background: none;
  width: 30px;
  height: 27px;
  right: 24px;
  top: 0;
}
.select2-container .select2-choice abbr.select2-search-choice-close:hover {
  text-decoration: none;
}
.select2-container .select2-choice abbr.select2-search-choice-close:before {
  font-family: 'Material-Design-Icons';
  content: "\e89c";
  font-size: 20px;
  line-height: 37px;
}
.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px;
}
.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0;
}
.select2-drop-mask {
  z-index: 700;
}
.form-group.form-md-line-input {
  position: relative;
  margin: 0 0 35px 0;
  padding-top: 20px;
}
.form-horizontal .form-group.form-md-line-input {
  padding-top: 10px;
  margin-bottom: 20px;
  margin: 0 -15px 20px -15px;
}
.form-horizontal .form-group.form-md-line-input > label {
  padding-top: 5px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  opacity: 0.8;
}
.form-group.form-md-line-input:last-child {
  margin-bottom: 0;
}
.form-group.form-md-line-input + .input-icon {
  padding-top: 0;
}
.form-group.form-md-line-input > .input-icon {
  padding-top: 20px;
}
.form-group.form-md-line-input > .input-icon > i {
  left: 0;
  bottom: 0;
  margin: 11px 2px 10px 10px;
  color: rgba(0, 0, 0, 0.54);
}
.form-group.form-md-line-input > .input-icon.right > i {
  left: auto;
  right: 8px;
  margin: 11px 2px 10px 10px;
}
.form-group.form-md-line-input .form-control {
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}
.form-group.form-md-line-input .form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.26);
  opacity: 1;
}
.form-group.form-md-line-input .form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.form-md-line-input .form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.form-md-line-input .form-control.form-control-static {
  border-bottom: 0;
}
.form-group.form-md-line-input .form-control.input-sm {
  font-size: 14px;
  padding: 6px 0;
}
.form-group.form-md-line-input .form-control.input-lg {
  font-size: 20px;
  padding: 14px 0;
}
.form-group.form-md-line-input .form-control ~ label,
.form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}
.form-horizontal .form-group.form-md-line-input .form-control ~ label,
.form-horizontal .form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: auto;
  left: 15px;
  right: 15px;
}
.form-group.form-md-line-input .form-control ~ label:after,
.form-group.form-md-line-input .form-control ~ .form-control-focus:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: 0.2s ease all;
}
.form-group.form-md-line-input .form-control ~ label {
  top: 0;
  margin-bottom: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  opacity: 0.8;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus {
  color: rgba(0, 0, 0, 0.54);
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #528dcc;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label {
  opacity: 1;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .help-block,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .help-block,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .help-block {
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
}
.form-group.form-md-line-input .form-control[readonly],
.form-group.form-md-line-input .form-control[disabled],
fieldset[disabled] .form-group.form-md-line-input .form-control {
  background: none;
  cursor: not-allowed;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
}
.form-group.form-md-line-input.form-md-floating-label .form-control ~ label {
  font-size: 16px;
  top: 25px;
  transition: 0.2s ease all;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.form-md-line-input.form-md-floating-label .form-control[readonly] ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.edited ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.form-control-static ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm ~ label {
  font-size: 14px;
  top: 24px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm[readonly] ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.form-control-static ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.edited ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg ~ label {
  font-size: 20px;
  top: 30px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg[readonly] ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.edited ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.form-control-static ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .input-icon > label {
  padding-left: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-icon.right > label {
  padding-left: 0;
  padding-right: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-group.left-addon label {
  padding-left: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-group.right-addon label {
  padding-right: 34px;
}
.form-group.form-md-line-input > .input-icon > i {
  margin-top: 9px;
}
.form-group.form-md-line-input > .input-icon .form-control {
  padding-left: 34px;
}
.form-group.form-md-line-input > .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 34px;
}
.form-group.form-md-line-input .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ .form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #1dbb99;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ label,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label {
  color: #1dbb99;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ i,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ i,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ i,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ i {
  color: #1dbb99;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ .help-block,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .help-block {
  color: #1dbb99;
}
.form-group.form-md-line-input.has-success .input-group-addon {
  color: #1dbb99;
}
.form-horizontal .form-group.form-md-line-input.has-success > label {
  color: #1dbb99;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #f7cf5f;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label {
  color: #f7cf5f;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ i,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ i,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ i,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ i {
  color: #f7cf5f;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .help-block,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .help-block {
  color: #f7cf5f;
}
.form-group.form-md-line-input.has-warning .input-group-addon {
  color: #f7cf5f;
}
.form-horizontal .form-group.form-md-line-input.has-warning > label {
  color: #f7cf5f;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #f35958;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ label,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label {
  color: #f35958;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ i,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ i,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ i,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ i {
  color: #f35958;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ .help-block,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .help-block {
  color: #f35958;
}
.form-group.form-md-line-input.has-error .input-group-addon {
  color: #f35958;
}
.form-horizontal .form-group.form-md-line-input.has-error > label {
  color: #f35958;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ .form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #3bafda;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ label,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label {
  color: #3bafda;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ i,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ i,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ i,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ i {
  color: #3bafda;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ .help-block,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .help-block,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .help-block {
  color: #3bafda;
}
.form-group.form-md-line-input.has-info .input-group-addon {
  color: #3bafda;
}
.form-horizontal .form-group.form-md-line-input.has-info > label {
  color: #3bafda;
}
.form-group.form-md-line-input + .input-group {
  padding-top: 0;
}
.form-group.form-md-line-input .input-group {
  padding-top: 20px;
}
.form-group.form-md-line-input .input-group .input-group-addon {
  border-radius: none;
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.form-group.form-md-line-input .input-group + .input-group-control {
  padding-top: 0;
}
.form-group.form-md-line-input .input-group .input-group-control {
  padding-top: 20px;
  position: relative;
  display: table-cell;
  vertical-align: bottom;
}
.form-group.form-md-line-input .input-group .input-group-btn .btn {
  border-radius: 2px;
}
.form-group.form-md-line-input .input-group .input-group-btn.btn-left .btn {
  margin-right: 10px;
  margin-bottom: -20px;
}
.form-group.form-md-line-input .input-group .input-group-btn.btn-right .btn {
  margin-left: 10px;
  margin-bottom: -20px;
}
.form-group.form-md-line-input .input-group.input-group-sm .input-group-btn .btn {
  margin-bottom: -20px;
}
.form-group.form-md-line-input .input-group.input-group-lg .input-group-btn .btn {
  margin-bottom: -20px;
}
/* Twitter Typeahead */
.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: #999999;
}
.tt-dropdown-menu {
  width: 422px;
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion p {
  margin: 0;
}
.twitter-typeahead {
  width: 100%;
  position: relative;
}
.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}
