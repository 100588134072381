/*
* mixins.less
*
*/
/*
* colors.less
*
*/
/*
* fonts.less
*
*/
.label1 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label1a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label1a a:not(.linky) {
  color: #000000;
}
.label1b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label1b a:not(.linky) {
  color: #757c87;
}
.label1c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label1c a:not(.linky) {
  color: #e71d1c;
}
.label1d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label1d a:not(.linky) {
  color: #ffffff;
}
.label1f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label1f a:not(.linky) {
  color: #bc8f30;
}
.label1 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label1a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label1a a:not(.linky) {
  color: #000000;
}
.label1b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label1b a:not(.linky) {
  color: #757c87;
}
.label1c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label1c a:not(.linky) {
  color: #e71d1c;
}
.label1d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label1d a:not(.linky) {
  color: #ffffff;
}
.label1f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label1f a:not(.linky) {
  color: #bc8f30;
}
.label2 {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label2a {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label2a a:not(.linky) {
  color: #000000;
}
.label2b {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label2b a:not(.linky) {
  color: #757c87;
}
.label2c {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label2c a:not(.linky) {
  color: #e71d1c;
}
.label2d {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label2d a:not(.linky) {
  color: #ffffff;
}
.label2f {
  font-family: Proxima Nova Reg Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label2f a:not(.linky) {
  color: #bc8f30;
}
.label3 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label3a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label3a a:not(.linky) {
  color: #000000;
}
.label3b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label3b a:not(.linky) {
  color: #757c87;
}
.label3c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label3c a:not(.linky) {
  color: #e71d1c;
}
.label3d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label3d a:not(.linky) {
  color: #ffffff;
}
.label3f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label3f a:not(.linky) {
  color: #bc8f30;
}
.label4 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label4a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label4a a:not(.linky) {
  color: #000000;
}
.label4b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label4b a:not(.linky) {
  color: #757c87;
}
.label4c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label4c a:not(.linky) {
  color: #e71d1c;
}
.label4d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label4d a:not(.linky) {
  color: #ffffff;
}
.label4f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label4f a:not(.linky) {
  color: #bc8f30;
}
.label6 {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label6a {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label6a a:not(.linky) {
  color: #000000;
}
.label6b {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label6b a:not(.linky) {
  color: #757c87;
}
.label6c {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label6c a:not(.linky) {
  color: #e71d1c;
}
.label6d {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label6d a:not(.linky) {
  color: #ffffff;
}
.label6f {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label6f a:not(.linky) {
  color: #bc8f30;
}
.label7 {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.label7a {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.label7a a:not(.linky) {
  color: #000000;
}
.label7b {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.label7b a:not(.linky) {
  color: #757c87;
}
.label7c {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.label7c a:not(.linky) {
  color: #e71d1c;
}
.label7d {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.label7d a:not(.linky) {
  color: #ffffff;
}
.label7f {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.label7f a:not(.linky) {
  color: #bc8f30;
}
.number1 {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.number1a {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.number1a a:not(.linky) {
  color: #000000;
}
.number1b {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.number1b a:not(.linky) {
  color: #757c87;
}
.number1c {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.number1c a:not(.linky) {
  color: #e71d1c;
}
.number1d {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.number1d a:not(.linky) {
  color: #ffffff;
}
.number1f {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.number1f a:not(.linky) {
  color: #bc8f30;
}
.number3 {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.number3a {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.number3a a:not(.linky) {
  color: #000000;
}
.number3b {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.number3b a:not(.linky) {
  color: #757c87;
}
.number3c {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.number3c a:not(.linky) {
  color: #e71d1c;
}
.number3d {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.number3d a:not(.linky) {
  color: #ffffff;
}
.number3f {
  font-family: Proxima Nova Semi Bold, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.number3f a:not(.linky) {
  color: #bc8f30;
}
.nav1 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
}
.nav1a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
  color: #000000;
}
.nav1a a:not(.linky) {
  color: #000000;
}
.nav1b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
  color: #757c87;
}
.nav1b a:not(.linky) {
  color: #757c87;
}
.nav1c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
  color: #e71d1c;
}
.nav1c a:not(.linky) {
  color: #e71d1c;
}
.nav1d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
  color: #ffffff;
}
.nav1d a:not(.linky) {
  color: #ffffff;
}
.nav1f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
  color: #bc8f30;
}
.nav1f a:not(.linky) {
  color: #bc8f30;
}
.nav2 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.nav2a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.nav2a a:not(.linky) {
  color: #000000;
}
.nav2b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.nav2b a:not(.linky) {
  color: #757c87;
}
.nav2c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.nav2c a:not(.linky) {
  color: #e71d1c;
}
.nav2d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.nav2d a:not(.linky) {
  color: #ffffff;
}
.nav2f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.nav2f a:not(.linky) {
  color: #bc8f30;
}
.nav3 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.nav3a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.nav3a a:not(.linky) {
  color: #000000;
}
.nav3b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.nav3b a:not(.linky) {
  color: #757c87;
}
.nav3c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.nav3c a:not(.linky) {
  color: #e71d1c;
}
.nav3d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.nav3d a:not(.linky) {
  color: #ffffff;
}
.nav3f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.nav3f a:not(.linky) {
  color: #bc8f30;
}
.body1 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.body1a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.body1a a:not(.linky) {
  color: #000000;
}
.body1b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.body1b a:not(.linky) {
  color: #757c87;
}
.body1c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.body1c a:not(.linky) {
  color: #e71d1c;
}
.body1d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.body1d a:not(.linky) {
  color: #ffffff;
}
.body1f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.body1f a:not(.linky) {
  color: #bc8f30;
}
.subhead2 {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
}
.subhead2a {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #000000;
}
.subhead2a a:not(.linky) {
  color: #000000;
}
.subhead2b {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #757c87;
}
.subhead2b a:not(.linky) {
  color: #757c87;
}
.subhead2c {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #e71d1c;
}
.subhead2c a:not(.linky) {
  color: #e71d1c;
}
.subhead2d {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #ffffff;
}
.subhead2d a:not(.linky) {
  color: #ffffff;
}
.subhead2f {
  font-family: Proxima Nova Reg, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  text-transform: inherit;
  letter-spacing: normal;
  line-height: 100%;
  color: #bc8f30;
}
.subhead2f a:not(.linky) {
  color: #bc8f30;
}
@font-face {
  font-family: 'Proxima Nova Reg';
  font-weight: 400;
  src: url('../../../../fonts/Proxima Nova Reg.eot');
  src: url('../../../../fonts/Proxima Nova Reg.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/Proxima Nova Reg.woff') format('woff'), url('../../../../fonts/Proxima Nova Reg.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Reg Italic';
  font-weight: 400;
  src: url('../../../../fonts/Proxima Nova Reg It.eot');
  src: url('../../../../fonts/Proxima Nova Reg It.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/Proxima Nova Reg It.woff') format('woff'), url('../../../../fonts/Proxima Nova Reg It.ttf') format('truetype');
}
@font-face {
  font-family: 'Plantin Italic';
  font-weight: 400;
  src: url('../../../../fonts/PlantinStd-Italic.eot');
  src: url('../../../../fonts/PlantinStd-Italic.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/PlantinStd-Italic.woff') format('woff'), url('../../../../fonts/PlantinStd-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Light';
  font-weight: 400;
  src: url('../../../../fonts/Proxima Nova Light.eot');
  src: url('../../../../fonts/Proxima Nova Light.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/Proxima Nova Light.woff') format('woff'), url('../../../../fonts/Proxima Nova Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Semi Bold';
  font-weight: 400;
  src: url('../../../../fonts/Proxima Nova Sbold.eot');
  src: url('../../../../fonts/Proxima Nova Sbold.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/Proxima Nova Sbold.woff') format('woff'), url('../../../../fonts/Proxima Nova Sbold.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Bold';
  font-weight: 400;
  src: url('../../../../fonts/Proxima Nova Bold.eot');
  src: url('../../../../fonts/Proxima Nova Bold.eot?#iefix') format('embedded-opentype'), url('../../../../fonts/Proxima Nova Bold.woff') format('woff'), url('../../../../fonts/Proxima Nova Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata'), url(https://themes.googleusercontent.com/static/fonts/inconsolata/v6/BjAYBlHtW3CJxDcjzrnZCLrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'), url(https://themes.googleusercontent.com/static/fonts/inconsolata/v6/AIed271kqQlcIRSOnQH0yRbnBKKEOwRKgsHDreGcocg.woff) format('woff');
}
/*
* animations.less
*
*/
@-webkit-keyframes floatUpRight {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(6deg);
    -moz-transform: translateY(0) rotate(6deg);
    -ms-transform: translateY(0) rotate(6deg);
    -o-transform: translateY(0) rotate(6deg);
    transform: translateY(0) rotate(6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(8deg);
    -moz-transform: translateY(-10px) rotate(8deg);
    -ms-transform: translateY(-10px) rotate(8deg);
    -o-transform: translateY(-10px) rotate(8deg);
    transform: translateY(-10px) rotate(8deg);
  }
}
@-moz-keyframes floatUpRight {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(6deg);
    -moz-transform: translateY(0) rotate(6deg);
    -ms-transform: translateY(0) rotate(6deg);
    -o-transform: translateY(0) rotate(6deg);
    transform: translateY(0) rotate(6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(8deg);
    -moz-transform: translateY(-10px) rotate(8deg);
    -ms-transform: translateY(-10px) rotate(8deg);
    -o-transform: translateY(-10px) rotate(8deg);
    transform: translateY(-10px) rotate(8deg);
  }
}
@-ms-keyframes floatUpRight {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(6deg);
    -moz-transform: translateY(0) rotate(6deg);
    -ms-transform: translateY(0) rotate(6deg);
    -o-transform: translateY(0) rotate(6deg);
    transform: translateY(0) rotate(6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(8deg);
    -moz-transform: translateY(-10px) rotate(8deg);
    -ms-transform: translateY(-10px) rotate(8deg);
    -o-transform: translateY(-10px) rotate(8deg);
    transform: translateY(-10px) rotate(8deg);
  }
}
@-o-keyframes floatUpRight {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(6deg);
    -moz-transform: translateY(0) rotate(6deg);
    -ms-transform: translateY(0) rotate(6deg);
    -o-transform: translateY(0) rotate(6deg);
    transform: translateY(0) rotate(6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(8deg);
    -moz-transform: translateY(-10px) rotate(8deg);
    -ms-transform: translateY(-10px) rotate(8deg);
    -o-transform: translateY(-10px) rotate(8deg);
    transform: translateY(-10px) rotate(8deg);
  }
}
@keyframes floatUpRight {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(6deg);
    -moz-transform: translateY(0) rotate(6deg);
    -ms-transform: translateY(0) rotate(6deg);
    -o-transform: translateY(0) rotate(6deg);
    transform: translateY(0) rotate(6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(8deg);
    -moz-transform: translateY(-10px) rotate(8deg);
    -ms-transform: translateY(-10px) rotate(8deg);
    -o-transform: translateY(-10px) rotate(8deg);
    transform: translateY(-10px) rotate(8deg);
  }
}
@-webkit-keyframes floatUpLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(-6deg);
    -moz-transform: translateY(0) rotate(-6deg);
    -ms-transform: translateY(0) rotate(-6deg);
    -o-transform: translateY(0) rotate(-6deg);
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(-8deg);
    -moz-transform: translateY(-10px) rotate(-8deg);
    -ms-transform: translateY(-10px) rotate(-8deg);
    -o-transform: translateY(-10px) rotate(-8deg);
    transform: translateY(-10px) rotate(-8deg);
  }
}
@-moz-keyframes floatUpLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(-6deg);
    -moz-transform: translateY(0) rotate(-6deg);
    -ms-transform: translateY(0) rotate(-6deg);
    -o-transform: translateY(0) rotate(-6deg);
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(-8deg);
    -moz-transform: translateY(-10px) rotate(-8deg);
    -ms-transform: translateY(-10px) rotate(-8deg);
    -o-transform: translateY(-10px) rotate(-8deg);
    transform: translateY(-10px) rotate(-8deg);
  }
}
@-ms-keyframes floatUpLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(-6deg);
    -moz-transform: translateY(0) rotate(-6deg);
    -ms-transform: translateY(0) rotate(-6deg);
    -o-transform: translateY(0) rotate(-6deg);
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(-8deg);
    -moz-transform: translateY(-10px) rotate(-8deg);
    -ms-transform: translateY(-10px) rotate(-8deg);
    -o-transform: translateY(-10px) rotate(-8deg);
    transform: translateY(-10px) rotate(-8deg);
  }
}
@-o-keyframes floatUpLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(-6deg);
    -moz-transform: translateY(0) rotate(-6deg);
    -ms-transform: translateY(0) rotate(-6deg);
    -o-transform: translateY(0) rotate(-6deg);
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(-8deg);
    -moz-transform: translateY(-10px) rotate(-8deg);
    -ms-transform: translateY(-10px) rotate(-8deg);
    -o-transform: translateY(-10px) rotate(-8deg);
    transform: translateY(-10px) rotate(-8deg);
  }
}
@keyframes floatUpLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0) rotate(-6deg);
    -moz-transform: translateY(0) rotate(-6deg);
    -ms-transform: translateY(0) rotate(-6deg);
    -o-transform: translateY(0) rotate(-6deg);
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-10px) rotate(-8deg);
    -moz-transform: translateY(-10px) rotate(-8deg);
    -ms-transform: translateY(-10px) rotate(-8deg);
    -o-transform: translateY(-10px) rotate(-8deg);
    transform: translateY(-10px) rotate(-8deg);
  }
}
@-webkit-keyframes floatDownLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(8px) rotate(-5deg);
    -moz-transform: translateY(8px) rotate(-5deg);
    -ms-transform: translateY(8px) rotate(-5deg);
    -o-transform: translateY(8px) rotate(-5deg);
    transform: translateY(8px) rotate(-5deg);
  }
}
@-moz-keyframes floatDownLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(8px) rotate(-5deg);
    -moz-transform: translateY(8px) rotate(-5deg);
    -ms-transform: translateY(8px) rotate(-5deg);
    -o-transform: translateY(8px) rotate(-5deg);
    transform: translateY(8px) rotate(-5deg);
  }
}
@-ms-keyframes floatDownLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(8px) rotate(-5deg);
    -moz-transform: translateY(8px) rotate(-5deg);
    -ms-transform: translateY(8px) rotate(-5deg);
    -o-transform: translateY(8px) rotate(-5deg);
    transform: translateY(8px) rotate(-5deg);
  }
}
@-o-keyframes floatDownLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(8px) rotate(-5deg);
    -moz-transform: translateY(8px) rotate(-5deg);
    -ms-transform: translateY(8px) rotate(-5deg);
    -o-transform: translateY(8px) rotate(-5deg);
    transform: translateY(8px) rotate(-5deg);
  }
}
@keyframes floatDownLeft {
  0%,
  100% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(8px) rotate(-5deg);
    -moz-transform: translateY(8px) rotate(-5deg);
    -ms-transform: translateY(8px) rotate(-5deg);
    -o-transform: translateY(8px) rotate(-5deg);
    transform: translateY(8px) rotate(-5deg);
  }
}
@-webkit-keyframes slideLeft {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
}
@-moz-keyframes slideLeft {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
}
@-ms-keyframes slideLeft {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
}
@-o-keyframes slideLeft {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
}
@keyframes slideLeft {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes slideUp {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes slideUp {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes slideUp {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.slide-up {
  -webkit-animation: slideUp 500ms ease-in 200ms;
  -moz-animation: slideUp 500ms ease-in 200ms;
  -ms-animation: slideUp 500ms ease-in 200ms;
  animation: slideUp 500ms ease-in 200ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceDown {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-moz-keyframes bounceDown {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-ms-keyframes bounceDown {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-o-keyframes bounceDown {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounceDown {
  0% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  1% {
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
.bounce-down {
  -webkit-animation: bounceDown 1s ease-in-out 200ms;
  -moz-animation: bounceDown 1s ease-in-out 200ms;
  -ms-animation: bounceDown 1s ease-in-out 200ms;
  animation: bounceDown 1s ease-in-out 200ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
#loadingSpinner {
  height: 2em;
  width: 2em;
  border-radius: 100%;
  background: #eaab00;
  display: block;
  margin: 2em auto;
  position: relative;
  z-index: 20;
  -webkit-animation: spin 2s ease infinite;
  -moz-animation: spin 2s ease infinite;
  -ms-animation: spin 2s ease infinite;
  animation: spin 2s ease infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
#loadingSpinner.inline {
  display: inline-block;
  margin: 0;
  margin-bottom: 8px;
  height: 5px;
  width: 5px;
  -webkit-animation: spinSmall 2s ease infinite;
  -moz-animation: spinSmall 2s ease infinite;
  -ms-animation: spinSmall 2s ease infinite;
  animation: spinSmall 2s ease infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
#loadingSpinner.inline:before,
#loadingSpinner.inline:after {
  -webkit-animation: spinUpSmall 2s ease infinite;
  -moz-animation: spinUpSmall 2s ease infinite;
  -ms-animation: spinUpSmall 2s ease infinite;
  animation: spinUpSmall 2s ease infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
#loadingSpinner.inline:before {
  left: -6.5px;
}
#loadingSpinner.inline:after {
  left: 6.5px;
}
#loadingSpinner:before,
#loadingSpinner:after {
  content: '';
  display: block;
  position: absolute;
  height: inherit;
  width: inherit;
  background: inherit;
  border-radius: inherit;
  -webkit-animation: spinUp 2s ease infinite;
  -moz-animation: spinUp 2s ease infinite;
  -ms-animation: spinUp 2s ease infinite;
  animation: spinUp 2s ease infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}
#loadingSpinner:before {
  left: -2.3em;
  background: #739600;
}
#loadingSpinner:after {
  left: 2.3em;
  background: #d52b1e;
}
@-webkit-keyframes spin {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 2em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spin {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 2em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-ms-keyframes spin {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 2em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-o-keyframes spin {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 2em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes spin {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 2em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -1em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes spinUp {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 4em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spinUp {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 4em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-ms-keyframes spinUp {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 4em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-o-keyframes spinUp {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 4em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes spinUp {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 4em;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes spinSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spinSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-ms-keyframes spinSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-o-keyframes spinSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes spinSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  50% {
    top: 5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  55% {
    top: -2.5px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes spinUpSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 10px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spinUpSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 10px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-ms-keyframes spinUpSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 10px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-o-keyframes spinUpSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 10px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes spinUpSmall {
  0% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    top: 10px;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    top: 0;
    -webkit-transform: translatez(0);
    -moz-transform: translateZ(0);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
/*
* pigments.less
*
*/
.highlight .hll {
  background-color: #ffffcc;
}
.highlight {
  background: #eeffcc;
}
.highlight .c {
  color: #408090;
  font-style: italic;
}
/* Comment */
.highlight .err {
  border: 1px solid #ff0000;
}
/* Error */
.highlight .k {
  color: #007020;
  font-weight: bold;
}
/* Keyword */
.highlight .o {
  color: #666666;
}
/* Operator */
.highlight .cm {
  color: #408090;
  font-style: italic;
}
/* Comment.Multiline */
.highlight .cp {
  color: #007020;
}
/* Comment.Preproc */
.highlight .c1 {
  color: #408090;
  font-style: italic;
}
/* Comment.Single */
.highlight .cs {
  color: #408090;
  background-color: #fff0f0;
}
/* Comment.Special */
.highlight .gd {
  color: #a00000;
}
/* Generic.Deleted */
.highlight .ge {
  font-style: italic;
}
/* Generic.Emph */
.highlight .gr {
  color: #ff0000;
}
/* Generic.Error */
.highlight .gh {
  color: #000080;
  font-weight: bold;
}
/* Generic.Heading */
.highlight .gi {
  color: #00a000;
}
/* Generic.Inserted */
.highlight .go {
  color: #303030;
}
/* Generic.Output */
.highlight .gp {
  color: #c65d09;
  font-weight: bold;
}
/* Generic.Prompt */
.highlight .gs {
  font-weight: bold;
}
/* Generic.Strong */
.highlight .gu {
  color: #800080;
  font-weight: bold;
}
/* Generic.Subheading */
.highlight .gt {
  color: #0040d0;
}
/* Generic.Traceback */
.highlight .kc {
  color: #007020;
  font-weight: bold;
}
/* Keyword.Constant */
.highlight .kd {
  color: #007020;
  font-weight: bold;
}
/* Keyword.Declaration */
.highlight .kn {
  color: #007020;
  font-weight: bold;
}
/* Keyword.Namespace */
.highlight .kp {
  color: #007020;
}
/* Keyword.Pseudo */
.highlight .kr {
  color: #007020;
  font-weight: bold;
}
/* Keyword.Reserved */
.highlight .kt {
  color: #902000;
}
/* Keyword.Type */
.highlight .m {
  color: #208050;
}
/* Literal.Number */
.highlight .s {
  color: #4070a0;
}
/* Literal.String */
.highlight .na {
  color: #4070a0;
}
/* Name.Attribute */
.highlight .nb {
  color: #007020;
}
/* Name.Builtin */
.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}
/* Name.Class */
.highlight .no {
  color: #60add5;
}
/* Name.Constant */
.highlight .nd {
  color: #555555;
  font-weight: bold;
}
/* Name.Decorator */
.highlight .ni {
  color: #d55537;
  font-weight: bold;
}
/* Name.Entity */
.highlight .ne {
  color: #007020;
}
/* Name.Exception */
.highlight .nf {
  color: #06287e;
}
/* Name.Function */
.highlight .nl {
  color: #002070;
  font-weight: bold;
}
/* Name.Label */
.highlight .nn {
  color: #0e84b5;
  font-weight: bold;
}
/* Name.Namespace */
.highlight .nt {
  color: #062873;
  font-weight: bold;
}
/* Name.Tag */
.highlight .nv {
  color: #bb60d5;
}
/* Name.Variable */
.highlight .ow {
  color: #007020;
  font-weight: bold;
}
/* Operator.Word */
.highlight .w {
  color: #bbbbbb;
}
/* Text.Whitespace */
.highlight .mf {
  color: #208050;
}
/* Literal.Number.Float */
.highlight .mh {
  color: #208050;
}
/* Literal.Number.Hex */
.highlight .mi {
  color: #208050;
}
/* Literal.Number.Integer */
.highlight .mo {
  color: #208050;
}
/* Literal.Number.Oct */
.highlight .sb {
  color: #4070a0;
}
/* Literal.String.Backtick */
.highlight .sc {
  color: #4070a0;
}
/* Literal.String.Char */
.highlight .sd {
  color: #4070a0;
  font-style: italic;
}
/* Literal.String.Doc */
.highlight .s2 {
  color: #4070a0;
}
/* Literal.String.Double */
.highlight .se {
  color: #4070a0;
  font-weight: bold;
}
/* Literal.String.Escape */
.highlight .sh {
  color: #4070a0;
}
/* Literal.String.Heredoc */
.highlight .si {
  color: #70a0d0;
  font-style: italic;
}
/* Literal.String.Interpol */
.highlight .sx {
  color: #c65d09;
}
/* Literal.String.Other */
.highlight .sr {
  color: #235388;
}
/* Literal.String.Regex */
.highlight .s1 {
  color: #4070a0;
}
/* Literal.String.Single */
.highlight .ss {
  color: #517918;
}
/* Literal.String.Symbol */
.highlight .bp {
  color: #007020;
}
/* Name.Builtin.Pseudo */
.highlight .vc {
  color: #bb60d5;
}
/* Name.Variable.Class */
.highlight .vg {
  color: #bb60d5;
}
/* Name.Variable.Global */
.highlight .vi {
  color: #bb60d5;
}
/* Name.Variable.Instance */
.highlight .il {
  color: #208050;
}
/* Literal.Number.Integer.Long */
/*
* navs.less
*
*/
/*
* labels.less
*
*
*/
h1 {
  font-family: "Roboto", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 34px;
  line-height: 40px;
  color: #000000;
}
h2 {
  font-family: "Roboto", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}
h3 {
  font-family: "Roboto", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
}
.sl-sm,
.sl-12,
.strapped-label-small {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 400;
}
.sl-sm-lt,
.sl-12-lt,
.strapped-label-small-light {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 300;
}
.sl-sm-sb,
.sl-12-sb,
.strapped-label-small-semi-bold {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
}
.sl-sm-b,
.sl-12-b,
.strapped-label-small-bold {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 700;
}
.f-14,
.f {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
}
.sl,
.sl-14 .strapped-label {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
.sl-lt,
.sl-14-lt,
.strapped-label-light {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
.f-sb {
  font: normal normal 500 14px "Roboto", arial, sans-serif;
}
.sl-sb,
.sl-14-sb .strapped-label-semi-bold {
  font: normal normal 500 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
.sl-b,
.sl-14-b,
.strapped-label-bold {
  font: normal normal 700 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
.sl-md,
.sl-16,
.strapped-label-medium {
  font: normal normal normal 16px "Roboto", arial, sans-serif;
  line-height: 24px;
}
.sl-md-i,
.sl-16-i,
.strapped-label-medium-italic {
  font: normal normal normal 16px "Plantin Italic", arial, sans-serif;
  line-height: 24px;
}
.sl-md-lt,
.sl-16-lt,
.strapped-label-medium-light {
  font: normal normal normal 16px "Roboto", arial, sans-serif;
  line-height: 24px;
}
.sl-md-sb,
.sl-16-sb,
.strapped-label-medium-semi-bold {
  font: normal normal normal 16px "Roboto", arial, sans-serif;
  line-height: 24px;
}
.sl-md-b,
.sl-16-b,
.strapped-label-medium-bold {
  font: normal normal normal 16px "Roboto", arial, sans-serif;
  line-height: 24px;
}
.sl-20 {
  font: normal normal normal 20px "Roboto", arial, sans-serif;
  line-height: 28px;
}
.sl-20-i {
  font: normal normal normal 20px "Plantin Italic", arial, sans-serif;
  line-height: 28px;
}
.sl-20-lt {
  font: normal normal normal 20px "Roboto", arial, sans-serif;
  line-height: 28px;
}
.sl-20-sb {
  font: normal normal normal 20px "Roboto", arial, sans-serif;
  line-height: 28px;
}
.sl-20-b {
  font: normal normal normal 20px "Roboto", arial, sans-serif;
  line-height: 28px;
}
.sl-24 {
  font: normal normal normal 24px "Roboto", arial, sans-serif;
  line-height: 32px;
}
.sl-24-i {
  font: normal normal normal 24px "Plantin Italic", arial, sans-serif;
  line-height: 32px;
}
.sl-24-lt {
  font: normal normal normal 24px "Roboto", arial, sans-serif;
  line-height: 32px;
}
.sl-24-sb {
  font: normal normal normal 24px "Roboto", arial, sans-serif;
  line-height: 32px;
}
.sl-24-b {
  font: normal normal normal 24px "Roboto", arial, sans-serif;
  line-height: 32px;
}
.sl-lg,
.sl-32,
.strapped-label-large {
  font: normal normal normal 32px "Roboto", arial, sans-serif;
  line-height: 40px;
}
.sl-lg-i,
.sl-32-i,
.strapped-label-large-italic {
  font: normal normal normal 32px "Plantin Italic", arial, sans-serif;
  line-height: 40px;
}
.sl-lg-lt,
.sl-32-lt,
.strapped-label-large-light {
  font: normal normal normal 32px "Roboto", arial, sans-serif;
  line-height: 40px;
}
.sl-lg-sb,
.sl-32-sb,
.strapped-label-large-semi-bold {
  font: normal normal normal 32px "Roboto", arial, sans-serif;
  line-height: 40px;
}
.sl-lg-b,
.sl-32-b,
.strapped-label-large-bold {
  font: normal normal normal 32px "Roboto", arial, sans-serif;
  line-height: 40px;
}
.sl-44 {
  font: normal normal normal 44px "Roboto", arial, sans-serif;
  line-height: 52px;
}
.sl-44-i {
  font: normal normal normal 44px "Plantin Italic", arial, sans-serif;
  line-height: 52px;
}
.sl-44-lt {
  font: normal normal normal 44px "Roboto", arial, sans-serif;
  line-height: 52px;
}
.sl-44-sb {
  font: normal normal normal 44px "Roboto", arial, sans-serif;
  line-height: 52px;
}
.sl-44-b {
  font: normal normal normal 44px "Roboto", arial, sans-serif;
  line-height: 52px;
}
.sl-code,
pre {
  font: normal normal normal 14px "Inconsolata", Monaco, Menlo, "Courier New", monospace;
  line-height: 20px;
}
.sl-warning {
  color: #ac7b0d;
}
.sl-disabled {
  color: #757c87;
}
.sl-danger {
  color: #e71d1c;
}
.sl-dark {
  background-color: #000000;
  color: #ffffff;
}
.sl-link {
  color: #528dcc;
}
.sl-link:visited,
.sl-link:active {
  color: inherit;
}
.sl-link:hover {
  color: #0063b4;
}
.sl-none {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
  line-height: 20px;
  color: #9196a1;
}
.sl-none:hover {
  color: #9196a1;
}
.sl-note {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 400;
  color: #59606b;
}
.num-sm {
  color: #000000;
  font: normal normal normal 18px "Roboto", arial, sans-serif;
}
.num-md {
  color: #000000;
  font: normal normal normal 24px "Roboto", arial, sans-serif;
}
.num-lg {
  color: #000000;
  font: normal normal normal 56px "Roboto", arial, sans-serif;
}
.num-xlg {
  color: #000000;
  font: normal normal normal 150px "Roboto", arial, sans-serif;
}
.danger {
  color: #e71d1c;
}
.success {
  color: #4bb2a4;
}
.disabled {
  color: #757c87;
}
.light {
  color: #b1b9bd;
}
.tb,
.title-block {
  background-color: #d2d6d9;
  height: 70px;
  margin-bottom: 0;
}
.tb .tb-icon,
.title-block .tb-icon {
  font-size: 36px;
  line-height: 86px;
}
.tb .tb-label,
.title-block .tb-label {
  line-height: 70px;
}
.tb .tb-label,
.title-block .tb-label,
.tb .tb-icon,
.title-block .tb-icon {
  background-color: #434854;
  background-image: none;
  color: #ffffff;
  display: block;
  float: left;
  height: 70px;
  text-align: center;
  text-indent: 0;
  width: 70px;
}
.tb .tb-content,
.title-block .tb-content {
  font: normal normal normal 16px "Roboto", arial, sans-serif;
  line-height: 24px;
  color: #1c1f26;
  line-height: 70px;
  letter-spacing: normal;
  padding: 0 20px;
  text-transform: inherit;
}
.tb .tb-actions,
.title-block .tb-actions {
  display: block;
  float: right;
  height: 70px;
  line-height: 70px;
  margin-right: 20px;
}
.tb.tb-imperial-red,
.title-block.tb-imperial-red {
  background-color: #f17555;
  color: #ffffff;
}
.tb.tb-imperial-red .tb-icon,
.title-block.tb-imperial-red .tb-icon,
.tb.tb-imperial-red .tb-label,
.title-block.tb-imperial-red .tb-label {
  background-color: #e71d1c;
}
.tb.tb-egyptian-blue,
.title-block.tb-egyptian-blue {
  background-color: #82aecb;
  color: #ffffff;
}
.tb.tb-egyptian-blue .tb-icon,
.title-block.tb-egyptian-blue .tb-icon,
.tb.tb-egyptian-blue .tb-label,
.title-block.tb-egyptian-blue .tb-label {
  background-color: #4789b0;
}
.table_strapped {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
.table_strapped th,
.table_strapped td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #b1b9bd;
}
.table_strapped thead th {
  vertical-align: bottom;
}
.table_strapped caption + thead tr:first-child th,
.table_strapped caption + thead tr:first-child td,
.table_strapped colgroup + thead tr:first-child th,
.table_strapped colgroup + thead tr:first-child td,
.table_strapped thead:first-child tr:first-child th,
.table_strapped thead:first-child tr:first-child td {
  border-top: 0;
}
.table_strapped tbody + tbody {
  border-top: 2px solid #b1b9bd;
}
.table_strapped .table {
  background-color: #ffffff;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
table.items {
  table-layout: fixed;
  width: 100%;
  border: 0;
}
table.items > thead > tr > th.x-field-drag,
table.items > thead th.x-field-drag,
table.items > tbody > tr > td.x-field-drag,
table.items > tbody td.x-field-drag,
table.items > thead > tr > th.x-field-action,
table.items > thead th.x-field-action,
table.items > tbody > tr > td.x-field-action,
table.items > tbody td.x-field-action {
  width: 28px;
  padding: 0px!important;
  text-align: center;
}
table.items > thead > tr > th.x-field-drag > a,
table.items > thead th.x-field-drag > a,
table.items > tbody > tr > td.x-field-drag > a,
table.items > tbody td.x-field-drag > a,
table.items > thead > tr > th.x-field-action > a,
table.items > thead th.x-field-action > a,
table.items > tbody > tr > td.x-field-action > a,
table.items > tbody td.x-field-action > a,
table.items > thead > tr > th.x-field-drag > a:link,
table.items > thead th.x-field-drag > a:link,
table.items > tbody > tr > td.x-field-drag > a:link,
table.items > tbody td.x-field-drag > a:link,
table.items > thead > tr > th.x-field-action > a:link,
table.items > thead th.x-field-action > a:link,
table.items > tbody > tr > td.x-field-action > a:link,
table.items > tbody td.x-field-action > a:link {
  line-height: 24px;
  width: 36px;
  height: auto;
  display: inline-block;
}
table.items > thead > tr > th.x-field-action,
table.items > thead th.x-field-action,
table.items > tbody > tr > td.x-field-action,
table.items > tbody td.x-field-action {
  width: 64px;
  padding: 0 15px!important;
  overflow: visible;
}
table.items > thead > tr > th.x-field-action .btn-group,
table.items > thead th.x-field-action .btn-group,
table.items > tbody > tr > td.x-field-action .btn-group,
table.items > tbody td.x-field-action .btn-group {
  margin-top: 10px;
}
table.items > thead > tr > th.x-field-action .btn-group .md-btn,
table.items > thead th.x-field-action .btn-group .md-btn,
table.items > tbody > tr > td.x-field-action .btn-group .md-btn,
table.items > tbody td.x-field-action .btn-group .md-btn {
  width: 34px;
  height: 34px;
  float: left;
}
table.items > thead > tr > th.x-field-index,
table.items > thead th.x-field-index,
table.items > tbody > tr > td.x-field-index,
table.items > tbody td.x-field-index {
  width: 40px;
  text-align: center;
}
table.items > thead > tr > th.x-field-name,
table.items > thead th.x-field-name,
table.items > tbody > tr > td.x-field-name,
table.items > tbody td.x-field-name {
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table.items > thead > tr > th.x-field-desc,
table.items > thead th.x-field-desc,
table.items > tbody > tr > td.x-field-desc,
table.items > tbody td.x-field-desc {
  white-space: nowrap;
  word-wrap: normal;
}
table.items > thead > tr > th.x-field-size,
table.items > thead th.x-field-size,
table.items > tbody > tr > td.x-field-size,
table.items > tbody td.x-field-size,
table.items > thead > tr > th.x-field-rate,
table.items > thead th.x-field-rate,
table.items > tbody > tr > td.x-field-rate,
table.items > tbody td.x-field-rate,
table.items > thead > tr > th.x-field-total,
table.items > thead th.x-field-total,
table.items > tbody > tr > td.x-field-total,
table.items > tbody td.x-field-total,
table.items > thead > tr > th.x-field-labor,
table.items > thead th.x-field-labor,
table.items > tbody > tr > td.x-field-labor,
table.items > tbody td.x-field-labor {
  width: 105px;
  text-align: right;
}
table.items > thead > tr > th.x-field-sqft,
table.items > thead th.x-field-sqft,
table.items > tbody > tr > td.x-field-sqft,
table.items > tbody td.x-field-sqft {
  width: 90px;
  text-align: right;
}
table.items > thead > tr > th.x-field-size span.primary,
table.items > thead th.x-field-size span.primary,
table.items > tbody > tr > td.x-field-size span.primary,
table.items > tbody td.x-field-size span.primary {
  text-align: left;
}
table.items > thead > tr > th.x-field-size span.primary > span,
table.items > thead th.x-field-size span.primary > span,
table.items > tbody > tr > td.x-field-size span.primary > span,
table.items > tbody td.x-field-size span.primary > span {
  text-align: right;
  float: right;
}
table.items > thead > tr > th,
table.items > thead {
  float: none;
  padding: 0px 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #eeeeee;
  border-bottom: 2px solid #bdbdbd;
  text-align: left;
  border-left: 1px solid #bdbdbd;
}
table.items > thead > tr > th:first-child,
table.items > thead:first-child {
  border-left: 0;
}
table.items > thead > tr > th:first-child:not(.status),
table.items > thead:first-child:not(.status) {
  padding-left: 0px;
  padding-right: 0px;
  border-left: 0;
}
table.items > thead > tr > th:last-child,
table.items > thead:last-child {
  padding-right: 15px;
}
table.items > thead > tr > th:last-child.sortable,
table.items > thead:last-child.sortable {
  padding-right: 5px;
}
table.items > thead > tr > th > span,
table.items > thead > span {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  color: #757c87;
  line-height: 36px;
  text-transform: uppercase;
}
table.items > thead > tr > th.has-filter,
table.items > thead.has-filter {
  padding: 0;
}
table.items > thead > tr > th.has-filter:first-child,
table.items > thead.has-filter:first-child {
  padding-left: 15px;
}
table.items.side {
  table-layout: auto;
}
table.items.non-interactive tbody tr:hover,
table.items.non-interactive tfoot tr:hover {
  cursor: auto;
  background-color: transparent;
}
table.items tbody tr,
table.items tfoot tr {
  border-bottom: 1px solid #eeeeee;
  background-color: transparent;
}
table.items tbody tr:last-child,
table.items tfoot tr:last-child {
  border-bottom: 2px solid #bdbdbd;
}
table.items tbody tr:hover,
table.items tfoot tr:hover {
  cursor: pointer;
  background-color: #e0e9f6;
}
table.items tbody tr:hover .no-results,
table.items tfoot tr:hover .no-results {
  background-color: transparent;
}
table.items tbody tr td,
table.items tfoot tr td,
table.items tbody tr span,
table.items tfoot tr span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table.items tbody tr td,
table.items tfoot tr td {
  padding: 0 10px;
  border-left: 1px dotted rgba(0, 0, 0, 0.12);
}
table.items tbody tr td:first-child,
table.items tfoot tr td:first-child {
  border-left: 0;
}
table.items tbody tr td:first-child:not(.status),
table.items tfoot tr td:first-child:not(.status) {
  padding-left: 0px;
  padding-right: 0px;
  border-left: 0;
  text-align: center;
  font-weight: 700;
}
table.items tbody tr td:last-child,
table.items tfoot tr td:last-child {
  padding-right: 15px;
  border-right: 0;
}
table.items tbody tr td.wrap-text,
table.items tfoot tr td.wrap-text {
  white-space: normal;
  word-break: break-all;
  padding-top: 10px;
  padding-bottom: 10px;
}
table.items tbody tr td.wrap-text > span,
table.items tfoot tr td.wrap-text > span {
  white-space: normal;
  line-height: 22px;
}
table.items tbody tr td.no-results,
table.items tfoot tr td.no-results {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
  line-height: 20px;
  color: #9196a1;
  padding: 12px 15px 12px 25px;
  cursor: default;
}
table.items tbody tr td.no-results:hover,
table.items tfoot tr td.no-results:hover {
  color: #9196a1;
}
table.items tbody tr td > span,
table.items tfoot tr td > span {
  line-height: 50px;
}
table.items tbody tr td > a,
table.items tfoot tr td > a,
table.items tbody tr td > span.holder,
table.items tfoot tr td > span.holder {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}
table.items tbody tr td > a span,
table.items tfoot tr td > a span,
table.items tbody tr td > span.holder span,
table.items tfoot tr td > span.holder span {
  padding: 0;
  display: block;
  line-height: 50px;
}
table.items tbody tr td.two-lines > a > span,
table.items tfoot tr td.two-lines > a > span,
table.items tbody tr td.two-lines > span.holder > span,
table.items tfoot tr td.two-lines > span.holder > span {
  padding-top: 4px !important;
  padding-bottom: 2px !important;
}
table.items tbody tr td.two-lines > a > span span.primary,
table.items tfoot tr td.two-lines > a > span span.primary,
table.items tbody tr td.two-lines > span.holder > span span.primary,
table.items tfoot tr td.two-lines > span.holder > span span.primary {
  line-height: 16px;
  padding: 4px 0 1px !important;
  display: block;
}
table.items tbody tr td.two-lines > a > span span.primary ~ span.primary,
table.items tfoot tr td.two-lines > a > span span.primary ~ span.primary,
table.items tbody tr td.two-lines > span.holder > span span.primary ~ span.primary,
table.items tfoot tr td.two-lines > span.holder > span span.primary ~ span.primary {
  padding: 1px 0 4px !important;
  display: block;
}
table.items tbody tr td.two-lines > a > span span.primary > span,
table.items tfoot tr td.two-lines > a > span span.primary > span,
table.items tbody tr td.two-lines > span.holder > span span.primary > span,
table.items tfoot tr td.two-lines > span.holder > span span.primary > span,
table.items tbody tr td.two-lines > a > span span.primary > span,
table.items tfoot tr td.two-lines > a > span span.primary > span,
table.items tbody tr td.two-lines > span.holder > span span.primary > span,
table.items tfoot tr td.two-lines > span.holder > span span.primary > span {
  font-weight: 400;
  display: inline;
  line-height: 16px;
}
table.items tbody tr td.two-lines > a > span span.secondary,
table.items tfoot tr td.two-lines > a > span span.secondary,
table.items tbody tr td.two-lines > span.holder > span span.secondary,
table.items tfoot tr td.two-lines > span.holder > span span.secondary {
  font-family: "Roboto", arial, sans-serif;
  font-weight: 400;
  line-height: 14px;
  color: #9196a1;
  padding: 1px 0 4px !important;
  display: block;
}
table.items tbody tr td.date .secondary,
table.items tfoot tr td.date .secondary {
  text-transform: lowercase;
}
table.items tbody tr td.status > a > span:before,
table.items tfoot tr td.status > a > span:before,
table.items tbody tr td.status > span.holder > span:before,
table.items tfoot tr td.status > span.holder > span:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 6px;
}
table.items tbody tr td.status.two-lines > a > span:before,
table.items tfoot tr td.status.two-lines > a > span:before,
table.items tbody tr td.status.two-lines > span.holder > span:before,
table.items tfoot tr td.status.two-lines > span.holder > span:before {
  float: left;
  margin-top: 14px;
}
table.items tbody tr td.status.two-lines span > .secondary,
table.items tfoot tr td.status.two-lines span > .secondary {
  margin-left: 14px;
}
table.items tbody tr td.status.two-lines span.failed:before,
table.items tfoot tr td.status.two-lines span.failed:before {
  float: left;
  margin-top: 14px;
}
table.items tbody tr td.status .succeeded:before,
table.items tfoot tr td.status .succeeded:before,
table.items tbody tr td.status .approved:before,
table.items tfoot tr td.status .approved:before,
table.items tbody tr td.status .captured:before,
table.items tfoot tr td.status .captured:before,
table.items tbody tr td.status .created:before,
table.items tfoot tr td.status .created:before,
table.items tbody tr td.status .won:before,
table.items tfoot tr td.status .won:before,
table.items tbody tr td.status .paid:before,
table.items tfoot tr td.status .paid:before,
table.items tbody tr td.status .updated:before,
table.items tfoot tr td.status .updated:before,
table.items tbody tr td.status .deposited:before,
table.items tfoot tr td.status .deposited:before,
table.items tbody tr td.status .verified:before,
table.items tfoot tr td.status .verified:before,
table.items tbody tr td.status .active:before,
table.items tfoot tr td.status .active:before,
table.items tbody tr td.status .good:before,
table.items tfoot tr td.status .good:before {
  background-color: #00a268;
}
table.items tbody tr td.status .needs_attention:before,
table.items tfoot tr td.status .needs_attention:before,
table.items tbody tr td.status .sent:before,
table.items tfoot tr td.status .sent:before,
table.items tbody tr td.status .pending:before,
table.items tfoot tr td.status .pending:before,
table.items tbody tr td.status .scheduled:before,
table.items tfoot tr td.status .scheduled:before,
table.items tbody tr td.status .unverified:before,
table.items tfoot tr td.status .unverified:before,
table.items tbody tr td.status .unverifiable:before,
table.items tfoot tr td.status .unverifiable:before {
  background-color: #c6ab34;
}
table.items tbody tr td.status .failed:before,
table.items tfoot tr td.status .failed:before,
table.items tbody tr td.status .expired:before,
table.items tfoot tr td.status .expired:before,
table.items tbody tr td.status .overdue:before,
table.items tfoot tr td.status .overdue:before,
table.items tbody tr td.status .rejected:before,
table.items tfoot tr td.status .rejected:before,
table.items tbody tr td.status .lost:before,
table.items tfoot tr td.status .lost:before,
table.items tbody tr td.status .arbitration:before,
table.items tfoot tr td.status .arbitration:before,
table.items tbody tr td.status .canceled:before,
table.items tfoot tr td.status .canceled:before,
table.items tbody tr td.status .voided:before,
table.items tfoot tr td.status .voided:before,
table.items tbody tr td.status .invalidated:before,
table.items tfoot tr td.status .invalidated:before,
table.items tbody tr td.status .removed:before,
table.items tfoot tr td.status .removed:before,
table.items tbody tr td.status .bad:before,
table.items tfoot tr td.status .bad:before {
  background-color: #ec4e33;
}
table.items tbody tr td.status .under_review:before,
table.items tfoot tr td.status .under_review:before,
table.items tbody tr td.status .draft:before,
table.items tfoot tr td.status .draft:before {
  background-color: #a57bb7;
}
table.items tbody tr td.label4a a:link,
table.items tfoot tr td.label4a a:link,
table.items tbody tr td.label4a a:visited,
table.items tfoot tr td.label4a a:visited,
table.items tbody tr td.label4a a:hover,
table.items tfoot tr td.label4a a:hover,
table.items tbody tr td.label4a a:active,
table.items tfoot tr td.label4a a:active {
  color: #000000;
}
table.items tbody tr td.label4a.black,
table.items tfoot tr td.label4a.black {
  color: #000000;
}
table.items tfoot tr:hover {
  background-color: transparent;
  cursor: default;
}
table.items tfoot tr.load-more-results {
  border-bottom: none;
}
table.items tfoot tr.load-more-results td {
  padding: 12px 25px;
  text-align: center;
}
table.items tfoot tr.load-more-results td a {
  display: inline;
}
table.items .num,
table.items .align-right {
  text-align: right;
}
.no-results {
  font: normal normal normal 32px "Roboto", arial, sans-serif;
  line-height: 40px;
  color: #b1b9bd;
}
.no-results i {
  font-size: 80px;
  color: #d2d6d9;
  display: block;
  margin: 80px 0 20px;
}
.x-input {
  font: normal normal normal 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
table .btn-link:hover {
  color: #212121;
  background-color: rgba(0, 0, 0, 0.26);
  text-decoration: none;
}
/*
* base.less
*
* Here we define less/css that affects the whole site.
*
* Styles for individual components/pages should go in their own file.
*/
html,
body {
  height: 100%;
}
#balanced-app,
#balanced-app > div.ember-view {
  height: 100%;
}
#balanced-app #main,
#balanced-app > div.ember-view #main {
  height: calc(100% - 50px);
}
body.overlaid #main-overlay {
  display: block;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
input:focus,
select:focus,
textarea:focus,
a:focus {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}
.empty-field-label {
  font-style: italic;
}
table {
  border-collapse: collapse;
}
table th {
  text-align: left;
}
strong {
  font: normal normal 700 14px "Roboto", arial, sans-serif;
  line-height: 20px;
}
.window {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.window .frame {
  height: 200px;
  width: 100%;
  top: 50%;
  position: absolute;
  margin: -100px auto 0;
  text-align: center;
}
.window.black {
  background-color: #000;
  opacity: 80;
  filter: alpha(opacity=8000);
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.full-page-form label.control-styled-upload {
  width: 100%;
  padding-top: 0;
}
.full-page-form label.control-styled-upload:after {
  content: "";
}
label.control-styled-upload {
  float: none;
  text-align: right;
}
label.control-styled-upload .btn {
  margin-left: 15px;
}
label.control-styled-upload input[type=file] {
  left: 0;
  opacity: 0;
  position: absolute;
}
.table.table-fixed {
  table-layout: fixed;
  width: 100%;
}
.table.table-fixed th,
.table.table-fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 9px 5px;
}
.table-column-total {
  text-align: right;
  width: 100px;
}
.table-column-icon {
  text-align: center;
  width: 16px;
}
.table-column-20percent {
  width: 20%;
}
.table-column .icon.icon-alert {
  bottom: -2px;
  color: #e71d1c;
  position: relative;
}
.table-column .icon.icon-checkmark {
  color: #00927b;
}
.table-border tbody tr {
  border-top: 1px solid #b1b9bd;
}
.table-row a {
  cursor: pointer;
}
table th.fluid25,
table td.fluid25 {
  width: 25%;
}
table th.fluid50,
table td.fluid50 {
  width: 50%;
}
table th.fluid33,
table td.fluid33 {
  width: 33%;
}
table th.fluid66,
table td.fluid66 {
  width: 66%;
}
