/*!
 *
 *  Project:  GLYPHICONS HALFLINGS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons_regular/glyphicons-regular.eot');
  src: url('../fonts/glyphicons_regular/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons_regular/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons_regular/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons_regular/glyphicons-regular.svg#glyphiconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.glyphicons {
  display: inline-block;
  position: relative;
  padding-left: 48px;
  color: #1d1d1b;
  text-decoration: none;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.glyphicons i:before {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  font: 24px/1em 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  color: #1d1d1b;
  *display: inline;
  *zoom: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.glyphicons.white i:before {
  color: #fff;
}
.glyphicons.glass i:before {
  content: "\E001";
}
.glyphicons.leaf i:before {
  content: "\E002";
}
.glyphicons.dog i:before {
  content: "\1F415";
}
.glyphicons.user i:before {
  content: "\E004";
}
.glyphicons.girl i:before {
  content: "\1F467";
}
.glyphicons.car i:before {
  content: "\E006";
}
.glyphicons.user_add i:before {
  content: "\E007";
}
.glyphicons.user_remove i:before {
  content: "\E008";
}
.glyphicons.film i:before {
  content: "\E009";
}
.glyphicons.magic i:before {
  content: "\E010";
}
.glyphicons.envelope i:before {
  content: "\2709";
}
.glyphicons.camera i:before {
  content: "\1F4F7";
}
.glyphicons.heart i:before {
  content: "\E013";
}
.glyphicons.beach_umbrella i:before {
  content: "\E014";
}
.glyphicons.train i:before {
  content: "\1F686";
}
.glyphicons.print i:before {
  content: "\E016";
}
.glyphicons.bin i:before {
  content: "\E017";
}
.glyphicons.music i:before {
  content: "\E018";
}
.glyphicons.note i:before {
  content: "\E019";
}
.glyphicons.heart_empty i:before {
  content: "\E020";
}
.glyphicons.home i:before {
  content: "\E021";
}
.glyphicons.snowflake i:before {
  content: "\2744";
}
.glyphicons.fire i:before {
  content: "\1F525";
}
.glyphicons.magnet i:before {
  content: "\E024";
}
.glyphicons.parents i:before {
  content: "\E025";
}
.glyphicons.binoculars i:before {
  content: "\E026";
}
.glyphicons.road i:before {
  content: "\E027";
}
.glyphicons.search i:before {
  content: "\E028";
}
.glyphicons.cars i:before {
  content: "\E029";
}
.glyphicons.notes_2 i:before {
  content: "\E030";
}
.glyphicons.pencil i:before {
  content: "\270F";
}
.glyphicons.bus i:before {
  content: "\1F68C";
}
.glyphicons.wifi_alt i:before {
  content: "\E033";
}
.glyphicons.luggage i:before {
  content: "\E034";
}
.glyphicons.old_man i:before {
  content: "\E035";
}
.glyphicons.woman i:before {
  content: "\1F469";
}
.glyphicons.file i:before {
  content: "\E037";
}
.glyphicons.coins i:before {
  content: "\E038";
}
.glyphicons.airplane i:before {
  content: "\2708";
}
.glyphicons.notes i:before {
  content: "\E040";
}
.glyphicons.stats i:before {
  content: "\E041";
}
.glyphicons.charts i:before {
  content: "\E042";
}
.glyphicons.pie_chart i:before {
  content: "\E043";
}
.glyphicons.group i:before {
  content: "\E044";
}
.glyphicons.keys i:before {
  content: "\E045";
}
.glyphicons.calendar i:before {
  content: "\1F4C5";
}
.glyphicons.router i:before {
  content: "\E047";
}
.glyphicons.camera_small i:before {
  content: "\E048";
}
.glyphicons.dislikes i:before {
  content: "\E049";
}
.glyphicons.star i:before {
  content: "\E050";
}
.glyphicons.link i:before {
  content: "\E051";
}
.glyphicons.eye_open i:before {
  content: "\E052";
}
.glyphicons.eye_close i:before {
  content: "\E053";
}
.glyphicons.alarm i:before {
  content: "\E054";
}
.glyphicons.clock i:before {
  content: "\E055";
}
.glyphicons.stopwatch i:before {
  content: "\E056";
}
.glyphicons.projector i:before {
  content: "\E057";
}
.glyphicons.history i:before {
  content: "\E058";
}
.glyphicons.truck i:before {
  content: "\E059";
}
.glyphicons.cargo i:before {
  content: "\E060";
}
.glyphicons.compass i:before {
  content: "\E061";
}
.glyphicons.keynote i:before {
  content: "\E062";
}
.glyphicons.paperclip i:before {
  content: "\1F4CE";
}
.glyphicons.power i:before {
  content: "\E064";
}
.glyphicons.lightbulb i:before {
  content: "\E065";
}
.glyphicons.tag i:before {
  content: "\E066";
}
.glyphicons.tags i:before {
  content: "\E067";
}
.glyphicons.cleaning i:before {
  content: "\E068";
}
.glyphicons.ruller i:before {
  content: "\E069";
}
.glyphicons.gift i:before {
  content: "\E070";
}
.glyphicons.umbrella i:before {
  content: "\2602";
}
.glyphicons.book i:before {
  content: "\E072";
}
.glyphicons.bookmark i:before {
  content: "\1F516";
}
.glyphicons.wifi i:before {
  content: "\E074";
}
.glyphicons.cup i:before {
  content: "\E075";
}
.glyphicons.stroller i:before {
  content: "\E076";
}
.glyphicons.headphones i:before {
  content: "\E077";
}
.glyphicons.headset i:before {
  content: "\E078";
}
.glyphicons.warning_sign i:before {
  content: "\E079";
}
.glyphicons.signal i:before {
  content: "\E080";
}
.glyphicons.retweet i:before {
  content: "\E081";
}
.glyphicons.refresh i:before {
  content: "\E082";
}
.glyphicons.roundabout i:before {
  content: "\E083";
}
.glyphicons.random i:before {
  content: "\E084";
}
.glyphicons.heat i:before {
  content: "\E085";
}
.glyphicons.repeat i:before {
  content: "\E086";
}
.glyphicons.display i:before {
  content: "\E087";
}
.glyphicons.log_book i:before {
  content: "\E088";
}
.glyphicons.address_book i:before {
  content: "\E089";
}
.glyphicons.building i:before {
  content: "\E090";
}
.glyphicons.eyedropper i:before {
  content: "\E091";
}
.glyphicons.adjust i:before {
  content: "\E092";
}
.glyphicons.tint i:before {
  content: "\E093";
}
.glyphicons.crop i:before {
  content: "\E094";
}
.glyphicons.vector_path_square i:before {
  content: "\E095";
}
.glyphicons.vector_path_circle i:before {
  content: "\E096";
}
.glyphicons.vector_path_polygon i:before {
  content: "\E097";
}
.glyphicons.vector_path_line i:before {
  content: "\E098";
}
.glyphicons.vector_path_curve i:before {
  content: "\E099";
}
.glyphicons.vector_path_all i:before {
  content: "\E100";
}
.glyphicons.font i:before {
  content: "\E101";
}
.glyphicons.italic i:before {
  content: "\E102";
}
.glyphicons.bold i:before {
  content: "\E103";
}
.glyphicons.text_underline i:before {
  content: "\E104";
}
.glyphicons.text_strike i:before {
  content: "\E105";
}
.glyphicons.text_height i:before {
  content: "\E106";
}
.glyphicons.text_width i:before {
  content: "\E107";
}
.glyphicons.text_resize i:before {
  content: "\E108";
}
.glyphicons.left_indent i:before {
  content: "\E109";
}
.glyphicons.right_indent i:before {
  content: "\E110";
}
.glyphicons.align_left i:before {
  content: "\E111";
}
.glyphicons.align_center i:before {
  content: "\E112";
}
.glyphicons.align_right i:before {
  content: "\E113";
}
.glyphicons.justify i:before {
  content: "\E114";
}
.glyphicons.list i:before {
  content: "\E115";
}
.glyphicons.text_smaller i:before {
  content: "\E116";
}
.glyphicons.text_bigger i:before {
  content: "\E117";
}
.glyphicons.embed i:before {
  content: "\E118";
}
.glyphicons.embed_close i:before {
  content: "\E119";
}
.glyphicons.table i:before {
  content: "\E120";
}
.glyphicons.message_full i:before {
  content: "\E121";
}
.glyphicons.message_empty i:before {
  content: "\E122";
}
.glyphicons.message_in i:before {
  content: "\E123";
}
.glyphicons.message_out i:before {
  content: "\E124";
}
.glyphicons.message_plus i:before {
  content: "\E125";
}
.glyphicons.message_minus i:before {
  content: "\E126";
}
.glyphicons.message_ban i:before {
  content: "\E127";
}
.glyphicons.message_flag i:before {
  content: "\E128";
}
.glyphicons.message_lock i:before {
  content: "\E129";
}
.glyphicons.message_new i:before {
  content: "\E130";
}
.glyphicons.inbox i:before {
  content: "\E131";
}
.glyphicons.inbox_plus i:before {
  content: "\E132";
}
.glyphicons.inbox_minus i:before {
  content: "\E133";
}
.glyphicons.inbox_lock i:before {
  content: "\E134";
}
.glyphicons.inbox_in i:before {
  content: "\E135";
}
.glyphicons.inbox_out i:before {
  content: "\E136";
}
.glyphicons.cogwheel i:before {
  content: "\E137";
}
.glyphicons.cogwheels i:before {
  content: "\E138";
}
.glyphicons.picture i:before {
  content: "\E139";
}
.glyphicons.adjust_alt i:before {
  content: "\E140";
}
.glyphicons.database_lock i:before {
  content: "\E141";
}
.glyphicons.database_plus i:before {
  content: "\E142";
}
.glyphicons.database_minus i:before {
  content: "\E143";
}
.glyphicons.database_ban i:before {
  content: "\E144";
}
.glyphicons.folder_open i:before {
  content: "\E145";
}
.glyphicons.folder_plus i:before {
  content: "\E146";
}
.glyphicons.folder_minus i:before {
  content: "\E147";
}
.glyphicons.folder_lock i:before {
  content: "\E148";
}
.glyphicons.folder_flag i:before {
  content: "\E149";
}
.glyphicons.folder_new i:before {
  content: "\E150";
}
.glyphicons.edit i:before {
  content: "\E151";
}
.glyphicons.new_window i:before {
  content: "\E152";
}
.glyphicons.check i:before {
  content: "\E153";
}
.glyphicons.unchecked i:before {
  content: "\E154";
}
.glyphicons.more_windows i:before {
  content: "\E155";
}
.glyphicons.show_big_thumbnails i:before {
  content: "\E156";
}
.glyphicons.show_thumbnails i:before {
  content: "\E157";
}
.glyphicons.show_thumbnails_with_lines i:before {
  content: "\E158";
}
.glyphicons.show_lines i:before {
  content: "\E159";
}
.glyphicons.playlist i:before {
  content: "\E160";
}
.glyphicons.imac i:before {
  content: "\E161";
}
.glyphicons.macbook i:before {
  content: "\E162";
}
.glyphicons.ipad i:before {
  content: "\E163";
}
.glyphicons.iphone i:before {
  content: "\E164";
}
.glyphicons.iphone_transfer i:before {
  content: "\E165";
}
.glyphicons.iphone_exchange i:before {
  content: "\E166";
}
.glyphicons.ipod i:before {
  content: "\E167";
}
.glyphicons.ipod_shuffle i:before {
  content: "\E168";
}
.glyphicons.ear_plugs i:before {
  content: "\E169";
}
.glyphicons.record i:before {
  content: "\E170";
}
.glyphicons.step_backward i:before {
  content: "\E171";
}
.glyphicons.fast_backward i:before {
  content: "\E172";
}
.glyphicons.rewind i:before {
  content: "\E173";
}
.glyphicons.play i:before {
  content: "\E174";
}
.glyphicons.pause i:before {
  content: "\E175";
}
.glyphicons.stop i:before {
  content: "\E176";
}
.glyphicons.forward i:before {
  content: "\E177";
}
.glyphicons.fast_forward i:before {
  content: "\E178";
}
.glyphicons.step_forward i:before {
  content: "\E179";
}
.glyphicons.eject i:before {
  content: "\E180";
}
.glyphicons.facetime_video i:before {
  content: "\E181";
}
.glyphicons.download_alt i:before {
  content: "\E182";
}
.glyphicons.mute i:before {
  content: "\E183";
}
.glyphicons.volume_down i:before {
  content: "\E184";
}
.glyphicons.volume_up i:before {
  content: "\E185";
}
.glyphicons.screenshot i:before {
  content: "\E186";
}
.glyphicons.move i:before {
  content: "\E187";
}
.glyphicons.more i:before {
  content: "\E188";
}
.glyphicons.brightness_reduce i:before {
  content: "\E189";
}
.glyphicons.brightness_increase i:before {
  content: "\E190";
}
.glyphicons.circle_plus i:before {
  content: "\E191";
}
.glyphicons.circle_minus i:before {
  content: "\E192";
}
.glyphicons.circle_remove i:before {
  content: "\E193";
}
.glyphicons.circle_ok i:before {
  content: "\E194";
}
.glyphicons.circle_question_mark i:before {
  content: "\E195";
}
.glyphicons.circle_info i:before {
  content: "\E196";
}
.glyphicons.circle_exclamation_mark i:before {
  content: "\E197";
}
.glyphicons.remove i:before {
  content: "\E198";
}
.glyphicons.ok i:before {
  content: "\E199";
}
.glyphicons.ban i:before {
  content: "\E200";
}
.glyphicons.download i:before {
  content: "\E201";
}
.glyphicons.upload i:before {
  content: "\E202";
}
.glyphicons.shopping_cart i:before {
  content: "\E203";
}
.glyphicons.lock i:before {
  content: "\1F512";
}
.glyphicons.unlock i:before {
  content: "\E205";
}
.glyphicons.electricity i:before {
  content: "\E206";
}
.glyphicons.ok_2 i:before {
  content: "\E207";
}
.glyphicons.remove_2 i:before {
  content: "\E208";
}
.glyphicons.cart_out i:before {
  content: "\E209";
}
.glyphicons.cart_in i:before {
  content: "\E210";
}
.glyphicons.left_arrow i:before {
  content: "\E211";
}
.glyphicons.right_arrow i:before {
  content: "\E212";
}
.glyphicons.down_arrow i:before {
  content: "\E213";
}
.glyphicons.up_arrow i:before {
  content: "\E214";
}
.glyphicons.resize_small i:before {
  content: "\E215";
}
.glyphicons.resize_full i:before {
  content: "\E216";
}
.glyphicons.circle_arrow_left i:before {
  content: "\E217";
}
.glyphicons.circle_arrow_right i:before {
  content: "\E218";
}
.glyphicons.circle_arrow_top i:before {
  content: "\E219";
}
.glyphicons.circle_arrow_down i:before {
  content: "\E220";
}
.glyphicons.play_button i:before {
  content: "\E221";
}
.glyphicons.unshare i:before {
  content: "\E222";
}
.glyphicons.share i:before {
  content: "\E223";
}
.glyphicons.chevron-right i:before {
  content: "\E224";
}
.glyphicons.chevron-left i:before {
  content: "\E225";
}
.glyphicons.bluetooth i:before {
  content: "\E226";
}
.glyphicons.euro i:before {
  content: "\20AC";
}
.glyphicons.usd i:before {
  content: "\E228";
}
.glyphicons.gbp i:before {
  content: "\E229";
}
.glyphicons.retweet_2 i:before {
  content: "\E230";
}
.glyphicons.moon i:before {
  content: "\E231";
}
.glyphicons.sun i:before {
  content: "\2609";
}
.glyphicons.cloud i:before {
  content: "\2601";
}
.glyphicons.direction i:before {
  content: "\E234";
}
.glyphicons.brush i:before {
  content: "\E235";
}
.glyphicons.pen i:before {
  content: "\E236";
}
.glyphicons.zoom_in i:before {
  content: "\E237";
}
.glyphicons.zoom_out i:before {
  content: "\E238";
}
.glyphicons.pin i:before {
  content: "\E239";
}
.glyphicons.albums i:before {
  content: "\E240";
}
.glyphicons.rotation_lock i:before {
  content: "\E241";
}
.glyphicons.flash i:before {
  content: "\E242";
}
.glyphicons.google_maps i:before {
  content: "\E243";
}
.glyphicons.anchor i:before {
  content: "\2693";
}
.glyphicons.conversation i:before {
  content: "\E245";
}
.glyphicons.chat i:before {
  content: "\E246";
}
.glyphicons.male i:before {
  content: "\E247";
}
.glyphicons.female i:before {
  content: "\E248";
}
.glyphicons.asterisk i:before {
  content: "\002A";
}
.glyphicons.divide i:before {
  content: "\00F7";
}
.glyphicons.snorkel_diving i:before {
  content: "\E251";
}
.glyphicons.scuba_diving i:before {
  content: "\E252";
}
.glyphicons.oxygen_bottle i:before {
  content: "\E253";
}
.glyphicons.fins i:before {
  content: "\E254";
}
.glyphicons.fishes i:before {
  content: "\E255";
}
.glyphicons.boat i:before {
  content: "\E256";
}
.glyphicons.delete i:before {
  content: "\E257";
}
.glyphicons.sheriffs_star i:before {
  content: "\E258";
}
.glyphicons.qrcode i:before {
  content: "\E259";
}
.glyphicons.barcode i:before {
  content: "\E260";
}
.glyphicons.pool i:before {
  content: "\E261";
}
.glyphicons.buoy i:before {
  content: "\E262";
}
.glyphicons.spade i:before {
  content: "\E263";
}
.glyphicons.bank i:before {
  content: "\1F3E6";
}
.glyphicons.vcard i:before {
  content: "\E265";
}
.glyphicons.electrical_plug i:before {
  content: "\E266";
}
.glyphicons.flag i:before {
  content: "\E267";
}
.glyphicons.credit_card i:before {
  content: "\E268";
}
.glyphicons.keyboard-wireless i:before {
  content: "\E269";
}
.glyphicons.keyboard-wired i:before {
  content: "\E270";
}
.glyphicons.shield i:before {
  content: "\E271";
}
.glyphicons.ring i:before {
  content: "\02DA";
}
.glyphicons.cake i:before {
  content: "\E273";
}
.glyphicons.drink i:before {
  content: "\E274";
}
.glyphicons.beer i:before {
  content: "\E275";
}
.glyphicons.fast_food i:before {
  content: "\E276";
}
.glyphicons.cutlery i:before {
  content: "\E277";
}
.glyphicons.pizza i:before {
  content: "\E278";
}
.glyphicons.birthday_cake i:before {
  content: "\E279";
}
.glyphicons.tablet i:before {
  content: "\E280";
}
.glyphicons.settings i:before {
  content: "\E281";
}
.glyphicons.bullets i:before {
  content: "\E282";
}
.glyphicons.cardio i:before {
  content: "\E283";
}
.glyphicons.t-shirt i:before {
  content: "\E284";
}
.glyphicons.pants i:before {
  content: "\E285";
}
.glyphicons.sweater i:before {
  content: "\E286";
}
.glyphicons.fabric i:before {
  content: "\E287";
}
.glyphicons.leather i:before {
  content: "\E288";
}
.glyphicons.scissors i:before {
  content: "\E289";
}
.glyphicons.bomb i:before {
  content: "\1F4A3";
}
.glyphicons.skull i:before {
  content: "\1F480";
}
.glyphicons.celebration i:before {
  content: "\E292";
}
.glyphicons.tea_kettle i:before {
  content: "\E293";
}
.glyphicons.french_press i:before {
  content: "\E294";
}
.glyphicons.coffe_cup i:before {
  content: "\E295";
}
.glyphicons.pot i:before {
  content: "\E296";
}
.glyphicons.grater i:before {
  content: "\E297";
}
.glyphicons.kettle i:before {
  content: "\E298";
}
.glyphicons.hospital i:before {
  content: "\1F3E5";
}
.glyphicons.hospital_h i:before {
  content: "\E300";
}
.glyphicons.microphone i:before {
  content: "\1F3A4";
}
.glyphicons.webcam i:before {
  content: "\E302";
}
.glyphicons.temple_christianity_church i:before {
  content: "\E303";
}
.glyphicons.temple_islam i:before {
  content: "\E304";
}
.glyphicons.temple_hindu i:before {
  content: "\E305";
}
.glyphicons.temple_buddhist i:before {
  content: "\E306";
}
.glyphicons.bicycle i:before {
  content: "\1F6B2";
}
.glyphicons.life_preserver i:before {
  content: "\E308";
}
.glyphicons.share_alt i:before {
  content: "\E309";
}
.glyphicons.comments i:before {
  content: "\E310";
}
.glyphicons.flower i:before {
  content: "\2698";
}
.glyphicons.baseball i:before {
  content: "\26BE";
}
.glyphicons.rugby i:before {
  content: "\E313";
}
.glyphicons.ax i:before {
  content: "\E314";
}
.glyphicons.table_tennis i:before {
  content: "\E315";
}
.glyphicons.bowling i:before {
  content: "\1F3B3";
}
.glyphicons.tree_conifer i:before {
  content: "\E317";
}
.glyphicons.tree_deciduous i:before {
  content: "\E318";
}
.glyphicons.more_items i:before {
  content: "\E319";
}
.glyphicons.sort i:before {
  content: "\E320";
}
.glyphicons.filter i:before {
  content: "\E321";
}
.glyphicons.gamepad i:before {
  content: "\E322";
}
.glyphicons.playing_dices i:before {
  content: "\E323";
}
.glyphicons.calculator i:before {
  content: "\E324";
}
.glyphicons.tie i:before {
  content: "\E325";
}
.glyphicons.wallet i:before {
  content: "\E326";
}
.glyphicons.piano i:before {
  content: "\E327";
}
.glyphicons.sampler i:before {
  content: "\E328";
}
.glyphicons.podium i:before {
  content: "\E329";
}
.glyphicons.soccer_ball i:before {
  content: "\E330";
}
.glyphicons.blog i:before {
  content: "\E331";
}
.glyphicons.dashboard i:before {
  content: "\E332";
}
.glyphicons.certificate i:before {
  content: "\E333";
}
.glyphicons.bell i:before {
  content: "\1F514";
}
.glyphicons.candle i:before {
  content: "\E335";
}
.glyphicons.pushpin i:before {
  content: "\1F4CC";
}
.glyphicons.iphone_shake i:before {
  content: "\E337";
}
.glyphicons.pin_flag i:before {
  content: "\E338";
}
.glyphicons.turtle i:before {
  content: "\1F422";
}
.glyphicons.rabbit i:before {
  content: "\1F407";
}
.glyphicons.globe i:before {
  content: "\E341";
}
.glyphicons.briefcase i:before {
  content: "\1F4BC";
}
.glyphicons.hdd i:before {
  content: "\E343";
}
.glyphicons.thumbs_up i:before {
  content: "\E344";
}
.glyphicons.thumbs_down i:before {
  content: "\E345";
}
.glyphicons.hand_right i:before {
  content: "\E346";
}
.glyphicons.hand_left i:before {
  content: "\E347";
}
.glyphicons.hand_up i:before {
  content: "\E348";
}
.glyphicons.hand_down i:before {
  content: "\E349";
}
.glyphicons.fullscreen i:before {
  content: "\E350";
}
.glyphicons.shopping_bag i:before {
  content: "\E351";
}
.glyphicons.book_open i:before {
  content: "\E352";
}
.glyphicons.nameplate i:before {
  content: "\E353";
}
.glyphicons.nameplate_alt i:before {
  content: "\E354";
}
.glyphicons.vases i:before {
  content: "\E355";
}
.glyphicons.bullhorn i:before {
  content: "\E356";
}
.glyphicons.dumbbell i:before {
  content: "\E357";
}
.glyphicons.suitcase i:before {
  content: "\E358";
}
.glyphicons.file_import i:before {
  content: "\E359";
}
.glyphicons.file_export i:before {
  content: "\E360";
}
.glyphicons.bug i:before {
  content: "\1F41B";
}
.glyphicons.crown i:before {
  content: "\1F451";
}
.glyphicons.smoking i:before {
  content: "\E363";
}
.glyphicons.cloud-upload i:before {
  content: "\E364";
}
.glyphicons.cloud-download i:before {
  content: "\E365";
}
.glyphicons.restart i:before {
  content: "\E366";
}
.glyphicons.security_camera i:before {
  content: "\E367";
}
.glyphicons.expand i:before {
  content: "\E368";
}
.glyphicons.collapse i:before {
  content: "\E369";
}
.glyphicons.collapse_top i:before {
  content: "\E370";
}
.glyphicons.globe_af i:before {
  content: "\E371";
}
.glyphicons.global i:before {
  content: "\E372";
}
.glyphicons.spray i:before {
  content: "\E373";
}
.glyphicons.nails i:before {
  content: "\E374";
}
.glyphicons.claw_hammer i:before {
  content: "\E375";
}
.glyphicons.classic_hammer i:before {
  content: "\E376";
}
.glyphicons.hand_saw i:before {
  content: "\E377";
}
.glyphicons.riflescope i:before {
  content: "\E378";
}
.glyphicons.electrical_socket_eu i:before {
  content: "\E379";
}
.glyphicons.electrical_socket_us i:before {
  content: "\E380";
}
.glyphicons.message_forward i:before {
  content: "\E381";
}
.glyphicons.coat_hanger i:before {
  content: "\E382";
}
.glyphicons.dress i:before {
  content: "\1F457";
}
.glyphicons.bathrobe i:before {
  content: "\E384";
}
.glyphicons.shirt i:before {
  content: "\E385";
}
.glyphicons.underwear i:before {
  content: "\E386";
}
.glyphicons.log_in i:before {
  content: "\E387";
}
.glyphicons.log_out i:before {
  content: "\E388";
}
.glyphicons.exit i:before {
  content: "\E389";
}
.glyphicons.new_window_alt i:before {
  content: "\E390";
}
.glyphicons.video_sd i:before {
  content: "\E391";
}
.glyphicons.video_hd i:before {
  content: "\E392";
}
.glyphicons.subtitles i:before {
  content: "\E393";
}
.glyphicons.sound_stereo i:before {
  content: "\E394";
}
.glyphicons.sound_dolby i:before {
  content: "\E395";
}
.glyphicons.sound_5_1 i:before {
  content: "\E396";
}
.glyphicons.sound_6_1 i:before {
  content: "\E397";
}
.glyphicons.sound_7_1 i:before {
  content: "\E398";
}
.glyphicons.copyright_mark i:before {
  content: "\E399";
}
.glyphicons.registration_mark i:before {
  content: "\E400";
}
.glyphicons.radar i:before {
  content: "\E401";
}
.glyphicons.skateboard i:before {
  content: "\E402";
}
.glyphicons.golf_course i:before {
  content: "\E403";
}
.glyphicons.sorting i:before {
  content: "\E404";
}
.glyphicons.sort-by-alphabet i:before {
  content: "\E405";
}
.glyphicons.sort-by-alphabet-alt i:before {
  content: "\E406";
}
.glyphicons.sort-by-order i:before {
  content: "\E407";
}
.glyphicons.sort-by-order-alt i:before {
  content: "\E408";
}
.glyphicons.sort-by-attributes i:before {
  content: "\E409";
}
.glyphicons.sort-by-attributes-alt i:before {
  content: "\E410";
}
.glyphicons.compressed i:before {
  content: "\E411";
}
.glyphicons.package i:before {
  content: "\1F4E6";
}
.glyphicons.cloud_plus i:before {
  content: "\E413";
}
.glyphicons.cloud_minus i:before {
  content: "\E414";
}
.glyphicons.disk_save i:before {
  content: "\E415";
}
.glyphicons.disk_open i:before {
  content: "\E416";
}
.glyphicons.disk_saved i:before {
  content: "\E417";
}
.glyphicons.disk_remove i:before {
  content: "\E418";
}
.glyphicons.disk_import i:before {
  content: "\E419";
}
.glyphicons.disk_export i:before {
  content: "\E420";
}
.glyphicons.tower i:before {
  content: "\E421";
}
.glyphicons.send i:before {
  content: "\E422";
}
.glyphicons.git_branch i:before {
  content: "\E423";
}
.glyphicons.git_create i:before {
  content: "\E424";
}
.glyphicons.git_private i:before {
  content: "\E425";
}
.glyphicons.git_delete i:before {
  content: "\E426";
}
.glyphicons.git_merge i:before {
  content: "\E427";
}
.glyphicons.git_pull_request i:before {
  content: "\E428";
}
.glyphicons.git_compare i:before {
  content: "\E429";
}
.glyphicons.git_commit i:before {
  content: "\E430";
}
.glyphicons.construction_cone i:before {
  content: "\E431";
}
.glyphicons.shoe_steps i:before {
  content: "\E432";
}
.glyphicons.plus i:before {
  content: "\002B";
}
.glyphicons.minus i:before {
  content: "\2212";
}
.glyphicons.redo i:before {
  content: "\E435";
}
.glyphicons.undo i:before {
  content: "\E436";
}
.glyphicons.golf i:before {
  content: "\E437";
}
.glyphicons.hockey i:before {
  content: "\E438";
}
.glyphicons.pipe i:before {
  content: "\E439";
}
.glyphicons.wrench i:before {
  content: "\1F527";
}
.glyphicons.folder_closed i:before {
  content: "\E441";
}
.glyphicons.phone_alt i:before {
  content: "\E442";
}
.glyphicons.earphone i:before {
  content: "\E443";
}
.glyphicons.floppy_disk i:before {
  content: "\E444";
}
.glyphicons.floppy_saved i:before {
  content: "\E445";
}
.glyphicons.floppy_remove i:before {
  content: "\E446";
}
.glyphicons.floppy_save i:before {
  content: "\E447";
}
.glyphicons.floppy_open i:before {
  content: "\E448";
}
.glyphicons.translate i:before {
  content: "\E449";
}
.glyphicons.fax i:before {
  content: "\E450";
}
.glyphicons.factory i:before {
  content: "\1F3ED";
}
.glyphicons.shop_window i:before {
  content: "\E452";
}
.glyphicons.shop i:before {
  content: "\E453";
}
.glyphicons.kiosk i:before {
  content: "\E454";
}
.glyphicons.kiosk_wheels i:before {
  content: "\E455";
}
.glyphicons.kiosk_light i:before {
  content: "\E456";
}
.glyphicons.kiosk_food i:before {
  content: "\E457";
}
.glyphicons.transfer i:before {
  content: "\E458";
}
.glyphicons.money i:before {
  content: "\E459";
}
.glyphicons.header i:before {
  content: "\E460";
}
.glyphicons.blacksmith i:before {
  content: "\E461";
}
.glyphicons.saw_blade i:before {
  content: "\E462";
}
.glyphicons.basketball i:before {
  content: "\E463";
}
.glyphicons.server i:before {
  content: "\E464";
}
.glyphicons.server_plus i:before {
  content: "\E465";
}
.glyphicons.server_minus i:before {
  content: "\E466";
}
.glyphicons.server_ban i:before {
  content: "\E467";
}
.glyphicons.server_flag i:before {
  content: "\E468";
}
.glyphicons.server_lock i:before {
  content: "\E469";
}
.glyphicons.server_new i:before {
  content: "\E470";
}
