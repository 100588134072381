/*!
 *
 *  Project:  GLYPHICONS HALFLINGS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Filetypes Regular';
  src: url('../fonts/glyphicons_filetypes/glyphicons-filetypes-regular.eot');
  src: url('../fonts/glyphicons_filetypes/glyphicons-filetypes-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons_filetypes/glyphicons-filetypes-regular.woff') format('woff'), url('../fonts/glyphicons_filetypes/glyphicons-filetypes-regular.ttf') format('truetype'), url('../fonts/glyphicons_filetypes/glyphicons-filetypes-regular.svg#glyphicons_filetypesregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.glyphicons-filetype {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  color: #1d1d1b;
  text-decoration: none;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.glyphicons-filetype i:before {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  font: 24px/1em 'Glyphicons Filetypes Regular';
  font-style: normal;
  font-weight: normal;
  color: #1d1d1b;
  *display: inline;
  *zoom: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.glyphicons-filetype.white i:before {
  color: #fff;
}
.glyphicons-filetype.txt i:before {
  content: "\E001";
}
.glyphicons-filetype.doc i:before {
  content: "\E002";
}
.glyphicons-filetype.rtf i:before {
  content: "\E003";
}
.glyphicons-filetype.log i:before {
  content: "\E004";
}
.glyphicons-filetype.tex i:before {
  content: "\E005";
}
.glyphicons-filetype.msg i:before {
  content: "\E006";
}
.glyphicons-filetype.text i:before {
  content: "\E007";
}
.glyphicons-filetype.wpd i:before {
  content: "\E008";
}
.glyphicons-filetype.wps i:before {
  content: "\E009";
}
.glyphicons-filetype.docx i:before {
  content: "\E010";
}
.glyphicons-filetype.page i:before {
  content: "\E011";
}
.glyphicons-filetype.csv i:before {
  content: "\E012";
}
.glyphicons-filetype.dat i:before {
  content: "\E013";
}
.glyphicons-filetype.tar i:before {
  content: "\E014";
}
.glyphicons-filetype.xml i:before {
  content: "\E015";
}
.glyphicons-filetype.vcf i:before {
  content: "\E016";
}
.glyphicons-filetype.pps i:before {
  content: "\E017";
}
.glyphicons-filetype.key i:before {
  content: "\1F511";
}
.glyphicons-filetype.ppt i:before {
  content: "\E019";
}
.glyphicons-filetype.pptx i:before {
  content: "\E020";
}
.glyphicons-filetype.sdf i:before {
  content: "\E021";
}
.glyphicons-filetype.gbr i:before {
  content: "\E022";
}
.glyphicons-filetype.ged i:before {
  content: "\E023";
}
.glyphicons-filetype.mp3 i:before {
  content: "\E024";
}
.glyphicons-filetype.m4a i:before {
  content: "\E025";
}
.glyphicons-filetype.waw i:before {
  content: "\E026";
}
.glyphicons-filetype.wma i:before {
  content: "\E027";
}
.glyphicons-filetype.mpa i:before {
  content: "\E028";
}
.glyphicons-filetype.iff i:before {
  content: "\E029";
}
.glyphicons-filetype.aif i:before {
  content: "\E030";
}
.glyphicons-filetype.ra i:before {
  content: "\E031";
}
.glyphicons-filetype.mid i:before {
  content: "\E032";
}
.glyphicons-filetype.m3v i:before {
  content: "\E033";
}
.glyphicons-filetype.e_3gp i:before {
  content: "\E034";
}
.glyphicons-filetype.shf i:before {
  content: "\E035";
}
.glyphicons-filetype.avi i:before {
  content: "\E036";
}
.glyphicons-filetype.asx i:before {
  content: "\E037";
}
.glyphicons-filetype.mp4 i:before {
  content: "\E038";
}
.glyphicons-filetype.e_3g2 i:before {
  content: "\E039";
}
.glyphicons-filetype.mpg i:before {
  content: "\E040";
}
.glyphicons-filetype.asf i:before {
  content: "\E041";
}
.glyphicons-filetype.vob i:before {
  content: "\E042";
}
.glyphicons-filetype.wmv i:before {
  content: "\E043";
}
.glyphicons-filetype.mov i:before {
  content: "\E044";
}
.glyphicons-filetype.srt i:before {
  content: "\E045";
}
.glyphicons-filetype.m4v i:before {
  content: "\E046";
}
.glyphicons-filetype.flv i:before {
  content: "\E047";
}
.glyphicons-filetype.rm i:before {
  content: "\E048";
}
.glyphicons-filetype.png i:before {
  content: "\E049";
}
.glyphicons-filetype.psd i:before {
  content: "\E050";
}
.glyphicons-filetype.psp i:before {
  content: "\E051";
}
.glyphicons-filetype.jpg i:before {
  content: "\E052";
}
.glyphicons-filetype.tif i:before {
  content: "\E053";
}
.glyphicons-filetype.tiff i:before {
  content: "\E054";
}
.glyphicons-filetype.gif i:before {
  content: "\E055";
}
.glyphicons-filetype.bmp i:before {
  content: "\E056";
}
.glyphicons-filetype.tga i:before {
  content: "\E057";
}
.glyphicons-filetype.thm i:before {
  content: "\E058";
}
.glyphicons-filetype.yuv i:before {
  content: "\E059";
}
.glyphicons-filetype.dds i:before {
  content: "\E060";
}
.glyphicons-filetype.ai i:before {
  content: "\E061";
}
.glyphicons-filetype.eps i:before {
  content: "\E062";
}
.glyphicons-filetype.ps i:before {
  content: "\E063";
}
.glyphicons-filetype.svg i:before {
  content: "\E064";
}
.glyphicons-filetype.pdf i:before {
  content: "\E065";
}
.glyphicons-filetype.pct i:before {
  content: "\E066";
}
.glyphicons-filetype.indd i:before {
  content: "\E067";
}
.glyphicons-filetype.xlr i:before {
  content: "\E068";
}
.glyphicons-filetype.xls i:before {
  content: "\E069";
}
.glyphicons-filetype.xlsx i:before {
  content: "\E070";
}
.glyphicons-filetype.db i:before {
  content: "\E071";
}
.glyphicons-filetype.dbf i:before {
  content: "\E072";
}
.glyphicons-filetype.mdb i:before {
  content: "\E073";
}
.glyphicons-filetype.pdb i:before {
  content: "\E074";
}
.glyphicons-filetype.sql i:before {
  content: "\E075";
}
.glyphicons-filetype.aacd i:before {
  content: "\E076";
}
.glyphicons-filetype.app i:before {
  content: "\E077";
}
.glyphicons-filetype.exe i:before {
  content: "\E078";
}
.glyphicons-filetype.com i:before {
  content: "\E079";
}
.glyphicons-filetype.bat i:before {
  content: "\E080";
}
.glyphicons-filetype.apk i:before {
  content: "\E081";
}
.glyphicons-filetype.jar i:before {
  content: "\E082";
}
.glyphicons-filetype.hsf i:before {
  content: "\E083";
}
.glyphicons-filetype.pif i:before {
  content: "\E084";
}
.glyphicons-filetype.vb i:before {
  content: "\E085";
}
.glyphicons-filetype.cgi i:before {
  content: "\E086";
}
.glyphicons-filetype.css i:before {
  content: "\E087";
}
.glyphicons-filetype.js i:before {
  content: "\E088";
}
.glyphicons-filetype.php i:before {
  content: "\E089";
}
.glyphicons-filetype.xhtml i:before {
  content: "\E090";
}
.glyphicons-filetype.htm i:before {
  content: "\E091";
}
.glyphicons-filetype.html i:before {
  content: "\E092";
}
.glyphicons-filetype.asp i:before {
  content: "\E093";
}
.glyphicons-filetype.cer i:before {
  content: "\E094";
}
.glyphicons-filetype.jsp i:before {
  content: "\E095";
}
.glyphicons-filetype.cfm i:before {
  content: "\E096";
}
.glyphicons-filetype.aspx i:before {
  content: "\E097";
}
.glyphicons-filetype.rss i:before {
  content: "\E098";
}
.glyphicons-filetype.csr i:before {
  content: "\E099";
}
.glyphicons-filetype.less i:before {
  content: "\003C";
}
.glyphicons-filetype.otf i:before {
  content: "\E101";
}
.glyphicons-filetype.ttf i:before {
  content: "\E102";
}
.glyphicons-filetype.font i:before {
  content: "\E103";
}
.glyphicons-filetype.fnt i:before {
  content: "\E104";
}
.glyphicons-filetype.eot i:before {
  content: "\E105";
}
.glyphicons-filetype.woff i:before {
  content: "\E106";
}
.glyphicons-filetype.zip i:before {
  content: "\E107";
}
.glyphicons-filetype.zipx i:before {
  content: "\E108";
}
.glyphicons-filetype.rar i:before {
  content: "\E109";
}
.glyphicons-filetype.targ i:before {
  content: "\E110";
}
.glyphicons-filetype.sitx i:before {
  content: "\E111";
}
.glyphicons-filetype.deb i:before {
  content: "\E112";
}
.glyphicons-filetype.e_7z i:before {
  content: "\E113";
}
.glyphicons-filetype.pkg i:before {
  content: "\E114";
}
.glyphicons-filetype.rpm i:before {
  content: "\E115";
}
.glyphicons-filetype.cbr i:before {
  content: "\E116";
}
.glyphicons-filetype.gz i:before {
  content: "\E117";
}
.glyphicons-filetype.dmg i:before {
  content: "\E118";
}
.glyphicons-filetype.cue i:before {
  content: "\E119";
}
.glyphicons-filetype.bin i:before {
  content: "\E120";
}
.glyphicons-filetype.iso i:before {
  content: "\E121";
}
.glyphicons-filetype.hdf i:before {
  content: "\E122";
}
.glyphicons-filetype.vcd i:before {
  content: "\E123";
}
.glyphicons-filetype.bak i:before {
  content: "\E124";
}
.glyphicons-filetype.tmp i:before {
  content: "\E125";
}
.glyphicons-filetype.ics i:before {
  content: "\E126";
}
.glyphicons-filetype.msi i:before {
  content: "\E127";
}
.glyphicons-filetype.cfg i:before {
  content: "\E128";
}
.glyphicons-filetype.ini i:before {
  content: "\E129";
}
.glyphicons-filetype.prf i:before {
  content: "\E130";
}
