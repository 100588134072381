/*!
 *
 *  Project:  GLYPHICONS HALFLINGS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Social Regular';
  src: url('../fonts/glyphicons_social/glyphicons-social-regular.eot');
  src: url('../fonts/glyphicons_social/glyphicons-social-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons_social/glyphicons-social-regular.woff') format('woff'), url('../fonts/glyphicons_social/glyphicons-social-regular.ttf') format('truetype'), url('../fonts/glyphicons_social/glyphicons-social-regular.svg#glyphicons_socialregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.glyphicons-social {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  color: #1d1d1b;
  text-decoration: none;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.glyphicons-social i:before {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  font: 24px/1em 'Glyphicons Social Regular';
  font-style: normal;
  font-weight: normal;
  color: #1d1d1b;
  *display: inline;
  *zoom: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.glyphicons-social.white i:before {
  color: #fff;
}
.glyphicons-social.pinterest i:before {
  content: "\E001";
}
.glyphicons-social.dropbox i:before {
  content: "\E002";
}
.glyphicons-social.google_plus i:before {
  content: "\E003";
}
.glyphicons-social.jolicloud i:before {
  content: "\E004";
}
.glyphicons-social.yahoo i:before {
  content: "\E005";
}
.glyphicons-social.blogger i:before {
  content: "\E006";
}
.glyphicons-social.picasa i:before {
  content: "\E007";
}
.glyphicons-social.amazon i:before {
  content: "\E008";
}
.glyphicons-social.tumblr i:before {
  content: "\E009";
}
.glyphicons-social.wordpress i:before {
  content: "\E010";
}
.glyphicons-social.instapaper i:before {
  content: "\E011";
}
.glyphicons-social.evernote i:before {
  content: "\E012";
}
.glyphicons-social.xing i:before {
  content: "\E013";
}
.glyphicons-social.zootool i:before {
  content: "\E014";
}
.glyphicons-social.dribbble i:before {
  content: "\E015";
}
.glyphicons-social.deviantart i:before {
  content: "\E016";
}
.glyphicons-social.read_it_later i:before {
  content: "\E017";
}
.glyphicons-social.linked_in i:before {
  content: "\E018";
}
.glyphicons-social.forrst i:before {
  content: "\E019";
}
.glyphicons-social.pinboard i:before {
  content: "\E020";
}
.glyphicons-social.behance i:before {
  content: "\E021";
}
.glyphicons-social.github i:before {
  content: "\E022";
}
.glyphicons-social.youtube i:before {
  content: "\E023";
}
.glyphicons-social.skitch i:before {
  content: "\E024";
}
.glyphicons-social.foursquare i:before {
  content: "\E025";
}
.glyphicons-social.quora i:before {
  content: "\E026";
}
.glyphicons-social.badoo i:before {
  content: "\E027";
}
.glyphicons-social.spotify i:before {
  content: "\E028";
}
.glyphicons-social.stumbleupon i:before {
  content: "\E029";
}
.glyphicons-social.readability i:before {
  content: "\E030";
}
.glyphicons-social.facebook i:before {
  content: "\E031";
}
.glyphicons-social.twitter i:before {
  content: "\E032";
}
.glyphicons-social.instagram i:before {
  content: "\E033";
}
.glyphicons-social.posterous_spaces i:before {
  content: "\E034";
}
.glyphicons-social.vimeo i:before {
  content: "\E035";
}
.glyphicons-social.flickr i:before {
  content: "\E036";
}
.glyphicons-social.last_fm i:before {
  content: "\E037";
}
.glyphicons-social.rss i:before {
  content: "\E038";
}
.glyphicons-social.skype i:before {
  content: "\E039";
}
.glyphicons-social.e-mail i:before {
  content: "\E040";
}
.glyphicons-social.vine i:before {
  content: "\E041";
}
.glyphicons-social.myspace i:before {
  content: "\E042";
}
.glyphicons-social.goodreads i:before {
  content: "\E043";
}
.glyphicons-social.apple i:before {
  content: "\F8FF";
}
.glyphicons-social.windows i:before {
  content: "\E045";
}
.glyphicons-social.yelp i:before {
  content: "\E046";
}
.glyphicons-social.playstation i:before {
  content: "\E047";
}
.glyphicons-social.xbox i:before {
  content: "\E048";
}
.glyphicons-social.android i:before {
  content: "\E049";
}
.glyphicons-social.ios i:before {
  content: "\E050";
}
